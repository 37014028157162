import { baseHTTP } from "../http";
import store from "@/store";

export default {
  async importTariff(group, data) {
    return baseHTTP("post", `/app/admin/tariff/${group}`, data);
  },

  async readTariff() {
    return baseHTTP("get", `/app/admin/tariff`);
  },

  async importIrradiation(docType, data) {
    return baseHTTP("post", `/app/admin/irradiation/${docType}`, data);
  },

  async readIrradiation() {
    return baseHTTP("get", `/app/admin/irradiation`);
  },

  async readDistributors(registerStatus) {
    return baseHTTP("get", `/app/admin/distribuitor/${registerStatus}`);
  },

  async createSupportUser(distributorId, data) {
    return baseHTTP("post", `/app/admin/support/${distributorId}`, data);
  },

  async updateSupportUser(distributorId, data) {
    return baseHTTP("put", `/app/admin/support/${distributorId}`, data);
  },

  async readSupportUsers(distributorId) {
    return baseHTTP("get", `/app/admin/support/${distributorId}`);
  },

  async updateLogoDistributor(distribuitorId, data) {
    return baseHTTP(
      "put",
      `/app/admin/distribuitor/logo/${distribuitorId}`,
      data
    );
  },

  async uploadDistribuitorSquareLogo(distribuitorId, data) {
    return baseHTTP(
      "put",
      `/app/admin/distribuitor/squareLogo/${distribuitorId}`,
      data
    );
  },

  async uploadDistribuitorMonocromaticLogo(distribuitorId, data) {
    return baseHTTP(
      "put",
      `/app/admin/distribuitor/monocromaticLogo/${distribuitorId}`,
      data
    );
  },

  async updateThemeDistributor(formData, id) {
    return baseHTTP(
      "put",
      `/app/admin/distribuitor/configureTheme/${id}`,
      formData
    );
  },

  async readDistribuitorById(id) {
    return baseHTTP("get", `/app/admin/distribuitor/detail/${id}`);
  },

  async readActions() {
    return baseHTTP("get", `/app/admin/available-actions`);
  },

  async updateActions(id, actions) {
    let data = {
      id,
      actions,
    };
    return baseHTTP("put", "/app/admin/distribuitor/actions", data);
  },

  async createDistribuitor(data) {
    return baseHTTP("post", `/app/admin/distribuitor`, data);
  },

  async updateDistribuitor(data, id) {
    return baseHTTP("put", `/app/admin/distribuitor/info/${id}`, data);
  },

  async deleteDistribuitor(id) {
    return baseHTTP("delete", `/app/admin/distribuitor/${id}`);
  },

  async readFeaturedProduct(page, limit) {
    return baseHTTP("get", `/app/admin/featured/product/${page}/${limit}`);
  },

  async readFeaturedKit(page, limit) {
    return baseHTTP("get", `/app/admin/featured/kit/${page}/${limit}`);
  },

  async readNotFeaturedProduct(distribuitor, type) {
    return baseHTTP(
      "get",
      `/app/admin/notFeatured/product/${distribuitor}/${type}`
    );
  },

  async readNotFeaturedKit(distribuitor) {
    return baseHTTP("get", `/app/admin/notFeatured/kit/${distribuitor}`);
  },

  async readBlockedUsers(distId) {
    return baseHTTP(
      "get",
      `/app/admin/users/blockedUsers?distributorId=${distId}`
    );
  },

  async updateFeaturedProduct(data) {
    return baseHTTP("put", `/app/admin/update/featured/product`, data);
  },

  async updateFeaturedKit(data) {
    return baseHTTP("put", `/app/admin/update/featured/kit`, data);
  },

  async logout() {
    let ret = await baseHTTP("post", `/app/admin/user/logout`, true);
    if (!ret.error) await store.dispatch("userData/removeUserData");
  },

  async createAdvertisement(data) {
    return baseHTTP("post", `/app/admin/marketplace/advertisement`, data);
  },

  async updateAdvertisement(id, data) {
    return baseHTTP("put", `/app/admin/marketplace/advertisement/${id}`, data);
  },

  async readAdvertisements() {
    return baseHTTP("get", `/app/admin/marketplace/advertisements`);
  },

  async readAdvertisement(id) {
    return baseHTTP("get", `/app/admin/marketplace/advertisement/${id}`);
  },

  async deleteAdvertisement(id) {
    return baseHTTP("delete", `/app/admin/marketplace/advertisement/${id}`);
  },

  async createNPS(data) {
    return baseHTTP("post", `/app/admin/nps`, data);
  },

  async updateNPS(data) {
    return baseHTTP("put", `/app/admin/nps/${data.id}`, data);
  },

  async readNPS() {
    return baseHTTP("get", `/app/admin/nps`);
  },

  async removeNPS(id) {
    return baseHTTP("delete", `/app/admin/nps/${id}`);
  },

  async decreaseOrderAdvertisement(data) {
    return baseHTTP(
      "put",
      `/app/admin/marketplace/advertisement/order/decrease`,
      data
    );
  },

  async increaseOrderAdvertisement(data) {
    return baseHTTP(
      "put",
      `/app/admin/marketplace/advertisement/order/increase`,
      data
    );
  },

  async resyncRequests(distribuitorId) {
    return baseHTTP("post", `/app/admin/resyncRequests/${distribuitorId}`);
  },

  toggleAction(distributor, action, payload = {}) {
    return baseHTTP(
      "put",
      `/app/admin/${distributor}/action/${action}`,
      payload
    );
  },

  readCheckoutPayment(distributor) {
    return baseHTTP("get", `/app/admin/checkout-payment/${distributor}`);
  },

  updateCheckoutPayment(distributor, payload) {
    return baseHTTP(
      "put",
      `/app/admin/checkout-payment/${distributor}`,
      payload
    );
  },

  async activateUsers(data) {
    return baseHTTP("put", `/app/admin/users/activateUsers`, data);
  },
};
