import { Integrator } from "@/request";

export const actions = {
  //### LEAD ###
  async createLeadFromLeads(context, payload) {
    try {
      payload.email = payload.email ? payload.email.toLowerCase() : "";
      return await Integrator.createLead(payload);
    } catch (e) {
      throw e.response || e;
    }
  },

  async importLeadsFromLeads(context, payload) {
    try {
      const ret = await Integrator.importLeads(payload);
      if (!ret.error && ret.data) context.commit("READ_ALL_LEADS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async readLeadsFromLeads(context) {
    try {
      const ret = await Integrator.readLeads(
        "leads",
        context.state.leadsTableInfo.page,
        context.state.leadsTableInfo.itemPerPage,
        "readAmount"
      );
      if (!ret.error && ret.data) context.commit("READ_ALL_LEADS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async readLeadFromLeads(context, payload) {
    try {
      const ret = await Integrator.readLead(payload);
      if (!ret.error && ret.data) context.commit("READ_LEAD", ret.data[0]);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async exportLeadsFromLeads() {
    try {
      await Integrator.exportLeads("Clientes.xlsx");
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateLeadFromLeads(context, payload) {
    try {
      payload.lead.email = payload.lead.email
        ? payload.lead.email.toLowerCase()
        : "";
      if (payload.lead.customFields?.length)
        payload.lead.customFields.forEach((item) => {
          if (item.addedBy) item.addedBy = item.addedBy.id;
        });
      const ret = await Integrator.updateLead({
        id: payload.lead.id,
        address: payload.lead.address,
        cellphone: payload.lead.cellphone,
        email: payload.lead.email,
        name: payload.lead.name,
        phone: payload.lead.phone,
        surname: payload.lead.surname,
        customFields: payload.lead.customFields,
        cpf: payload.lead.cpf,
        cnpj: payload.lead.cnpj,
        responsible: payload.lead.responsible,
        origin: payload.lead.origin,
        otherOrigin: payload.lead.otherOrigin,
      });
      if (!ret.error && ret.data)
        context.commit("UPDATE_LEAD", {
          ...ret.data,
          cameFrom: payload.cameFrom,
        });
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteLeadFromLeads(context, payload) {
    try {
      const ret = await Integrator.deleteLead(payload);
      if (!ret.error && ret.data) context.commit("DELETE_LEAD", payload);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async addLeadObservationFromLeads(context, payload) {
    try {
      const ret = await Integrator.addLeadObservation(
        payload.leadId,
        payload.observation
      );
      if (!ret.error && ret.data) context.commit("ADD_OBSERVATION", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteLeadObservationFromLeads(context, payload) {
    try {
      const ret = await Integrator.deleteLeadObservation(
        payload.observationId,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("DELETE_OBSERVATION", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  //#### TASKS ###
  async createTaskFromLead(context, payload) {
    try {
      const ret = await Integrator.createTask(payload);
      if (!ret.error && ret.data) context.commit("CREATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFromLead(context, payload) {
    try {
      payload.responsible = payload.responsible.id || null;
      const ret = await Integrator.updateTask(payload);
      if (!ret.error && ret.data) context.commit("UPDATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFinishedFromLead(context, payload) {
    try {
      const ret = await Integrator.updateTaskFinished(
        payload.taskId,
        payload.finished,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_TASK_FINISHED", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteTaskFromLead(context, payload) {
    try {
      const ret = await Integrator.deleteTask(payload.taskId, payload.leadId);
      if (!ret.error && ret.data) context.commit("DELETE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  //#### SYSTEMS ####

  async createSystemFromLeads(context, payload) {
    try {
      const ret = await Integrator.createSystem(payload);
      if (!ret.error && ret.data) context.commit("CREATE_SYSTEM", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateSystemFromLeads(context, payload) {
    try {
      const ret = await Integrator.updateSystem(payload);
      if (!ret.error && ret.data) context.commit("UPDATE_SYSTEM", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateSystemStatusFromLeads(context, payload) {
    try {
      const ret = await Integrator.updateSystemStatus(
        payload.systemId,
        payload.newStatus,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_SYSTEM_STATUS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteSystemFromLeads(context, payload) {
    try {
      const ret = await Integrator.deleteSystem(
        payload.systemId,
        payload.leadId
      );
      if (!ret.error && ret.data) context.commit("DELETE_SYSTEM", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async createSystemCommentByStatusFromLeads(context, payload) {
    try {
      const ret = await Integrator.createSystemCommentByStatus(
        payload.systemId,
        payload.status,
        payload.comment,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_SYSTEM_COMMENTS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteSystemCommentByStatusFromLeads(context, payload) {
    try {
      const ret = await Integrator.deleteSystemCommentByStatus(
        payload.systemId,
        payload.commentId,
        payload.commentStatus,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("DELETE_SYSTEM_COMMENT", payload);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  //#### SIZING ####
  async createUpdateSizingFromLeads(context, payload) {
    try {
      const ret = await Integrator.createSizing(payload);
      if (!ret.error && ret.data)
        context.dispatch("readLeadFromLeads", context.getters.getLeadId);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteSizingFromLeads(context, payload) {
    try {
      const ret = await Integrator.deleteSizing(
        payload.leadId,
        payload.sizingId,
        payload.systemId
      );
      if (!ret.error && ret.data)
        context.commit("DELETE_SIZING", {
          systemId: payload.systemId,
          sizingId: payload.sizingId,
          activities: ret.data.activities,
        });
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  //#### PROPOSAL ####
  async createProposal(context, payload) {
    try {
      const ret = await Integrator.createProposal(payload);
      if (!ret.error && ret.data)
        context.dispatch("readLeadFromLeads", context.getters.getLeadId);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteProposal(context, payload) {
    try {
      const ret = await Integrator.deleteProposal(
        payload.proposalId,
        payload.leadId,
        payload.systemId
      );
      if (!ret.error && ret.data)
        context.commit("DELETE_PROPOSAL", {
          ...payload,
          activities: ret.data.activities,
        });
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async createLeadToken(context, payload) {
    const ret = await Integrator.createLeadToken(payload);
    if (!ret.error && ret.data) context.commit("CREATE_LEAD_TOKEN", ret.data);
    return ret;
  },
};
