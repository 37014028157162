import User from "@/request/routes/User";

const state = {
  products: [],
  manufacturers: [],
  type: "",
};

const mutations = {
  setManufacturers(state, payload) {
    state.type = payload.type;
    state.manufacturers = payload.rows;
  },
};

const actions = {
  async findManufacture(store, query = { type: "manufacturer" }) {
    const result = await User.supportAutoComplete("manufacturer", query);

    store.commit("setManufacturers", {
      type: query.type,
      rows: result?.data || [],
    });
  },
};

const getters = {
  products: (state) => state.products,
  manufacturer: (state) => state.manufacturers,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
