import Socket from "@/plugins/socket";
import { generateUUID } from "@/utils/StringUtils";

export class BaseTracking {
  type = "";
  clicks = [];
  session = generateUUID();
  extraInfo = {};

  constructor(type) {
    this.type = type;
  }

  onClick(id) {
    const trackedId = BaseTracking.trackedIds(this.type).includes(id);
    if (!trackedId) return;
    this.clicks.push({
      id,
      date: new Date(),
    });
    this.socketEmit();

    const resetId = BaseTracking.resetIds(this.type).includes(id);
    if (resetId) this.resetSession();
  }

  addExtraInfo(extraInfo = {}) {
    Object.assign(this.extraInfo, extraInfo);
    if (this.clicks.length) this.socketEmit();
  }

  socketEmit() {
    if (!Socket?.io) return;
    if (Socket?.io)
      Socket.io.emit("tracking", {
        session: this.session,
        clicks: this.clicks,
        type: this.type,
        ...this.extraInfo,
      });
  }

  resetSession() {
    this.clicks = [];
    this.session = generateUUID();
  }

  static trackedIds(type) {
    switch (type) {
      case "sizing":
        return [
          "backSizing",
          "goToKitsIntegrator",
          "goToMountKit",
          "goToProposalValues",
          "goToSystemDetails",
        ];
      default:
        return [];
    }
  }

  static resetIds(type) {
    switch (type) {
      case "sizing":
        return ["backSizing"];
      default:
        return [];
    }
  }

  static routeTrackingType(route) {
    if (
      [
        "Sizing",
        "SizingComponent",
        "ResultSizing",
        "SizingFast",
        "SizingCRM",
        "SizingComponentCRM",
        "SizingFastCRM",
        "ResultSizingCRM",
      ].includes(route)
    ) {
      return "sizing";
    }
    return null;
  }
}
