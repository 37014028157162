import { checkIsUserAllowedEditRequest, checkPermissions } from "@/utils/utils";
import { Staff, User } from "@/request";

const state = {
  budgetSettings: null,
  permissionsLoaded: false,
  discountSettings: {
    discount: 0,
    permission: [],
    allowedRequests: [],
  },
};

const mutations = {
  SET_PERMISSIONS_LOADED(state, payload) {
    state.permissionsLoaded = payload;
  },

  SET_BUDGET_SETTING(state, payload) {
    state.budgetSettings = payload;
  },

  SET_DISCOUNT_SETTING_PLATAFORM(state, payload) {
    state.discountSettings = payload;
  },
};

const getters = {
  permissionsLoaded: (state) => state.permissionsLoaded,
  budgetSettings: (state) => state.budgetSettings,
};

const actions = {
  /**
   * @param dispatch
   * @param rootGetters
   * @param request {OrganizedRequestDetails}
   */
  async getPermissions({ state, dispatch, rootGetters }, request) {
    if (!request) throw new Error("Não foi passado o request");
    const user = rootGetters["userData/getUserData"];
    if (!user) return {};

    if (!state.permissionsLoaded) {
      await dispatch("loadUserPermission");
    }

    return {
      increase: await dispatch("hasPermissionIncrease", request),
      discount: await dispatch("hasPermissionDiscount", request),
      annotations: await dispatch("hasPermissionAnnotations", request),
      changeHistory: await dispatch("hasPermissionChangeHistory", request),
      payment: await dispatch("hasPermissionPayment", request),
      edit: await dispatch("hasPermissionEdit", request),
      convert: await dispatch("hasPermissionConvert", request),
      timeline: await dispatch("hasPermissionTimeline", request),
      changeStatus: await dispatch("hasPermissionChangeStatus", request),
      changeSeller: await dispatch("hasPermissionChangeSeller", request),
      delete: await dispatch("hasPermissionDelete", request),
      downloadPdf: await dispatch("hasPermissionDownloadPdf", request),
      downloadDoc: await dispatch("hasPermissionDownloadDoc", request),
      duplicate: await dispatch("hasPermissionDuplicate", request),
      changeStatusPayment: await dispatch(
        "hasPermissionChangeStatusPayment",
        request
      ),
      updateExpiredBudget: await dispatch(
        "hasPermissionUpdateExpiredBudget",
        request
      ),
      files: {
        view: await dispatch("hasPermissionFile", { request, type: "" }),
        change: await dispatch("hasPermissionFile", {
          request,
          type: "change",
        }),
        remove: await dispatch("hasPermissionFile", {
          request,
          type: "remove",
        }),
        download: await dispatch("hasPermissionFile", {
          request,
          type: "download",
        }),
      },
    };
  },

  async loadUserPermission({ dispatch, state, commit, rootGetters }) {
    if (state.permissionsLoaded) return;

    const setBudgetSettings = async () => {
      if (!state.budgetSettings) {
        const ret = await User.readBudgetSetting();

        if (ret.error || !ret.data) return false;

        commit("SET_BUDGET_SETTING", ret.data.budgetSettings);
      }
    };

    const setDiscountSettings = async () => {
      const ret = await Staff.readDiscountSetting();

      if (ret?.data?.discountSettings) {
        commit("SET_DISCOUNT_SETTING_PLATAFORM", ret?.data?.discountSettings);
      } else {
        commit("SET_DISCOUNT_SETTING_PLATAFORM", {
          discount: 0,
          permission: [],
          allowedRequests: [],
        });
      }
    };

    const promises = [setBudgetSettings()];
    const userType = rootGetters["userData/accountType"];
    if (userType === "integrator")
      promises.push(
        dispatch("companyPermissions/readPermissions", {}, { root: true })
      );
    else if (userType === "staff" || userType === "admin")
      promises.push(setDiscountSettings());
    await Promise.all(promises);

    commit("SET_PERMISSIONS_LOADED", true);
  },

  hasPermissionIncrease({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.increase`];

    return checkPermissions(staffPermission, [], user);
  },

  async hasPermissionDiscount({ state, rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.discount`];
    const isBudget = request.type === "budget";

    if (!checkPermissions(staffPermission, [], user)) {
      return false;
    }

    let discount = 0;
    if (user.type === "admin") {
      discount = state.discountSettings.discount;
    }

    if (user.type === "staff") {
      discount = isBudget
        ? user.role.limitDiscountBudget
        : user.role.limitDiscountOrder;
    }

    return discount > 0;
  },

  hasPermissionAnnotations({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.annotations`];

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionChangeHistory({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.changehistory`];

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionPayment({ rootGetters }, request) {
    if (request.type !== "order") return false;

    const user = rootGetters["userData/getUserData"];
    const staffPermission = ["order.details.payment"];
    const integratorPermission = ["order.details.payment"];

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionEdit({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.edit`];
    const integratorPermission = [`${request.type}.edit`];

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionConvert({ rootGetters }, request) {
    if (request.type !== "budget") return false;
    if (rootGetters["userData/isBackOfficeUser"]) return false;

    const user = rootGetters["userData/getUserData"];
    const staffPermission = ["budget.toOrder"];
    const integratorPermission = ["budget.toOrder"];

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionTimeline({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];

    if (user.type === "integrator") return false;
    if (!rootGetters["userData/hasAction"]("requestTimeline")) return false;

    const staffPermission = [`${request.type}.details.timeline`];

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionChangeStatus({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.changeStatus`];

    if (request.type === "budget" && !user.actions.budgetStatus) return false;

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionChangeStatusPayment({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = ["order.details.payment.statusEdit"];
    const integratorPermission = ["order.details.payment.statusEdit"];

    if (user.type === "integrator" || request.type === "budget") return false;

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionChangeSeller({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.changeSeller`];

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionDelete({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.delete`];

    if (request.type === "budget" && rootGetters["userData/isBackOfficeUser"]) {
      return false;
    }

    return checkPermissions(staffPermission, [], user);
  },

  hasPermissionDownloadPdf({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.fileDownload.pdf`];
    const integratorPermission = [`${request.type}.fileDownload.pdf`];

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionDownloadDoc({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.fileDownload.doc`];
    const integratorPermission = [`${request.type}.fileDownload.doc`];

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  hasPermissionDuplicate({ rootGetters }, request) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [request.type];
    const integratorPermission = [request.type];

    if (request.type !== "budget") return false;

    return checkPermissions(staffPermission, integratorPermission, user);
  },

  async hasPermissionUpdateExpiredBudget(
    { state, commit, dispatch, rootGetters },
    request
  ) {
    const user = rootGetters["userData/getUserData"];
    if (!user.actions?.updateExpiredBudget) return false;
    if (request.type !== "budget") return false;

    const { enableUpdateExpiredBudget } = state.budgetSettings;

    return user.type !== "integrator" || enableUpdateExpiredBudget;
  },

  hasPermissionFile({ rootGetters }, { request, type }) {
    const user = rootGetters["userData/getUserData"];
    const staffPermission = [`${request.type}.details.files`];
    const integratorPermission = [`${request.type}.details.files`];

    if (type) {
      staffPermission.push(`${request.type}.details.files.${type}`);
      integratorPermission.push(`${request.type}.details.files.${type}`);
    }

    return checkPermissions(staffPermission, integratorPermission, user);
  },
  async fetchUserAllowedEditRequest({}, request) {
    const date = request.expiryDateUpdate ?? request.createdAt;

    return checkIsUserAllowedEditRequest(date);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
