import BaseFilter from "./BaseFilter";

export default class ProductClassFilterInverter extends BaseFilter {
  constructor() {
    super({
      minOutPower: null,
      maxOutPower: null,
      isToSellOptions: [],
      manufacturer: [],
      minPrice: null,
      maxPrice: null,
      inverter: [],
      connection: [],
      voltage: null,
      productCode: null,
      name: null,
      model: null,
    });
  }
}
