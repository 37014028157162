import { BaseTracking } from "./BaseTracking";

const state = {
  entity: null,
};

const mutations = {
  ["CHANGE_SESSION"](state, type) {
    if (state.entity?.type === type) return;
    state.entity = new BaseTracking(type);
  },
  ["CLICK"](state, payload) {
    if (state.entity) state.entity.onClick(payload);
  },
  ["EXTRA_INFO"](state, payload) {
    if (state.entity) state.entity.addExtraInfo(payload);
  },
};

const actions = {
  ["PAGE_CHANGE"]({ commit, dispatch }, payload) {
    const name = payload?.to?.name || "";
    commit("CHANGE_SESSION", BaseTracking.routeTrackingType(name));
  },
};

const getters = {
  entity: (state) => state.entity,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
