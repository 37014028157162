import Vuetify from "@/plugins/vuetify";
import { Storage } from "@/localStorage";
import { DISTRIBUTOR_THEME } from "@/localStorage/storageKeys";
import { baseURL } from "@/request/http";

const getDefaultSideBar = () => {
  return [
    {
      name: "DashboardIntegrator",
      title: "Visão Geral",
      customTitle: "",
      icon: "mdi-view-grid",
    },
    {
      name: "Budgets",
      title: "Orçamentos",
      customTitle: "",
      icon: "mdi-currency-usd",
    },
    {
      name: "Requests",
      title: "Pedidos",
      customTitle: "",
      icon: "mdi-clipboard-text-outline",
    },
    {
      name: "Sizing",
      title: "Engenheiro Virtual",
      customTitle: "",
      icon: "mdi-calculator",
    },
    {
      name: "KitsExpress",
      title: "Kits Express",
      customTitle: "",
      icon: "mdi-dolly",
    },
    {
      name: "KitsIntegrator",
      title: "Kits Fechados",
      customTitle: "",
      icon: "mdi-bag-personal",
    },
    {
      name: "MountKit",
      title: "Kits Personalizados",
      customTitle: "",
      icon: "mdi-bag-personal",
    },
    {
      name: "Separate",
      title: "Produtos Avulsos",
      customTitle: "",
      icon: "mdi-bottle-tonic",
    },
  ];
};
const getDefaultSideBarPosition = () => "left";
export const stateLoginTheme = Object.freeze({
  type: "minimalist",
  highlightColor: undefined,
  image: null,
  align: "center",
  links: {
    linkedin: {
      url: null,
      selected: false,
    },
    facebook: {
      url: null,
      selected: false,
    },
    instagram: {
      url: null,
      selected: false,
    },
    whatsapp: {
      url: null,
      selected: false,
    },
    youtube: {
      url: null,
      selected: false,
    },
    web: {
      url: null,
      selected: false,
    },
  },
});

const state = {
  theme: {
    frontendSettings: [],
    companyTheme: {
      loginTheme: Object.assign({}, stateLoginTheme),
    },
  },
  appBar: true,
  mini: false,
  isButtonOpenCloseNavBarHidden: false,
  piedAdmin: false,
  title: "Portal Pied",
  sideBarPosition: getDefaultSideBarPosition(),
  sideBar: getDefaultSideBar(),
};

const mutations = {
  UPDATE_APP_BAR(state, payload) {
    state.appBar = payload;
  },
  UPDATE_APP_SIDE_BAR(state, { sideBar, position }) {
    state.sideBar = sideBar || getDefaultSideBar();
    state.sideBarPosition = position || getDefaultSideBarPosition();
  },
  UPDATE_MINI(state, payload) {
    state.mini = payload;
  },
  UPDATE_IS_BUTTON_OPEN_CLOSE_NAV_BAR_HIDDEN(state, payload) {
    state.isButtonOpenCloseNavBarHidden = payload;
  },
  UPDATE_LOGIN_THEME(state, payload) {
    if (payload.image && payload.image instanceof File) {
      payload.image = URL.createObjectURL(payload.image);
    }

    state.theme = {
      ...state.theme,
      companyTheme: {
        ...state.theme.companyTheme,
        loginTheme: payload,
      },
    };
  },

  async UPDATE_THEME(state, payload) {
    const vuetify = Vuetify.framework.theme.themes.light;
    const companyTheme = payload?.companyTheme || state.theme?.companyTheme;

    const rootDoc = document.querySelector(":root");
    rootDoc.style.setProperty("--angleLinearGradient", "0deg");

    document
      .getElementById("favicon")
      .setAttribute(
        "href",
        payload?.piedAdmin
          ? `${baseURL}/favicon.ico`
          : `${baseURL}/app/distribuitors/${payload?.id}/favicon.ico`
      );

    if (!companyTheme) return;

    vuetify.primary = companyTheme.primaryColor;
    vuetify.secondary = companyTheme.secondaryColor;
    vuetify.button = companyTheme.buttonsColor;
    vuetify.icon = companyTheme.iconsColor;
    vuetify.colorOneLinearGradient = companyTheme.colorOneLinearGradient;
    vuetify.colorTwoLinearGradient = companyTheme.colorTwoLinearGradient;
    vuetify.textColor = "#333333";
    rootDoc.style.setProperty(
      "--angleLinearGradient",
      companyTheme.angleLinearGradient
    );

    if (payload?.title) state.title = payload.title;
    state.piedAdmin = payload?.piedAdmin || false;
    state.theme = payload || state.theme;

    const updateCompanyTheme = Object.assign({}, companyTheme);

    if (!state.theme.companyTheme?.loginTheme) {
      Object.assign(updateCompanyTheme, {
        loginTheme: Object.assign({}, stateLoginTheme, {
          highlightColor: companyTheme.primaryColor,
        }),
      });
    }

    Object.assign(state.theme.companyTheme, updateCompanyTheme);

    await Storage.setItem(DISTRIBUTOR_THEME, payload);
  },
};

const actions = {
  updateAppBar({ commit }, value) {
    commit("UPDATE_APP_BAR", value);
    commit("UPDATE_MINI", false);
  },
  updateMiniVariant({ commit }, value) {
    commit("UPDATE_MINI", value);
    commit("UPDATE_APP_BAR", true);
  },
  updateSideBar({ commit }, { sideBar, position }) {
    commit("UPDATE_APP_SIDE_BAR", { sideBar, position });
  },
  updateIsButtonOpenCloseNavBarHidden({ commit }, value) {
    commit("UPDATE_IS_BUTTON_OPEN_CLOSE_NAV_BAR_HIDDEN", value);
  },
  updateLoginTheme({ commit }, value) {
    commit("UPDATE_LOGIN_THEME", value);
  },
};

const getters = {
  isPiedAdmin: (state) => state.piedAdmin,
  getTheme: (state) => state.theme,
  getTerm: (state) => state.theme?.term,
  getSideBar: (state) => state.sideBar,
  getSideBarPosition: (state) => state.sideBarPosition,
  getDefaultBreadcrumb: (state) => (routeName) => {
    const { customTitle, title } = state.sideBar.find(
      (sBar) => sBar.name === routeName
    );
    return { text: customTitle || title, routeName };
  },
  hasTerms: (state) => !!state.theme?.term?.id,
  getMetaDescription: (state) => state.theme?.metaDescription,
  getTitle: (state) => state.title,
  setting: (state) =>
    state.theme.frontendSettings?.find((config) => config.service === "pied") ||
    {},
  getLoginTheme: (state) =>
    state.theme.companyTheme.loginTheme || stateLoginTheme,
  isLoginMinimalist: (state, getters) =>
    getters.getLoginTheme.type === "minimalist",
  isButtonOpenCloseNavBarHidden: (state) => state.isButtonOpenCloseNavBarHidden,
  integration: (state) => (type) =>
    state.theme.integrations.find((h) => h.type === type),
  malga: (state, getters) =>
    getters.integration("malga") || {
      options: [],
    },
  enabledPaymentMethod: (state, getters) => (type) =>
    getters.malga?.options?.methods?.includes(
      type === "credito" ? "credit" : type
    ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
