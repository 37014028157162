import Icons from "@/constants/Icons";

export default [
  {
    path: "/leads",
    name: "Leads",
    meta: {
      icon: Icons.ACCOUNTGROUP,
      title: "Clientes",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["crm"],
    },
    component: () => import("@/views/leadsIntegrator/Leads.vue"),
    children: [
      {
        path: "details",
        name: "LeadDetails",
        meta: {
          title: "Detalhes do Cliente",
          local: ["navBar"],
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: ["crm"],
        },
        component: () => import("@/views/leadsIntegrator/LeadDetails.vue"),
        children: [
          {
            path: "main/:leadId",
            name: "LeadDetailsMain",
            meta: {
              title: "Detalhes do Cliente",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import("@/views/leadsIntegrator/leadDetails/Main.vue"),
          },
          {
            path: "generalData/:leadId",
            name: "LeadDetailsGeneralData",
            meta: {
              title: "Dados Gerais do Cliente",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import("@/views/leadsIntegrator/leadDetails/GeneralData.vue"),
          },
          {
            path: "systems/:leadId",
            name: "LeadDetailsSystems",
            meta: {
              title: "Sistemas do Cliente",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import("@/views/leadsIntegrator/leadDetails/Systems.vue"),
          },
          {
            path: "proposals/:leadId",
            name: "LeadDetailsProposals",
            meta: {
              title: "Propostas do Cliente",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import("@/views/leadsIntegrator/leadDetails/Proposals.vue"),
          },

          {
            path: "requets/:leadId",
            name: "LeadDetailsRequests",
            meta: {
              title: "Orçametos e Pedidos do Cliente",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import("@/views/leadsIntegrator/leadDetails/Requests.vue"),
          },

          {
            path: "systemDetails/:leadId/:systemId",
            name: "SystemDetails",
            meta: {
              title: "Detalhes do Sistema",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () =>
              import(
                "@/views/leadsIntegrator/leadDetails/system/SystemDetails.vue"
              ),
          },
          {
            path: "/proposaldetails/:leadId/:proposalId",
            name: "ProposalDetails",
            meta: {
              icon: Icons.BAG,
              title: "Detalhes da Proposta",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"], //can be changed in NavBar depending on configuration
              integratorPermissions: ["crm"],
            },
            component: () =>
              import(
                "@/views/leadsIntegrator/leadDetails/proposal/ProposalDetails.vue"
              ),
            props: true,
          },

          {
            path: "/budgetDetails/:leadId/:requestId",
            name: "BudgetDetails",
            meta: {
              icon: Icons.BAG,
              title: "Detalhes do Orçamento",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"], //can be changed in NavBar depending on configuration
              integratorPermissions: ["crm"],
            },
            component: () =>
              import(
                "@/views/leadsIntegrator/leadDetails/requests/RequestDetails"
              ),
            props: true,
          },
          {
            path: "/orderDetails/:leadId/:requestId",
            name: "OrderDetails",
            meta: {
              icon: Icons.BAG,
              title: "Detalhes do Pedido",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"], //can be changed in NavBar depending on configuration
              integratorPermissions: ["crm"],
            },
            component: () =>
              import(
                "@/views/leadsIntegrator/leadDetails/requests/RequestDetails"
              ),
            props: true,
          },

          {
            path: "sizing/:leadId/:systemId",
            name: "SizingCRM",
            redirect: { name: "SizingComponentCRM" },
            meta: {
              title: "Dimensionamento",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () => import("@/views/sizing/PageSizing.vue"),
            children: [
              {
                path: "sizingCRM",
                name: "SizingComponentCRM",
                meta: {
                  title: "Dimensionamento",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"],
                  integratorPermissions: ["crm"],
                },
                component: () =>
                  import("@/components/plataform/sizing/form/SizingForm.vue"),
              },
              {
                path: "sizingFastCRM",
                name: "SizingFastCRM",
                meta: {
                  title: "Dimensionamento rápido",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"],
                  integratorPermissions: ["crm"],
                },
                component: () => import("@/views/sizing/PageSizingFast.vue"),
              },
              {
                path: "resultSizingCRM",
                name: "ResultSizingCRM",
                meta: {
                  title: "Resultado dimensionamento",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"],
                  integratorPermissions: ["crm"],
                },
                component: () => import("@/views/sizing/ResultSizing.vue"),
              },
            ],
          },
          {
            path: "proposal",
            name: "ProposalCRM",
            meta: {
              title: "Proposta",
              local: ["navBar"],
              actions: ["enableCRM"],
              permissions: ["integrator"],
              integratorPermissions: ["crm"],
            },
            component: () => import("@/views/proposal/ProposalCRM.vue"),
            props: true,
            children: [
              {
                path: "/KitsIntegrator/:leadId/:systemId",
                name: "KitsIntegratorCRM",
                meta: {
                  icon: Icons.DOLLY,
                  title: "Kits Fechados",
                  local: ["navBar"],
                  actions: ["enableClosedKits", "enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () =>
                  import("@/views/kitsIntegrator/KitsIntegrator.vue"),
                props: true,
              },
              {
                path: "/MountKit/:leadId/:systemId",
                name: "MountKitCRM",
                meta: {
                  icon: Icons.DOLLY,
                  title: "Kits Personalizados",
                  local: ["navBar"],
                  actions: ["kitMount", "enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () => import("@/views/mountKit/PageMountKit.vue"),
                props: true,
              },
              {
                path: "/ProposalValues/:leadId/:systemId",
                name: "ProposalValuesCRM",
                meta: {
                  icon: Icons.BAG,
                  title: "Valores da Proposta",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () => import("@/views/proposal/ProposalValues.vue"),
                props: true,
              },
              {
                path: "/SimulateFinancing/:leadId/:systemId",
                name: "SimulateFinancingCRM",
                meta: {
                  icon: Icons.BAG,
                  title: "Simulação de Financiamento",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () =>
                  import("@/views/proposal/FinancingSimulation.vue"),
                props: true,
              },
              {
                path: "/FinancingSimulationResult/:leadId/:systemId",
                name: "FinancingSimulationResultCRM",
                meta: {
                  icon: Icons.BAG,
                  title: "Resultado da Simulação",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () =>
                  import("@/views/proposal/FinancingSimulationResult.vue"),
                props: true,
              },
              {
                path: "/ProposalPayback/:leadId/:systemId",
                name: "ProposalPaybackCRM",
                meta: {
                  icon: Icons.BAG,
                  title: "Payback da Proposta",
                  local: ["navBar"],
                  actions: ["enableCRM"],
                  permissions: ["integrator"], //can be changed in NavBar depending on configuration
                  integratorPermissions: ["crm"],
                },
                component: () => import("@/views/proposal/ProposalPayback.vue"),
                props: true,
              },
            ],
            redirect: { name: "LeadDetailsProposals" },
          },
        ],
        redirect: { name: "LeadDetailsMain" },
      },
    ],
  },
  {
    path: "/negotiations",
    name: "Negotiations",
    meta: {
      icon: Icons.NEGOTIATIONS,
      title: "Negociações",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["crm"],
    },
    component: () => import("@/views/businessIntegrator/Negotiations.vue"),
  },
  {
    path: "/sold",
    name: "Sold",
    meta: {
      icon: Icons.CASH3,
      title: "Vendidos",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["crm"],
    },
    component: () => import("@/views/businessIntegrator/Sold.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    meta: {
      icon: Icons.CALENDAR2,
      title: "Agenda",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["crm"],
    },
    component: () => import("@/views/scheduleIntegrator/Schedule.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    meta: {
      icon: Icons.BRIEFCASE,
      title: "Tarefas",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["crm"],
    },
    component: () => import("@/views/tasksIntegrator/Tasks.vue"),
  },
  {
    path: "/integratorUsers",
    name: "IntegratorUsers",
    meta: {
      icon: Icons.ACCOUNTGROUP,
      title: "Colaboradores",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["integratorUsers", "crm"],
    },
    component: () => import("@/views/integratorUsers/IntegratorUsers.vue"),
    children: [
      {
        path: "/integratorUsers/details/:integratorUserId",
        name: "IntegratorsUserDetails",
        meta: {
          title: "Detalhe do Colaborador",
          local: ["navBar"],
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: ["integratorUsers.details", "crm"],
        },
        component: () =>
          import("@/views/integratorUsers/IntegratorUserDetails.vue"),
      },
    ],
  },
  {
    path: "/proposalSettings",
    name: "ProposalSettings",
    meta: {
      icon: Icons.FILECOG,
      title: "Configurações da Proposta",
      local: ["navBar"],
      actions: ["enableCRM"],
      permissions: ["integrator"],
      integratorPermissions: ["proposalSettings", "crm"],
      isGroup: true,
    },
    component: () => import("@/views/GroupRouter.vue"),
    children: [
      {
        path: "/proposalSettings/defaultInformations",
        name: "DefaultInformations",
        meta: {
          icon: Icons.INFORMATION3,
          title: "Informações Padrões",
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: [
            "proposalSettings.defaultInformations",
            "crm",
          ],
        },
        component: () =>
          import("@/views/proposalSettingsIntegrator/DefaultInformations.vue"),
      },
      {
        path: "/proposalSettings/defaultValuesAndPermissions",
        name: "DefaultValuesAndPermissions",
        meta: {
          icon: Icons.LOCK2,
          title: "Permissões e Valores Padrões",
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: [
            "proposalSettings.defaultValuesAndPermissions",
            "crm",
          ],
        },
        component: () =>
          import(
            "@/views/proposalSettingsIntegrator/DefaultValuesAndPermissions.vue"
          ),
      },
      {
        path: "/proposalSettings/financing",
        name: "FinancingIntegrator",
        meta: {
          icon: Icons.FINANCE,
          title: "Financiamento",
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: ["proposalSettings.financing", "crm"],
        },
        component: () =>
          import("@/views/proposalSettingsIntegrator/Financing.vue"),
      },
      {
        path: "/proposalSettings/IntegratorProposalSettingsTemplates",
        name: "IntegratorProposalSettingsTemplates",
        meta: {
          icon: Icons.FILEDOCUMENTMULTIPLE,
          title: "Modelos de Proposta",
          actions: ["enableCRM"],
          permissions: ["integrator"],
          integratorPermissions: ["proposalSettings.proposalTemplates", "crm"],
        },
        component: () =>
          import(
            "@/views/proposalSettingsIntegrator/IntegratorProposalSettingsTemplates.vue"
          ),
      },
    ],
  },
];
