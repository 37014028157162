import Icons from "@/constants/Icons";

export default [
  {
    path: "/sizing",
    name: "Sizing",
    redirect: { name: "SizingComponent" },
    meta: {
      icon: Icons.CALCULATORVARIANT,
      title: "Engenheiro Virtual",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["sizing"],
      staffPermissions: ["sizing"],
      params: { resetSizing: true },
    },
    props: true,
    component: () => import("@/views/sizing/PageSizing.vue"),
    children: [
      {
        path: "",
        name: "SizingComponent",
        meta: {
          title: "Dimensionamento",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          integratorPermissions: ["sizing"],
          staffPermissions: ["sizing"],
        },
        component: () =>
          import("@/components/plataform/sizing/form/SizingForm.vue"),
      },
      {
        path: "/sizing/SizingFast",
        name: "SizingFast",
        meta: {
          title: "Dimensionamento rápido",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          integratorPermissions: ["sizing"],
          staffPermissions: ["sizing"],
        },
        component: () => import("@/views/sizing/PageSizingFast.vue"),
      },

      {
        path: "/sizing/resultSizing",
        name: "ResultSizing",
        meta: {
          title: "Resultado dimensionamento",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          integratorPermissions: ["sizing"],
          staffPermissions: ["sizing"],
        },
        component: () => import("@/views/sizing/ResultSizing.vue"),
      },
    ],
  },
  {
    path: "/kits-express",
    name: "KitsExpress",
    meta: {
      icon: Icons.DOLLY,
      title: "Kits Express",
      local: ["navBar"],
      actions: ["kitExpress"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["kitExpress"],
      staffPermissions: ["requestsCreation", "requestsCreation.kitExpress"],
    },
    component: () => import("@/views/sales/PageSalesKitExpress.vue"),
  },
  {
    path: "/kitsIntegrator/:id?",
    name: "KitsIntegrator",
    meta: {
      icon: Icons.DOLLY,
      title: "Kits Fechados",
      local: ["navBar"],
      actions: ["enableClosedKits"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["closedKits"],
      staffPermissions: ["requestsCreation", "requestsCreation.closedKits"],
    },
    component: () => import("@/views/sales/PageSales.vue"),
  },
  {
    path: "/mountKit/:id?",
    name: "MountKit",
    props: true,
    meta: {
      icon: Icons.DOLLY,
      title: "Kits Personalizados",
      local: ["navBar"],
      actions: ["kitMount"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["customKits"],
      staffPermissions: ["requestsCreation", "requestsCreation.customKits"],
    },
    component: () => import("@/views/mountKit/PageMountKit.vue"),
  },
  {
    path: "/separateSale/:id?",
    name: "Separate",
    meta: {
      icon: Icons.DOLLY,
      title: "Produtos Avulsos",
      local: ["navBar"],
      actions: ["singleProductPurchase"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["separateSale"],
      staffPermissions: ["requestsCreation", "requestsCreation.separateSale"],
    },
    component: () => import("@/views/sales/PageSales.vue"),
    props: true,
  },
];
