export default {
  ACCOUNT: "mdi-account",
  ACCOUNTALERT: "mdi-account-alert",
  ACCOUNTCANCEL: "mdi-account-cancel",
  ACCOUNTCIRCLE: "mdi-account-circle",
  ACCOUNTCOG: "mdi-account-cog",
  ACCOUNTDETAILS: "mdi-account-details",
  ACCOUNTEDIT: "mdi-account-edit",
  ACCOUNTGROUP: "mdi-account-group",
  ACCOUNTMULTIPLE: "mdi-account-multiple",
  ACCOUNTPLUS: "mdi-account-plus",
  ACCOUNTSEARCH: "mdi-account-search",
  ACCOUNTSUPERVISOR: "mdi-account-supervisor",
  ACCOUNTTIE: "mdi-account-tie",
  ACCOUNTTIEOUTLINE: "mdi-account-tie-outline",
  ACCOUNTTIEVOICE: "mdi-account-tie-voice",
  ACCOUNTCASH: "mdi-account-cash",
  ACTIVITIES: "mdi-camera-timer",
  ADD: "mdi-plus",
  ADD2: "mdi-plus-circle",
  ADD3: "mdi-plus-circle-outline",
  ADDRESSCEP: "mdi-home-search-outline",
  ADDRESSCITY: "mdi-home-city-outline",
  ADDRESSNEIGHBORHOOD: "mdi-home-group",
  ADDRESSNUMBER: "mdi-home",
  ADDRESSPATIO: "mdi-road-variant",
  ADDRESSSTATE: "mdi-city-variant-outline",
  ADVERTISEMENTS: "mdi-bullhorn-outline",
  ALERT: "mdi-alert",
  ALERT2: "mdi-alert-circle-outline",
  ALERT3: "mdi-alert-circle",
  ALPHA1: "mdi-alpha-s-box-outline",
  ALPHA2: "mdi-alpha-t-circle",
  ALPHA3: "mdi-alpha-s-circle",
  ARRANGE: "mdi-arrange-bring-forward",
  ARRANGEFRONT: "mdi-arrange-bring-to-front",
  ARROWDOWN: "mdi-arrow-down",
  ARROWDOWNCIRCLE: "mdi-arrow-down-thin-circle-outline",
  ARROWUP: "mdi-arrow-up",
  ARROWDOWNBOLD: "mdi-arrow-down-bold",
  ARROWDOWNTHIN: "mdi-arrow-down-thin",
  ARROWUPBOLD: "mdi-arrow-up-bold",
  ARROWUPTHIN: "mdi-arrow-up-thin",
  ARROWALL: "mdi-arrow-all",
  ARROWUPDOWN: "mdi-arrow-up-down",
  BADGE: "mdi-badge-account",
  BAG: "mdi-bag-personal",
  BARCODE: "mdi-barcode",
  BANK: "mdi-bank",
  BELL: "mdi-bell",
  BOOKOPEN: "mdi-book-open-outline",
  BOOKOUTLINE: "mdi-book-outline",
  BRIEFCASE: "mdi-briefcase",
  BRIEFCASE2: "mdi-briefcase-plus",
  BRIEFCASE3: "mdi-briefcase-edit",
  BRIEFCAS4: "mdi-briefcase-remove",
  CALCULATOR: "mdi-calculator",
  CALCULATORVARIANT: "mdi-calculator-variant",
  CALENDAR: "mdi-calendar",
  CALENDAR2: "mdi-calendar-month",
  CALENDAR3: "mdi-calendar-clock",
  CALENDAR4: "mdi-calendar-plus",
  CARD: "mdi-card-account-details",
  CARDOUTLINE: "mdi-card-account-details-outline",
  CARDSTAR: "mdi-card-account-details-star",
  CARDACCOUNTTEXT: "mdi-card-account-details",
  CARDBULLETED: "mdi-card-account-bulleted-outline",
  CARDBULLETED2: "mdi-card-bulleted-outline",
  CASH: "mdi-cash",
  CASH2: "mdi-cash-multiple",
  CASH3: "mdi-cash-register",
  CASH4: "mdi-cash-check",
  CATEGORY: "mdi-shape",
  CIRCLE: "mdi-circle",
  CIRCLEMEDIUM: "mdi-circle-medium",
  CHANGESTATUS: "mdi-restore",
  CHART: "mdi-chat-alert-outline",
  CHECK: "mdi-check",
  CHECK2: "mdi-check-circle",
  CHECK3: "mdi-check-circle-outline",
  CHECKBOX: "mdi-checkbox-blank-outline",
  CHEVRONDOWN: "mdi-chevron-down",
  CHEVRONUP: "mdi-chevron-up",
  CHEVRONLEFT: "mdi-chevron-left",
  CHEVRONRIGHT: "mdi-chevron-right",
  CHEVRONDOUBLEUP: "mdi-chevron-double-up",
  CHEVRONDOUBLEDOWN: "mdi-chevron-double-down",
  CLIPS: "mdi-attachment",
  CLIPBOARD: "mdi-clipboard-text-outline",
  CLIPBOARDPLUS: "mdi-clipboard-text-plus-outline",
  CLIPBOARDEDIT: "mdi-clipboard-text-edit-outline",
  CLIPBOARDREMOVE: "mdi-clipboard-text-remove-outline",
  CLOCK: "mdi-clock-outline",
  CLOSE: "mdi-close",
  CLOSE2: "mdi-close-box",
  CLOSE3: "mdi-close-circle",
  COG: "mdi-cog",
  COGS: "mdi-cogs",
  COMMENT: "mdi-comment",
  COMMENTPLUS: "mdi-comment-plus",
  COMMENTREMOVE: "mdi-comment-remove",
  COMMENTTEXT: "mdi-comment-text",
  CONTENTCOPY: "mdi-content-copy",
  CONTENTPASTE: "mdi-content-paste",
  COUNTER: "mdi-counter",
  CUBE: "mdi-cube",
  CURRENCY: "mdi-currency-usd",
  CURRENCYOFF: "mdi-currency-usd-off",
  CURRENCYCIRCLE: "mdi-currency-usd",
  DELETE: "mdi-delete",
  DESCRIPTION: "mdi-sort-variant",
  DESCRIPTION2: "mdi-note-text",
  DETAILS: "mdi-eye",
  DETAILS2: "mdi-dots-vertical",
  DETAILS3: "mdi-dots-vertical-circle-outline",
  DOMAIN: "mdi-domain",
  DOMAIN2: "mdi-at",
  DOWNLOAD: "mdi-download",
  DOWNLOAD2: "mdi-export",
  DOWNLOAD3: "mdi-tray-arrow-down",
  ECOM: "mdi-cart",
  EDIT: "mdi-pencil",
  EDITPLUS: "mdi-pencil-plus",
  EMAIL: "mdi-email",
  EQUIPMENTS: "mdi-widgets-outline",
  EXTRAITEM: "mdi-basket-plus",
  FACEBOOK: "mdi-facebook",
  FACTORY: "mdi-factory",
  FILE: "mdi-file",
  FILECOG: "mdi-file-cog",
  FILEDOCUMENT: "mdi-file-document",
  FILEDOCUMENTMULTIPLE: "mdi-file-document-multiple",
  FILEEDIT: "mdi-file-edit",
  FILEEDITOUTLINE: "mdi-file-edit-outline",
  FILEEYE: "mdi-file-eye",
  FILEPDF: "mdi-file-pdf-box",
  FILTER: "mdi-filter",
  FILTERREMOVE: "mdi-filter-remove",
  FINANCE: "mdi-finance",
  FLASH: "mdi-flash",
  FLASHEYE: "mdi-flash-red-eye",
  FORMATTYPE: "mdi-format-list-bulleted-type",
  FORMATNUMBERED: "mdi-format-list-numbered",
  FORMATTEXT: "mdi-format-list-text",
  FORMATTEXT2: "mdi-format-text",
  FORMATTITLE: "mdi-format-title",
  FORMATBULLETED: "mdi-format-list-bulleted",
  FORMATCHECKBOX: "mdi-format-list-checkbox",
  GENERAL: "mdi-wrench",
  GMAIL: "mdi-gmail",
  GRAPHIC: "mdi-chart-timeline-variant",
  GROUP: "mdi-group",
  HELP: "mdi-help",
  HELP2: "mdi-help-circle",
  HIDEPASSWORD: "mdi-eye-off",
  HISTORIC: "mdi-history",
  IDVISUAL: "mdi-palette",
  IMAGE: "mdi-image",
  IMAGEOUTLINE: "mdi-image-outline",
  IMAGEAREA: "mdi-image-area",
  IMPORT: "mdi-import",
  INFORMATION: "mdi-information-outline",
  INFORMATION2: "mdi-information",
  INFORMATION3: "mdi-script-text-play-outline",
  INSTAGRAM: "mdi-instagram",
  INTEGRATOR: "mdi-office-building",
  INVERTER: "mdi-amplifier",
  IRRADIATION: "mdi-weather-sunset",
  KEY: "mdi-key-variant",
  LAPTOP: "mdi-laptop",
  LIGHTNING: "mdi-lightning-bolt",
  LINK: "mdi-link",
  LINK2: "mdi-link-variant",
  LIST: "mdi-list-status",
  LOGOUT: "mdi-logout-variant",
  LOCK: "mdi-lock",
  LOCK2: "mdi-lock-pattern",
  MANUFACTURER: "mdi-hammer-wrench",
  MENU: "mdi-menu-down",
  MENUCLOSE: "mdi-menu-close",
  MENUOPEN: "mdi-menu-open",
  MENUUP: "mdi-menu-up",
  MENUSWAP: "mdi-menu-swap",
  MESSAGE: "mdi-message-plus",
  MINUSBOX: "mdi-minus-box",
  NEGOTIATIONS: "mdi-handshake",
  NUMERIC: "mdi-numeric",
  PAPERCLIP: "mdi-paperclip",
  PACKAGE: "mdi-package-variant-closed",
  PERCENT: "mdi-percent",
  PHONE: "mdi-phone",
  PLAYLISTPLUS: "mdi-playlist-plus",
  PLAYLISTREMOVE: "mdi-playlist-remove",
  POWER: "mdi-power-socket-eu",
  OBSERVATION: "mdi-asterisk",
  ORIENTATION: "mdi-debug-step-over",
  OTHERPRODUCT: "mdi-bottle-tonic",
  PRODUCTSIZE: "mdi-ruler",
  RAYSTART: "mdi-ray-start-arrow",
  RAYSEND: "mdi-ray-end-arrow",
  RECOMMENDATION: "mdi-rotate-right-variant",
  SEARCH: "mdi-magnify",
  SELECTALL: "mdi-select-all",
  SELECTGROUP: "mdi-select-group",
  SELECTPLACE: "mdi-select-place",
  SQUARE: "mdi-square",
  STAR: "mdi-star",
  STAROUTLINE: "mdi-star-outline",
  STAROFF: "mdi-star-off",
  STRUCTURE: "mdi-network-strength-outline",
  SUPORT: "mdi-face-agent",
  TABLE: "mdi-table",
  TABLEEDIT: "mdi-table-edit",
  TABLELARGE: "mdi-table-large-plus",
  TABLELOCK: "mdi-table-lock",
  TABLEPLUS: "mdi-table-plus",
  TABLEREFRESH: "mdi-table-refresh",
  TABLEREMOVE: "mdi-table-remove",
  TARGET: "mdi-target-account",
  TEXT: "mdi-text",
  TEXT2: "mdi-text-long",
  TEXT3: "mdi-text-box-plus",
  TEXT4: "mdi-text-box",
  TEXT5: "mdi-text-box-minus",
  TEXT6: "mdi-text-box-outline",
  TEXTBOXSEARCH: "mdi-text-box-search",
  TEXTBOXCHECK: "mdi-text-box-check",
  TIMER: "mdi-timer-sand",
  TIMER2: "mdi-timer-sand-full",
  TIMER3: "mdi-timer-outline",
  TIMER4: "mdi-timer-off-outline",
  TRASH: "mdi-trash-can",
  TRUCK: "mdi-truck",
  TRUCKDETAILS: "mdi-truck-delivery",
  TRUCKDETAILSOUTLINE: "mdi-truck-delivery-outline",
  VIEWGRID: "mdi-view-grid",
  VIEWMODULE: "mdi-view-module",
  VIEWDASHBOARD: "mdi-view-dashboard",
  UPDATE: "mdi-cached",
  WEIGHT: "mdi-weight",
  WHATSAPP: "mdi-whatsapp",
  DOLLY: "mdi-dolly",
  CREDITCARD: "mdi-credit-card-outline",
  CARDTEXT: "mdi-card-text-outline",
  API: "mdi-api",
  LISTBOXOUTLINE: "mdi-list-box-outline",
  SOLARPANELLARGE: "mdi-solar-panel-large",
  RECEIPTTEXTOUTLINE: "mdi-receipt-text-outline",
  ARCHIVEEYE: "mdi-archive-eye",
  MICROSOFT: "mdi-microsoft",
  WEB: "mdi-web",
  DRAGVERTICAL: "mdi-drag-vertical",
  MINUS: "mdi-minus",
  DOTSGRID: "mdi-dots-grid",
  DOTSHORIZONTAL: "mdi-dots-horizontal",
  CONTENTDUPLICATE: "mdi-content-duplicate",
  CONTENTSAVE: "mdi-content-save",
  CONTENTSAVEALL: "mdi-content-save-all",
  CURSORMOVE: "mdi-cursor-move",
  CHARTBOXOUTLINE: "mdi-chart-box-outline",
  PRINTER: "mdi-printer",
};
