export const MANAGER_STATUS = {
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  STARTED: "STARTED",
};

class RefreshManager {
  static status = MANAGER_STATUS.STARTED;
  static instance = null;
  constructor() {}

  setStatus = (status) => {
    RefreshManager.status = status;
  };

  getStatus = () => {
    return RefreshManager.status;
  };

  static getInstance() {
    if (!RefreshManager.instance) {
      RefreshManager.instance = new RefreshManager();
    }

    return RefreshManager.instance;
  }
  notPending(timeout = 10000) {
    if (RefreshManager.status !== MANAGER_STATUS.PENDING) return true;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (RefreshManager.status !== MANAGER_STATUS.PENDING) resolve(true);
        reject("pending");
      }, 50);
    }).catch((err) => {
      if (timeout > 0) {
        return this.notPending(timeout - 50);
      } else {
        return false;
      }
    });
  }
}

export default RefreshManager.getInstance();
