<template>
  <v-snackbar
    v-model="model"
    :timeout="timeout"
    top
    right
    app
    elevation="24"
    :color="theme.color"
    v-bind="theme.attrs"
    :max-width="$vuetify.smAndDown ? '100%' : '400px'"
    multi-line
  >
    <v-sheet class="d-flex align-center" color="transparent" elevation="0">
      <v-icon size="24" class="mr-2">{{ theme.icon }}</v-icon>
      <span style="flex: 1" v-html="description" />
    </v-sheet>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        v-for="(action, key) in actions"
        :key="key"
        text
        icon
        small
        @click.stop="handle(action)"
      >
        <v-icon small>{{ action.icon }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import socket from "@/plugins/socket";

export const ALERT_TYPE_ENUM = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

const TYPE_COLOR = {
  [ALERT_TYPE_ENUM.SUCCESS]: "success lighten-2",
  [ALERT_TYPE_ENUM.ERROR]: "error lighten-3",
  [ALERT_TYPE_ENUM.INFO]: "info lighten-1",
  [ALERT_TYPE_ENUM.WARNING]: "warning",
};

export default {
  data() {
    return {
      model: false,
      timeout: 5000,
      type: ALERT_TYPE_ENUM.INFO,
      description: null,
      actions: [],
      onClick: null,
      unsubscribeSocket: null,
      socket: null,
    };
  },
  computed: {
    theme() {
      return {
        [ALERT_TYPE_ENUM.INFO]: {
          color: TYPE_COLOR[ALERT_TYPE_ENUM.INFO],
          icon: "mdi-information-outline",
        },
        [ALERT_TYPE_ENUM.ERROR]: {
          color: TYPE_COLOR[ALERT_TYPE_ENUM.ERROR],
          icon: "mdi-alert-circle-outline",
        },
        [ALERT_TYPE_ENUM.SUCCESS]: {
          color: TYPE_COLOR[ALERT_TYPE_ENUM.SUCCESS],
          icon: "mdi-check-circle-outline",
        },
        [ALERT_TYPE_ENUM.WARNING]: {
          color: TYPE_COLOR[ALERT_TYPE_ENUM.WARNING],
          icon: "mdi-alert-outline",
        },
      }[this.type];
    },
  },
  watch: {
    model(value) {
      if (!value) this.onClose();
    },
  },
  methods: {
    open(description, type = ALERT_TYPE_ENUM.INFO, options = {}) {
      this.model = true;
      this.description = description;
      this.timeout = options?.duration || 3000;
      this.type = type;
      this.actions = options?.actions || [];
    },

    onClose() {
      this.model = false;
      this.timeout = 3000;
      this.type = ALERT_TYPE_ENUM.INFO;
      this.description = null;
      this.callback = null;
      this.actions = [];
    },

    onOpenDetail(data) {
      const { route, params } = data;
      return this.$redirect({ name: route, params });
    },

    handle(action) {
      const { args = {}, callable } = action;

      return (
        {
          onClose: this.onClose,
          onOpenDetail: this.onOpenDetail,
        }?.[callable](args) || null
      );
    },
  },
  created() {
    this.$root.$on("alert:open", this.open);

    socket.waitConnection(3000).then(() => {
      this.socket = socket.io;

      this.unsubscribeSocket = socket.subscribe(
        [
          (io) =>
            io?.on("alert", (data) => {
              const { description, type, ...options } = data;
              this.open(description, type, options);
            }),
        ],
        [(io) => io.off("alert")]
      );
    });
  },
  beforeDestroy() {
    if (this.unsubscribeSocket && typeof unsubscribeSocket === "function") {
      this.unsubscribeSocket();
    }
  },
};
</script>

<style lang="scss" scoped></style>
