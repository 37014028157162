import { state } from "@/store/modules/leadsIntegrator/state";
import { mutations } from "@/store/modules/leadsIntegrator/mutations";
import { actions } from "@/store/modules/leadsIntegrator/actions";
import { getters } from "@/store/modules/leadsIntegrator/getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
