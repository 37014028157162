<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    :style="{ color: svgColor }"
  >
    <g id="freepik--background-complete--inject-2">
      <rect
        x="416.78"
        y="398.49"
        width="33.12"
        height="0.25"
        style="fill: #ebebeb"
      />
      <rect
        x="322.53"
        y="401.21"
        width="8.69"
        height="0.25"
        style="fill: #ebebeb"
      />
      <rect
        x="396.59"
        y="389.21"
        width="19.19"
        height="0.25"
        style="fill: #ebebeb"
      />
      <rect
        x="52.46"
        y="390.89"
        width="43.19"
        height="0.25"
        style="fill: #ebebeb"
      />
      <rect
        x="104.56"
        y="390.89"
        width="6.33"
        height="0.25"
        style="fill: #ebebeb"
      />
      <rect
        x="131.47"
        y="395.11"
        width="93.68"
        height="0.25"
        style="fill: #ebebeb"
      />
      <path
        d="M238.4,337.8H45.3a5.71,5.71,0,0,1-5.71-5.71V60.66A5.71,5.71,0,0,1,45.3,55H238.4a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,238.4,337.8ZM45.3,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46H238.4a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
        style="fill: #ebebeb"
      />
      <path
        d="M454.7,337.8H261.6a5.71,5.71,0,0,1-5.71-5.71V60.66A5.71,5.71,0,0,1,261.6,55H454.7a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,454.7,337.8ZM261.6,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46H454.7a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
        style="fill: #ebebeb"
      />
      <rect y="382.4" width="500" height="0.25" style="fill: #ebebeb" />
      <path
        d="M396.3,330.54h25.25a8.21,8.21,0,0,0,8.22-8.21V285.42a1.5,1.5,0,0,0-1.5-1.5h0a1.5,1.5,0,0,0-1.5,1.5v36.91a5.22,5.22,0,0,1-5.22,5.21H396.3Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M389.08,365.93V331.55l-2-.64a5.45,5.45,0,0,1-3.82-5.2H402.3a5.45,5.45,0,0,1-3.82,5.2l-2,.64v34.38L412.3,373v3l-19.53-6.82L373.25,376v-3Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M367.87,327.54h49.81a5.41,5.41,0,0,0,5.41-5.4h0a5.41,5.41,0,0,0-5.41-5.41H367.87a5.41,5.41,0,0,0-5.41,5.41h0A5.41,5.41,0,0,0,367.87,327.54Z"
        style="fill: #f0f0f0"
      />
      <path
        d="M426.47,298.41,429.16,260a5.41,5.41,0,0,0-5-5.77h0a5.4,5.4,0,0,0-5.76,5l-2.69,38.44a5.4,5.4,0,0,0,5,5.77h0A5.41,5.41,0,0,0,426.47,298.41Z"
        style="fill: #ebebeb"
      />
      <path
        d="M408.89,378.15a3.42,3.42,0,1,0,3.41-3.42A3.42,3.42,0,0,0,408.89,378.15Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M369.83,378.15a3.42,3.42,0,1,0,3.42-3.42A3.42,3.42,0,0,0,369.83,378.15Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M100.26,330.54H75a8.21,8.21,0,0,1-8.22-8.21V285.42a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5v36.91A5.22,5.22,0,0,0,75,327.54h25.25Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M107.48,365.93V331.55l2-.64a5.45,5.45,0,0,0,3.82-5.2h-19a5.45,5.45,0,0,0,3.82,5.2l2,.64v34.38L84.26,373v3l19.53-6.82L123.32,376v-3Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M128.69,327.54H78.88a5.41,5.41,0,0,1-5.41-5.4h0a5.41,5.41,0,0,1,5.41-5.41h49.81a5.41,5.41,0,0,1,5.41,5.41h0A5.41,5.41,0,0,1,128.69,327.54Z"
        style="fill: #f0f0f0"
      />
      <path
        d="M70.09,298.41,67.4,260a5.41,5.41,0,0,1,5-5.77h0a5.4,5.4,0,0,1,5.76,5l2.69,38.44a5.4,5.4,0,0,1-5,5.77h0A5.41,5.41,0,0,1,70.09,298.41Z"
        style="fill: #ebebeb"
      />
      <path
        d="M87.67,378.15a3.42,3.42,0,1,1-3.41-3.42A3.42,3.42,0,0,1,87.67,378.15Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M126.73,378.15a3.42,3.42,0,1,1-3.41-3.42A3.42,3.42,0,0,1,126.73,378.15Z"
        style="fill: #e0e0e0"
      />
      <polygon
        points="207.57 382.69 188.06 382.69 186.06 345.71 209.57 345.71 207.57 382.69"
        style="fill: #ebebeb"
      />
      <polygon
        points="192.56 382.69 184.56 382.69 184.56 381.05 192.56 380.05 192.56 382.69"
        style="fill: #e0e0e0"
      />
      <path
        d="M189,342.71h17.56a3,3,0,0,1,3,3v0a0,0,0,0,1,0,0H186.06a0,0,0,0,1,0,0v0a3,3,0,0,1,3-3Z"
        style="fill: #e0e0e0"
      />
      <polygon
        points="167.56 286.79 136.35 286.79 136.35 283.05 167.56 281.05 167.56 286.79"
        style="fill: #e0e0e0"
      />
      <rect
        x="121.4"
        y="290.6"
        width="92.33"
        height="12.78"
        style="fill: #f0f0f0"
      />
      <rect
        x="102.59"
        y="286.79"
        width="130.28"
        height="7.62"
        style="fill: #fafafa"
      />
      <polygon
        points="220.77 382.69 226.65 382.69 216.47 294.41 209.57 294.41 220.77 382.69"
        style="fill: #e6e6e6"
      />
      <polygon
        points="125.89 294.41 118.99 294.41 108.8 382.69 114.69 382.69 125.89 294.41"
        style="fill: #e6e6e6"
      />
      <path
        d="M199.18,284.89q-4-19.87-8-39.74c-.39-1.9-3.28-1.09-2.9.79l7.62,37.85H179.4a1.5,1.5,0,0,0,0,3h18.33A1.53,1.53,0,0,0,199.18,284.89Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M180.5,274.23h0l5.14-73.49h0a13.65,13.65,0,0,1,5.67,12.06l-3.51,50.28A13.67,13.67,0,0,1,180.5,274.23Z"
        style="fill: #fafafa"
      />
      <rect
        x="209.29"
        y="265.43"
        width="14.16"
        height="21.36"
        rx="2.9"
        style="fill: #f5f5f5"
      />
      <path
        d="M214.89,257.47c-2.21,0-4,.55-4,1.23s1.8-.24,4-.24c.29,0,.57,0,.85,0v4.85H217v-4.66c1.14.22,1.89.44,1.89,0C218.89,258,217.1,257.47,214.89,257.47Z"
        style="fill: #e0e0e0"
      />
      <rect
        x="214.11"
        y="261.77"
        width="4.51"
        height="4.51"
        style="fill: #f0f0f0"
      />
      <rect
        x="282.83"
        y="290.6"
        width="92.33"
        height="12.78"
        transform="translate(657.99 593.98) rotate(180)"
        style="fill: #f0f0f0"
      />
      <rect
        x="263.69"
        y="286.79"
        width="130.28"
        height="7.62"
        transform="translate(657.67 581.2) rotate(180)"
        style="fill: #fafafa"
      />
      <polygon
        points="275.79 382.69 269.91 382.69 280.09 294.41 286.99 294.41 275.79 382.69"
        style="fill: #e6e6e6"
      />
      <polygon
        points="370.68 294.41 377.57 294.41 387.76 382.69 381.87 382.69 370.68 294.41"
        style="fill: #e6e6e6"
      />
      <polygon
        points="348.52 286.79 379.73 286.79 379.73 283.05 348.52 281.05 348.52 286.79"
        style="fill: #e0e0e0"
      />
      <path
        d="M285.73,219.71v67.08h4.33l1-6a4.54,4.54,0,0,1,4.47-3.76H335.9a4.54,4.54,0,0,1,4.47,3.76l1,6h4.33V219.71Z"
        style="fill: #f5f5f5"
      />
      <path
        d="M305.13,284.89q4-19.87,8-39.74c.39-1.9,3.28-1.09,2.9.79l-7.62,37.85h16.5a1.5,1.5,0,0,1,0,3H306.58A1.53,1.53,0,0,1,305.13,284.89Z"
        style="fill: #e0e0e0"
      />
      <path
        d="M323.81,274.23h0l-5.14-73.49h0A13.65,13.65,0,0,0,313,212.8l3.51,50.28A13.65,13.65,0,0,0,323.81,274.23Z"
        style="fill: #e0e0e0"
      />
      <rect
        x="53.83"
        y="147.94"
        width="163.83"
        height="99.83"
        style="fill: #ebebeb"
      />
      <rect
        x="217.68"
        y="147.9"
        width="1.2"
        height="99.83"
        style="fill: #e0e0e0"
      />
      <rect
        x="58.79"
        y="152.27"
        width="0.99"
        height="89.83"
        style="fill: #e0e0e0"
      />
      <rect
        x="58.69"
        y="152.27"
        width="153.63"
        height="89.83"
        style="fill: #fff"
      />
      <rect
        x="115.96"
        y="188.99"
        width="4.28"
        height="9.98"
        transform="translate(-75.88 312.08) rotate(-90)"
        style="fill: #f5f5f5"
      />
      <rect
        x="118.38"
        y="220.72"
        width="3.33"
        height="9.98"
        transform="translate(-105.66 345.76) rotate(-90)"
        style="fill: #f5f5f5"
      />
      <rect
        x="84.26"
        y="190.44"
        width="32.42"
        height="45.56"
        style="fill: #f0f0f0"
      />
      <rect
        x="116.15"
        y="228.09"
        width="3.33"
        height="9.98"
        transform="translate(-115.27 350.89) rotate(-90)"
        style="fill: #f5f5f5"
      />
      <rect
        x="117.81"
        y="197.63"
        width="3.33"
        height="9.98"
        transform="translate(-83.15 322.09) rotate(-90)"
        style="fill: #f5f5f5"
      />
      <rect
        x="115.62"
        y="205.9"
        width="3.33"
        height="9.98"
        transform="translate(-93.61 328.17) rotate(-90)"
        style="fill: #f5f5f5"
      />
      <rect
        x="88.08"
        y="196.04"
        width="22.1"
        height="3.92"
        style="fill: #e6e6e6"
      />
      <polygon
        points="110.53 212.79 88.43 212.79 88.08 211.01 110.17 211.01 110.53 212.79"
        style="fill: #e6e6e6"
      />
      <rect
        x="88.08"
        y="203.88"
        width="7.84"
        height="3.92"
        style="fill: #e6e6e6"
      />
      <rect
        x="150.03"
        y="182.21"
        width="12.66"
        height="18.18"
        style="fill: #f5f5f5"
      />
      <rect
        x="66.09"
        y="211.02"
        width="12.66"
        height="18.18"
        style="fill: #f5f5f5"
      />
      <rect
        x="67.81"
        y="158.78"
        width="12.66"
        height="12.66"
        style="fill: #f5f5f5"
      />
      <rect
        x="127.83"
        y="216.82"
        width="12.66"
        height="12.66"
        style="fill: #f5f5f5"
      />
      <rect
        x="170.25"
        y="183.78"
        width="32.42"
        height="45.56"
        style="fill: #f5f5f5"
      />
      <rect
        x="174.69"
        y="190.37"
        width="23.29"
        height="0.5"
        style="fill: #e0e0e0"
      />
      <rect
        x="186.08"
        y="190.86"
        width="0.5"
        height="33.74"
        style="fill: #e0e0e0"
      />
      <rect
        x="196.78"
        y="190.86"
        width="0.5"
        height="33.74"
        style="fill: #e0e0e0"
      />
      <rect
        x="176.58"
        y="190.86"
        width="0.5"
        height="33.74"
        style="fill: #e0e0e0"
      />
      <rect
        x="174.91"
        y="190.86"
        width="0.5"
        height="33.74"
        style="fill: #e0e0e0"
      />
      <rect
        x="175.16"
        y="192.64"
        width="21.62"
        height="0.5"
        style="fill: #e0e0e0"
      />
    </g>
    <g id="freepik--Shadow--inject-2">
      <ellipse
        id="freepik--path--inject-2"
        cx="245.18"
        cy="412.39"
        rx="193.89"
        ry="11.32"
        style="fill: #f5f5f5"
      />
    </g>
    <g id="freepik--Puzzle--inject-2">
      <path
        d="M275.2,205.07A22.23,22.23,0,0,1,253,227.3h-8.08v21.18H223v8.69a22.23,22.23,0,0,1-44.45,0v-8.69H158.08V227.3h-9.3a22.23,22.23,0,1,1,0-44.46h9.3V161.66h21.18V171a22.23,22.23,0,1,0,44.45,0v-9.3h21.18v21.18H253A22.23,22.23,0,0,1,275.2,205.07Z"
        style="fill: currentColor"
      />
      <g style="opacity: 0.5">
        <path
          d="M275.2,205.07A22.23,22.23,0,0,1,253,227.3h-8.08v21.18H223v8.69a22.23,22.23,0,0,1-44.45,0v-8.69H158.08V227.3h-9.3a22.23,22.23,0,1,1,0-44.46h9.3V161.66h21.18V171a22.23,22.23,0,1,0,44.45,0v-9.3h21.18v21.18H253A22.23,22.23,0,0,1,275.2,205.07Z"
          style="fill: #fff"
        />
      </g>
      <path
        d="M253.08,227.19h-9.3v21.18H330.6V227.19h8.07a22.23,22.23,0,0,0,0-44.46H330.6V161.55H309.42v-9.3a22.23,22.23,0,0,0-44.46,0v9.3H243.78v21.18h9.3a22.23,22.23,0,0,1,0,44.46Z"
        style="fill: currentColor"
      />
      <g style="opacity: 0.30000000000000004">
        <path
          d="M253.08,227.19h-9.3v21.18H330.6V227.19h8.07a22.23,22.23,0,0,0,0-44.46H330.6V161.55H309.42v-9.3a22.23,22.23,0,0,0-44.46,0v9.3H243.78v21.18h9.3a22.23,22.23,0,0,1,0,44.46Z"
          style="fill: #fff"
        />
      </g>
    </g>
    <g id="freepik--speech-bubbles--inject-2">
      <path
        d="M258.38,151.73l-1.87-.47a28.1,28.1,0,0,1-5.85-1.89l-.25-.12c-1.84-.89-4.14-2-4.63-5.87a1.05,1.05,0,0,0-.89-1c-17.14-.83-23.11-9.9-23.12-20,0-9.9,6.51-19.25,25-19.47,17.14-.21,25.72,10.34,25.2,21.21-.34,6.94-5.78,16.45-16.13,18.61-1.27.43-3.53.51-4.32,1.65a1.72,1.72,0,0,0,0,1.85,6.77,6.77,0,0,0,1.35,1.51C254.71,149.32,258.38,151.73,258.38,151.73Z"
        style="fill: #fff"
      />
      <path
        d="M258.38,152.23l-.12,0-1.87-.46a28.87,28.87,0,0,1-5.95-1.93l-.24-.12c-1.85-.89-4.39-2.11-4.91-6.26,0-.09-.09-.58-.43-.6-20.51-1-23.58-13.25-23.59-20.46,0-5.91,2.47-19.69,25.5-20,10.87-.11,17,3.88,20.28,7.28a19.3,19.3,0,0,1,5.43,14.46A21.2,21.2,0,0,1,256,143.22a9.91,9.91,0,0,1-1.28.32c-1.07.22-2.28.47-2.73,1.13a1.2,1.2,0,0,0,0,1.29,6.37,6.37,0,0,0,1.25,1.4c1.79,1.54,5.42,3.92,5.46,3.95a.49.49,0,0,1,.18.61A.5.5,0,0,1,258.38,152.23ZM247.29,103.4h-.51c-22.14.27-24.52,13.35-24.51,19,0,6.86,2.95,18.51,22.64,19.46a1.53,1.53,0,0,1,1.37,1.48c.46,3.6,2.52,4.6,4.35,5.48l.25.12a24.33,24.33,0,0,0,4.92,1.65c-1.11-.77-2.38-1.7-3.25-2.45a7.15,7.15,0,0,1-1.44-1.63,2.16,2.16,0,0,1,0-2.39c.67-1,2.09-1.28,3.35-1.54.45-.1.89-.19,1.22-.3a20.19,20.19,0,0,0,15.79-18.16,18.29,18.29,0,0,0-5.15-13.72C263.24,107.18,257.44,103.4,247.29,103.4Z"
        style="fill: currentColor"
      />
      <path
        d="M238,147.53c1.19-1.35,1.75-2.7,1.45-3.94-.62-2.61-2.93-2.57-4.42-3.07-10.35-2.17-15.79-9.67-16.13-16.61-.52-10.87,8.06-21.43,25.2-21.22,18.5.23,25,9.58,25,19.47,0,10.07-6,19.14-23.12,20-.57,0-.83.55-.9,1-.48,3.87-2.78,5-4.62,5.87l-.25.12a28.7,28.7,0,0,1-5.85,1.89l-1.87.46S236.37,149.35,238,147.53Z"
        style="fill: #fff"
      />
      <path
        d="M232.46,152a.5.5,0,0,1-.47-.31.5.5,0,0,1,.22-.62s3.86-2.15,5.38-3.88c1.13-1.3,1.58-2.47,1.34-3.49-.43-1.81-1.74-2.12-3.12-2.45-.34-.08-.68-.16-1-.26-10.9-2.28-16.14-10.22-16.47-17.06a19.33,19.33,0,0,1,5.43-14.47c3.28-3.39,9.41-7.42,20.28-7.28,23,.29,25.51,14.06,25.5,20,0,7.21-3.08,19.47-23.59,20.46-.34,0-.42.5-.43.6-.52,4.14-3.06,5.37-4.91,6.26l-.24.12a29.49,29.49,0,0,1-6,1.93l-1.88.46Zm11.09-48.82c-10.16,0-16,3.78-19,7a18.29,18.29,0,0,0-5.15,13.72c.31,6.46,5.3,14,15.73,16.14.33.11.64.18,1,.26,1.38.33,3.28.78,3.85,3.18.32,1.36-.2,2.83-1.56,4.39h0a16.45,16.45,0,0,1-2.92,2.41,23.89,23.89,0,0,0,4.54-1.56l.25-.12c1.82-.88,3.89-1.88,4.35-5.48a1.51,1.51,0,0,1,1.37-1.48c19.68-.95,22.63-12.6,22.64-19.47,0-5.61-2.37-18.69-24.52-19ZM238,147.53h0Z"
        style="fill: currentColor"
      />
      <polyline
        points="238.39 123.15 242.75 127.51 252.6 119.53"
        style="fill: #fff"
      />
      <path
        d="M242.75,128.51a1,1,0,0,1-.71-.29l-4.36-4.36a1,1,0,1,1,1.41-1.41l3.73,3.72,9.15-7.41a1,1,0,1,1,1.26,1.55l-9.85,8A1,1,0,0,1,242.75,128.51Z"
        style="fill: currentColor"
      />
    </g>
    <g id="freepik--light-bulb--inject-2">
      <path
        d="M370.12,99.43a1.27,1.27,0,0,0,1.46-.45l7.58-10.58a1.24,1.24,0,0,0-.29-1.75,1.26,1.26,0,0,0-1.75.29l-7.58,10.58a1.24,1.24,0,0,0,.29,1.75A1.18,1.18,0,0,0,370.12,99.43Z"
        style="fill: currentColor"
      />
      <path
        d="M379.08,113.36a1.26,1.26,0,0,0,.64.06l12.57-2.08a1.26,1.26,0,0,0-.41-2.48l-12.57,2.08a1.26,1.26,0,0,0-.23,2.42Z"
        style="fill: currentColor"
      />
      <path
        d="M340.46,104.61a1.26,1.26,0,0,0,1.17-2.2l-10.9-7.81a1.25,1.25,0,0,0-1.46,2l10.9,7.81A1.18,1.18,0,0,0,340.46,104.61Z"
        style="fill: currentColor"
      />
      <path
        d="M385.64,137a1.26,1.26,0,0,0,1.18-2.2l-10.25-7.35a1.26,1.26,0,1,0-1.47,2.05l10.25,7.34A1.4,1.4,0,0,0,385.64,137Z"
        style="fill: currentColor"
      />
      <path
        d="M354.15,96a1.26,1.26,0,0,0,1.68-1.38l-2.17-13.11a1.26,1.26,0,1,0-2.48.41L353.35,95A1.25,1.25,0,0,0,354.15,96Z"
        style="fill: currentColor"
      />
      <g style="opacity: 0.5">
        <path
          d="M370.12,99.43a1.27,1.27,0,0,0,1.46-.45l7.58-10.58a1.24,1.24,0,0,0-.29-1.75,1.26,1.26,0,0,0-1.75.29l-7.58,10.58a1.24,1.24,0,0,0,.29,1.75A1.18,1.18,0,0,0,370.12,99.43Z"
          style="fill: #fff"
        />
        <path
          d="M379.08,113.36a1.26,1.26,0,0,0,.64.06l12.57-2.08a1.26,1.26,0,0,0-.41-2.48l-12.57,2.08a1.26,1.26,0,0,0-.23,2.42Z"
          style="fill: #fff"
        />
        <path
          d="M340.46,104.61a1.26,1.26,0,0,0,1.17-2.2l-10.9-7.81a1.25,1.25,0,0,0-1.46,2l10.9,7.81A1.18,1.18,0,0,0,340.46,104.61Z"
          style="fill: #fff"
        />
        <path
          d="M385.64,137a1.26,1.26,0,0,0,1.18-2.2l-10.25-7.35a1.26,1.26,0,1,0-1.47,2.05l10.25,7.34A1.4,1.4,0,0,0,385.64,137Z"
          style="fill: #fff"
        />
        <path
          d="M354.15,96a1.26,1.26,0,0,0,1.68-1.38l-2.17-13.11a1.26,1.26,0,1,0-2.48.41L353.35,95A1.25,1.25,0,0,0,354.15,96Z"
          style="fill: #fff"
        />
      </g>
      <path
        d="M343.23,108.19a17,17,0,0,1,27.5,19.91c-4,5.51-11.07,6.06-17,8.42-1.84.73-5.4,5.85-5.4,5.85l-11.66-8.45s3.75-5,3.87-6.95C340.93,120.6,339.24,113.7,343.23,108.19Z"
        style="fill: currentColor"
      />
      <path
        d="M343.23,108.19a17,17,0,0,1,27.5,19.91c-4,5.51-11.07,6.06-17,8.42-1.84.73-5.4,5.85-5.4,5.85l-11.66-8.45s3.75-5,3.87-6.95C340.93,120.6,339.24,113.7,343.23,108.19Z"
        style="fill: #fff; opacity: 0.4"
      />
      <path
        d="M337,140.47l5,3.6a4.13,4.13,0,0,0,5.77-.92h0l-11.67-8.44h0A4.14,4.14,0,0,0,337,140.47Z"
        style="fill: #263238"
      />
      <path
        d="M337,145.78h0a3.37,3.37,0,0,0,4.7-.75h0l-5.45-3.95h0A3.37,3.37,0,0,0,337,145.78Z"
        style="fill: #263238"
      />
      <path
        d="M337,145.78h0a3.37,3.37,0,0,0,4.7-.75h0l-5.45-3.95h0A3.37,3.37,0,0,0,337,145.78Z"
        style="fill: #fff; opacity: 0.5"
      />
      <g style="opacity: 0.7000000000000001">
        <path
          d="M337,140.47l5,3.6a4.13,4.13,0,0,0,5.77-.92h0l-11.67-8.44h0A4.14,4.14,0,0,0,337,140.47Z"
          style="fill: #fff"
        />
      </g>
      <path
        d="M346.7,106.81c-6.23,8.69-7.84,22.43-1.13,27.23s20-2.3,25.42-9.82c3.32-4.63.58-12.29-6.13-17.09S350,102.18,346.7,106.81Z"
        style="fill: #fff; opacity: 0.30000000000000004"
      />
      <path
        d="M344.91,118.33a9.63,9.63,0,1,0,13.43-2.21A9.63,9.63,0,0,0,344.91,118.33Z"
        style="fill: #fff; opacity: 0.4"
      />
      <path
        d="M345.89,122c-2.18,3-.38,5.72,2.66,7.9s6.15,3,8.33,0,.34-5.68-2.69-7.86S348.06,119,345.89,122Z"
        style="fill: #fff; opacity: 0.4"
      />
    </g>
    <g id="freepik--Arrow--inject-2">
      <polygon
        points="106.66 143.25 110.07 146.09 129.79 129.24 154.97 134.33 174.16 116.23 175.88 118.59 180.43 108.33 169.27 109.52 170.99 111.88 154.15 130.53 128.94 125.63 106.66 143.25"
        style="fill: currentColor"
      />
      <g style="opacity: 0.30000000000000004">
        <polygon
          points="106.66 143.25 110.07 146.09 129.79 129.24 154.97 134.33 174.16 116.23 175.88 118.59 180.43 108.33 169.27 109.52 170.99 111.88 154.15 130.53 128.94 125.63 106.66 143.25"
          style="fill: #fff"
        />
      </g>
    </g>
    <g id="freepik--character-2--inject-2">
      <path
        d="M293.1,290.72c.58,9.06-1.66,20.72-3.8,29.41-1.77,7.18-3,9.16-3.47,12.34-.51,3.62.59,9,.31,13.55-1.07,18-8.39,54.4-8.39,54.4l-6.14-.39.16-70.47,4-68.28L300,267.74C302.42,281,293.1,290.72,293.1,290.72Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M324.28,401.67l-5.58-.16-15.49-68.8-15.66-66.57,25.11-.75c6,12-.09,24-.09,24,3.13,8.53,4.32,20.34,4.75,29.29.36,7.38-.29,9.64.19,12.81.56,3.62,2.32,8.43,3.37,12.9C325,362.13,324.28,401.67,324.28,401.67Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M242.5,204.59l2.92,3.84c2.91.9,2.85,5.23,2.85,5.23.63.49,6.55,4.23,7.93,5.44l-3.49,3.76c-1.51-2.31-6.76-6.22-6.76-6.22s-7.14-4.37-8.31-5.93.78-6.2.78-6.2Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M250.58,214.05c4.79,3.49,11.86,8.84,11.86,8.84A167.47,167.47,0,0,1,274.34,211a33.68,33.68,0,0,1,8.75-6l-1.23,14.39c-3.79,3.26-15.67,13.12-20.31,12.72-3.83-.33-10.94-8.54-15-13.62Z"
        style="fill: #263238"
      />
      <path
        d="M270.15,228.31c2.53-4.27,9.47-15.48,9.47-15.48l1.66,3.77-1.12,4.14C278.4,222.22,272.69,226.46,270.15,228.31Z"
        style="opacity: 0.2"
      />
      <path
        d="M315.71,212.28a298.58,298.58,0,0,1-5.66,39.2s14.65,12,9.9,61.29c0,0-14.82,6.56-49.8-1.24l6.65-60.27s-1.54-39.17,2.62-43.54c3.68-3.88,19.73-9,32-2.92A7.73,7.73,0,0,1,315.71,212.28Z"
        style="fill: currentColor"
      />
      <path
        d="M315.71,212.28a298.58,298.58,0,0,1-5.66,39.2s14.65,12,9.9,61.29c0,0-14.82,6.56-49.8-1.24l6.65-60.27s-1.54-39.17,2.62-43.54c3.68-3.88,19.73-9,32-2.92A7.73,7.73,0,0,1,315.71,212.28Z"
        style="opacity: 0.4"
      />
      <path
        d="M298.68,250.16s-10.73,1.86-18.27,0v4.6s5.61.81,18.27,0Z"
        style="opacity: 0.30000000000000004"
      />
      <path
        d="M304.92,188c-1.42,4-3.27,11.15-1.75,15.26a2.24,2.24,0,0,1-.87,2.67c-3.09,2.23-8.12,4-11.9,3.63-4.85-.53-3.59-4-2.35-5.85.33-.5.85-1.21,1.44-1.32,4.77-.81,5.68-4.39,5.31-8Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M300.8,190.61l-6,3.75a14.77,14.77,0,0,1,0,2.66,7.38,7.38,0,0,0,6-3.41C301.5,192.34,301.29,190.84,300.8,190.61Z"
        style="opacity: 0.30000000000000004; isolation: isolate"
      />
      <path
        d="M289.17,190.05l-2.71,4.87c.84.07,1.6.35,2.44.42.42-.66.92-1.31,1.34-2-.16.68-.71,1.41-.88,2.08a26.13,26.13,0,0,0,4.52.43,1.73,1.73,0,0,0-.11-1.37,8.39,8.39,0,0,0-4-4.14Z"
        style="fill: #263238"
      />
      <path
        d="M306.81,181.43c-1.75,6.23-2.46,10-6.35,12.52-5.85,3.84-13-.75-13.17-7.37-.13-6,2.76-15.16,9.48-16.33A8.83,8.83,0,0,1,307,177.36,9,9,0,0,1,306.81,181.43Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M292.2,181.43a15.43,15.43,0,0,1-2.7,3.17,2.5,2.5,0,0,0,1.92.77Z"
        style="fill: #ff5652"
      />
      <path
        d="M299.05,179.14a.38.38,0,0,0,.15,0,.32.32,0,0,0,.15-.41,3.09,3.09,0,0,0-2.19-1.78.31.31,0,0,0-.35.26.31.31,0,0,0,.26.35h0a2.48,2.48,0,0,1,1.72,1.43A.3.3,0,0,0,299.05,179.14Z"
        style="fill: #263238"
      />
      <path
        d="M289.22,176.2a.29.29,0,0,0,.3,0,2.43,2.43,0,0,1,2.21-.17.31.31,0,0,0,.41-.14.3.3,0,0,0-.13-.41,3.09,3.09,0,0,0-2.82.18.3.3,0,0,0-.12.41h0A.37.37,0,0,0,289.22,176.2Z"
        style="fill: #263238"
      />
      <path
        d="M302.94,201.22l-7,5.42a2.45,2.45,0,0,1-3.77-1L290.58,202l-1.86.48a1.17,1.17,0,0,0-.87.88c-.37,1.71-1.73,5.89-1.65,9.64,0,0,4.29-5.74,6.71-1.61,0,0,3.16-3.73,5.71,2.72a54.23,54.23,0,0,0,4.32-7.39c1.31-3.06,2.19-4.72,2.19-4.72Z"
        style="fill: #fff"
      />
      <path
        d="M303.78,201.53a277.49,277.49,0,0,0-6.25,40.24c-1.41,20.28-1.7,26.41,6.32,25.53s14.59-1.89,13.42-4.81S311.73,253,312,249.65s3.06-29.46,3.38-33.41S318.08,204,303.78,201.53Z"
        style="fill: #263238"
      />
      <path
        d="M305.15,256.76c-.1-.54,1.82-2.49,4-3.85a23.5,23.5,0,0,1,4.6-2.11s4.48-5.27,5.72-6.7l3.46,3a89.71,89.71,0,0,0-6.43,6.56l-1.86,3.93a6.88,6.88,0,0,1-2.61,2.22,20.44,20.44,0,0,1-3.65,1.29,1.17,1.17,0,0,1-1.29-.51A14.35,14.35,0,0,1,305.15,256.76Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M289.71,202.21s-6.57,12.36-7,33,.47,31.58-3.35,32.21-4.67-2-4.67-2,1.13-11.2,1.13-13.93-1.2-19.26,0-31.07,0-11.09,2-12.41S284.35,203.3,289.71,202.21Z"
        style="fill: #263238"
      />
      <path
        d="M313.15,238.49l-3.94-22.61s3.25,3.31,5.66,5.85C314.41,226.35,313.73,232.87,313.15,238.49Z"
        style="opacity: 0.2"
      />
      <path
        d="M311.36,204.12s-2.67,6.81-2.15,11.76c.22,2.1,15.14,18.1,15.14,18.1L313.1,248.9l5.8,5s16-15.84,16.13-20.13S317.37,205.93,311.36,204.12Z"
        style="fill: #263238"
      />
      <path
        d="M296.78,180.69c-.1.52-.46.88-.8.82s-.54-.54-.43-1.07.46-.88.8-.82S296.88,180.16,296.78,180.69Z"
        style="fill: #263238"
      />
      <path
        d="M290.89,179.53c-.1.52-.46.89-.8.82s-.53-.54-.43-1.06.46-.89.8-.82S291,179,290.89,179.53Z"
        style="fill: #263238"
      />
      <path
        d="M290.6,178.52l-1.18-.6S289.88,179,290.6,178.52Z"
        style="fill: #263238"
      />
      <path
        d="M296.49,179.68l-1.18-.6S295.77,180.18,296.49,179.68Z"
        style="fill: #263238"
      />
      <path
        d="M293.13,188.27a6.29,6.29,0,0,1-1,0,.16.16,0,0,1-.16-.18h0a.17.17,0,0,1,.17-.16A4.64,4.64,0,0,0,296,186.4a.16.16,0,0,1,.24,0,.17.17,0,0,1,0,.24A4.79,4.79,0,0,1,293.13,188.27Z"
        style="fill: #263238"
      />
      <path
        d="M303.09,169.25a36.11,36.11,0,0,1-1.22,26.87c.87-.26,1.8-.46,2.66-.71.42-1.11.75-2.25,1.17-3.35-.14,1-.41,2.24-.55,3.29l2.35-.43c.41-1.07.75-2.27,1.16-3.34-.18.95-.38,2.28-.57,3.23a13.11,13.11,0,0,0,5.39-7.89,16.18,16.18,0,0,0-1.32-10.53C310.22,173,306.83,171.61,303.09,169.25Z"
        style="fill: #263238"
      />
      <path
        d="M309,186.53a6.21,6.21,0,0,1-3,3.64c-1.91,1-3.32-.58-3.17-2.62.12-1.83,1.27-4.57,3.37-4.75A2.76,2.76,0,0,1,309,186.53Z"
        style="fill: #dd8c6a"
      />
      <path
        d="M289.75,217.84a2,2,0,0,1-.88-.21,2.23,2.23,0,0,1-1.2-1.61,3,3,0,0,1,1.52-2.92,8,8,0,0,1,2.92-1h.12l.81,0a.51.51,0,0,1,.48.51.5.5,0,0,1-.49.49c-.49,0-1.08,1-1.2,1.81l0,.12a3.19,3.19,0,0,1-1.27,2.55A1.7,1.7,0,0,1,289.75,217.84Zm1.67-4.54a5.06,5.06,0,0,0-1.69.64,2.14,2.14,0,0,0-1.08,1.93,1.23,1.23,0,0,0,.67.86.78.78,0,0,0,.74,0c.52-.27.64-1.06.76-1.82l0-.12A4.16,4.16,0,0,1,291.42,213.3Z"
        style="fill: #fff"
      />
      <path
        d="M295.86,218.17a1.69,1.69,0,0,1-.95-.29,3.13,3.13,0,0,1-1-2.67v-.12c0-.79-.52-1.86-1-1.93a.49.49,0,0,1-.43-.54.49.49,0,0,1,.52-.45l.81,0,.12,0a8.16,8.16,0,0,1,2.81,1.28,3,3,0,0,1,1.19,3.06,2.18,2.18,0,0,1-1.37,1.47A1.93,1.93,0,0,1,295.86,218.17Zm-1.36-4.71a4.26,4.26,0,0,1,.41,1.58v.13c0,.76.08,1.55.56,1.89a.82.82,0,0,0,.74.05,1.24,1.24,0,0,0,.76-.79,2.11,2.11,0,0,0-.86-2A5.46,5.46,0,0,0,294.5,213.46Z"
        style="fill: #fff"
      />
      <path
        d="M290,223.24a.41.41,0,0,1-.16,0,.5.5,0,0,1-.32-.63,46.73,46.73,0,0,0,2-9.51.5.5,0,0,1,1,.1,48.27,48.27,0,0,1-2,9.72A.5.5,0,0,1,290,223.24Z"
        style="fill: #fff"
      />
      <path
        d="M294,225.73a.5.5,0,0,1-.46-.31,9.72,9.72,0,0,1-.65-3.14,19.47,19.47,0,0,1,.27-3.26c.08-.54.16-1.08.21-1.62a6.57,6.57,0,0,0-.77-4.35.5.5,0,0,1,.83-.56,7.48,7.48,0,0,1,.93,5c-.05.56-.13,1.12-.21,1.67a18.21,18.21,0,0,0-.26,3.08,8.7,8.7,0,0,0,.58,2.81.51.51,0,0,1-.28.65A.58.58,0,0,1,294,225.73Z"
        style="fill: #fff"
      />
      <path
        d="M302.61,180.72c-.54-.49-1.3-1.1-1.84-1.6a10.94,10.94,0,0,0,.26-3.32c-.25.88-.33,1.78-.58,2.66l-1.84-1.22a2.77,2.77,0,0,0-1.54-.62c.1-.91.14-1.84.25-2.75-.21.81-.45,1.71-.65,2.52-.72-.1-1.55-.27-2.26-.37.08-1,.2-1.92.29-2.89-.31.76-.53,2.06-.84,2.82l-.21-.29a17.11,17.11,0,0,0-6.26-.35c-.7-2.75.14-6.64,2.84-7.53,9.15-3.05,16.64,4.09,16.85,4.68C308.45,176.23,305.92,178.73,302.61,180.72Z"
        style="fill: #263238"
      />
      <path
        d="M306,188.4a.87.87,0,1,1-.87-.87A.87.87,0,0,1,306,188.4Z"
        style="fill: currentColor"
      />
      <path
        d="M269.52,398.78c-4.83,2.65-5.37,2.7-10.7,3.71-1.17.22-.76,3,2,3.06,4.51.14,4.13,0,8,.08,3.28.08,7.23.36,9.84.47h.09a1.08,1.08,0,0,0,1-1.15l-.57-5.94a.54.54,0,0,0-.51-.47l-7.44-.21A3.31,3.31,0,0,0,269.52,398.78Z"
        style="fill: #263238"
      />
      <path
        d="M269.55,399.23a.16.16,0,0,0,0-.16.15.15,0,0,0-.17,0c-.32.11-3.1,1.21-3.31,2.1a.53.53,0,0,0,.13.48h0a.92.92,0,0,0,.77.29c1-.11,2.05-1.7,2.6-2.64Zm-3.19,1.91c.2-.49,1.59-1.19,2.66-1.65-.79,1.26-1.58,2-2.12,2.09a.54.54,0,0,1-.5-.2.17.17,0,0,1-.06-.18Z"
        style="fill: currentColor"
      />
      <path
        d="M269.55,399.23l0,0a.14.14,0,0,0-.06-.15,4.47,4.47,0,0,0-2.59-.95,1.15,1.15,0,0,0-.79.47h0c-.25.35-.18.59-.06.73.49.61,2.61.37,3.41,0A.14.14,0,0,0,269.55,399.23Zm-3.26-.33.11-.12h0a.83.83,0,0,1,.57-.35,3.75,3.75,0,0,1,2.12.72c-.92.28-2.48.33-2.77,0,0,0-.07-.08,0-.2Zm0-.24"
        style="fill: currentColor"
      />
      <path
        d="M316.11,399.25a23.23,23.23,0,0,1-9.36,4.63c-1.42.4-.53,3,2.87,3,5.63-.09,3.39-.18,8.2-.29,4.11-.08,5.2-.11,8.46-.13h.11a1.23,1.23,0,0,0,1.2-1.3l-1.09-6.38a.63.63,0,0,0-.66-.48l-8.59.23C316.5,398.44,316.69,398.84,316.11,399.25Z"
        style="fill: #263238"
      />
      <path
        d="M316.07,399.75a.15.15,0,0,0,0-.18.19.19,0,0,0-.16-.12c-.43,0-4.16.12-4.75,1a.49.49,0,0,0,0,.53h0a1.13,1.13,0,0,0,.79.57c1.18.24,3.1-1,4.16-1.77Zm-4.56.8c.43-.44,2.36-.66,3.81-.73-1.46,1-2.71,1.49-3.36,1.38a.7.7,0,0,1-.51-.38.18.18,0,0,1,0-.21Z"
        style="fill: currentColor"
      />
      <path
        d="M316.07,399.75l0,0a.15.15,0,0,0,0-.17c-.07-.05-1.32-1.68-2.66-1.89a1.6,1.6,0,0,0-1.12.2h0c-.45.27-.46.54-.38.72.32.78,2.92,1.31,4,1.24A.21.21,0,0,0,316.07,399.75Zm-3.71-1.51.13-.13h0a1.17,1.17,0,0,1,.82-.14,4.54,4.54,0,0,1,2.2,1.48c-1.21-.05-3.06-.54-3.25-1,0,0,0-.11.13-.22Zm.05-.25"
        style="fill: currentColor"
      />
    </g>
    <g id="freepik--character-1--inject-2">
      <g id="freepik--character-2--inject-110--inject-2">
        <path
          d="M228.08,241.29c-1.41,2.78,3.28,6.19,3.28,6.19l2.22,2.27a2.18,2.18,0,0,0,2.9.28l2.19-1.56.07-.05a2.18,2.18,0,0,0,.45-3l-1.47-2a7.09,7.09,0,0,0-2.24-2L231.27,239Z"
          style="fill: #ffc3bd"
        />
        <path
          d="M209.79,180.38a6.56,6.56,0,0,1,12.07.28l.14.35a18.49,18.49,0,0,1,1,7.63c-.06,2.55-.14,5.14-.1,7.61s0,11.39.08,13.81c.08,2.24,4.61,12.37,9,27a5.85,5.85,0,0,0,.33.82l.81,1.61s-1.21.77-2.45,1.51a14.22,14.22,0,0,1-3.27,1.84,7.24,7.24,0,0,1-1.59-2.08c-3.5-5.52-14.93-26-15.53-29-.59-2.78-1-12-1.26-14.8s-.46-5.53-.54-8.29a25.06,25.06,0,0,1,.16-4.15,14.64,14.64,0,0,1,1.15-4.15Z"
          style="fill: #263238"
        />
        <path
          d="M209.79,180.38a6.56,6.56,0,0,1,12.07.28l.14.35a18.49,18.49,0,0,1,1,7.63c-.06,2.55-.14,5.14-.1,7.61s0,11.39.08,13.81c.08,2.24,4.61,12.37,9,27a5.85,5.85,0,0,0,.33.82l.81,1.61s-1.21.77-2.45,1.51a14.22,14.22,0,0,1-3.27,1.84,7.24,7.24,0,0,1-1.59-2.08c-3.5-5.52-14.93-26-15.53-29-.59-2.78-1-12-1.26-14.8s-.46-5.53-.54-8.29a25.06,25.06,0,0,1,.16-4.15,14.64,14.64,0,0,1,1.15-4.15Z"
          style="opacity: 0.30000000000000004"
        />
        <path
          d="M173.88,183.83c1.71,19.7,8.25,30.8,7.37,46.54,0,0,35.5.7,36.72.7.52-29,4.66-26.79,3.39-45.15.22-6-3.21-9.75-6.68-10.35l-15.12-3.13-19.49,3.35A7.48,7.48,0,0,0,173.88,183.83Z"
          style="fill: currentColor"
        />
        <polygon
          points="201.76 364.2 206.36 400.55 215.32 399.91 212.9 364.2 201.76 364.2"
          style="fill: #ffc3bd"
        />
        <path
          d="M217.5,325.71c.81,34.28-1.17,63.66-1.17,63.66l-12.09.82s-6.81-44.48-4.06-62.65c-.13-3.82-4.55-51.59-6.07-60.62-2.75-16.21-5.69-27.33-5.69-27.33L216.87,238S217,304.13,217.5,325.71Z"
          style="fill: currentColor"
        />
        <g style="opacity: 0.2; isolation: isolate">
          <path
            d="M201.27,252.05s-.92,37.55-2.56,58.14c-.85-11.13-3.08-34.24-4.6-43.27Z"
          />
        </g>
        <polygon
          points="163.91 364.87 160.22 399.32 169.15 398.35 175.63 364.44 163.91 364.87"
          style="fill: #ffc3bd"
        />
        <path
          d="M159.1,389.25l13.71-.5s11.68-42.09,16.61-64.33c4.78-24.79,13.7-86.66,13.7-86.66L180.33,236c-12.14,21.93-7.77,74.43-9.92,89.13C160.11,343.5,159.1,389.25,159.1,389.25Z"
          style="fill: currentColor"
        />
        <path
          d="M216.64,227H183.27s-5.48,19.31-5.43,19.12S217,248,217,248Z"
          style="fill: currentColor"
        />
        <path
          d="M217,397c5.92,3,6.57,3.06,13,4.08,1.42.23,1,3.59-2.27,3.77-5.43.33-5,.11-9.61.37-3.95.22-9.67.69-12.82.92h-.1a1.31,1.31,0,0,1-1.27-1.35l.48-7.19a.64.64,0,0,1,.6-.58l9.93-.51A3.74,3.74,0,0,1,217,397Z"
          style="fill: #263238"
        />
        <path
          d="M217,397.52c.7,1.12,2.06,3,3.23,3.09a1.08,1.08,0,0,0,.92-.38h0a.61.61,0,0,0,.14-.58c-.29-1.07-3.68-2.29-4.06-2.41a.2.2,0,0,0-.22.05.21.21,0,0,0,0,.2Zm3.93,2.23a.23.23,0,0,1-.06.22.64.64,0,0,1-.6.25c-.66-.05-1.63-1-2.64-2.44,1.32.51,3,1.31,3.28,1.89Z"
          style="fill: currentColor"
        />
        <path
          d="M217,397.6c1,.4,3.55.61,4.12-.14.13-.17.22-.47-.1-.88h0a1.45,1.45,0,0,0-1-.54c-1.31-.16-3,1.18-3.09,1.23a.22.22,0,0,0-.07.18l0,0A.15.15,0,0,0,217,397.6Zm3.82-.61a.22.22,0,0,1,0,.25c-.34.44-2.22.44-3.34.13a4.56,4.56,0,0,1,2.53-.94,1,1,0,0,1,.7.4h0l.14.14Zm.06-.3"
          style="fill: currentColor"
        />
        <path
          d="M171.35,396.63c2.42,2.12,5.89,4.06,11.31,5.58,1.71.48.63,3.66-3.47,3.57-6.79-.1-4.09-.22-9.89-.34-5-.1-6.27-.14-10.21-.17H159a1.47,1.47,0,0,1-1.45-1.56l1.31-7.7a.77.77,0,0,1,.81-.58l10.36.28C170.89,395.65,170.67,396.13,171.35,396.63Z"
          style="fill: #263238"
        />
        <path
          d="M171.38,397.26c1.27.93,3.58,2.41,5,2.13a1.33,1.33,0,0,0,1-.69h0a.57.57,0,0,0,0-.64c-.71-1.05-5.22-1.19-5.73-1.19a.2.2,0,0,0-.2.15.17.17,0,0,0,0,.21Zm5.6,1a.21.21,0,0,1,0,.25.88.88,0,0,1-.62.46c-.78.13-2.3-.47-4.06-1.67,1.75.09,4.08.36,4.6.89Z"
          style="fill: currentColor"
        />
        <path
          d="M171.56,397.31c1.32.09,4.46-.55,4.84-1.49.11-.22.09-.55-.45-.87h0a2,2,0,0,0-1.35-.25c-1.62.26-3.13,2.22-3.21,2.29a.17.17,0,0,0,0,.2l0,0A.28.28,0,0,0,171.56,397.31Zm4.29-1.93c.14.14.1.23.16.27-.23.56-2.46,1.15-3.92,1.22a5.46,5.46,0,0,1,2.65-1.79,1.38,1.38,0,0,1,1,.17h0l.16.16Zm0-.27"
          style="fill: currentColor"
        />
        <path
          d="M173.88,183.83c1.71,19.7,8.25,30.8,7.37,46.54,0,0,35.5.7,36.72.7.52-29,4.66-26.79,3.39-45.15.22-6-3.37-9.79-6.84-10.39l-15-3.09-19.49,3.35A7.48,7.48,0,0,0,173.88,183.83Z"
          style="fill: #fff; opacity: 0.8"
        />
        <path
          d="M210.75,213.14l-4,7.12-7.24-7.71c0-.19,3.1-32.55,3.1-32.55l1.87-.14C204.3,183.79,210.75,213.14,210.75,213.14Z"
          style="fill: currentColor"
        />
        <path
          d="M209.55,178.63a93.06,93.06,0,0,1-4.14,41.08l3,32.34c.59,4.69,3.6,5.61,6.29,5.86l5.52-.44-2-30.86s4.51-34,3.47-41.7c-.69-5.08-2.37-7.06-3.57-8.09a4,4,0,0,0-1.79-.86c-2.1-.44-8.28-2.11-8-2S209.55,178.63,209.55,178.63Z"
          style="fill: #263238"
        />
        <path
          d="M206.62,164.46c-.76,3.61-1.93,8.53,2,10.21,1.71.74,2.52,3.14.88,3.91s-12.26,0-13.19-.44c-2.3-1.18-3.92-2-2.68-4.3,2.16-3.93.79-12.15.5-16.1Z"
          style="fill: #ffc3bd"
        />
        <path
          d="M205.54,178.2l-.49,1.55a.91.91,0,0,1-.72.72l-1.87.14a.71.71,0,0,1-.68-.49l-.91-1.38,2.33-1.37Z"
          style="fill: currentColor"
        />
        <path
          d="M196.61,163.45a12.49,12.49,0,0,0,2.4,3.76c1.45,1.66,4.18,2.39,6.82,2.39a26.68,26.68,0,0,1,.78-5.18l-.55-.29Z"
          style="opacity: 0.2; isolation: isolate"
        />
        <path
          d="M192,257.79c9,.61,11.5-.32,12.75-7.34,0,0,2.38-25.17,1.91-30.74,0,0-6-11.7-7.18-19.83-1.28-9.09-6.86-27.29-8.47-27.17-3.09.21-14.62,4.25-16.49,6.72-2.48,3.29-.87,7.09-.28,11.17,1.88,13.07,3.29,17.57,4,30.75,0,0-6,34-5.08,34.12C173.86,255.54,184.82,257.3,192,257.79Z"
          style="fill: #263238"
        />
        <path
          d="M181.25,230.37h15.36v.69a3.68,3.68,0,0,1-3.68,3.68h-8a3.68,3.68,0,0,1-3.68-3.68Z"
          style="fill: #fff; opacity: 0.2"
        />
        <path
          d="M218.57,234.91h-2.16a3.7,3.7,0,0,1-3.84-3.53l0-.68,5.94-.25Z"
          style="fill: #fff; opacity: 0.2"
        />
        <path
          d="M212.67,159.4c-1,7.15-9.29,11.27-15.11,6.45-3.37-2.78-4-6.24-4.88-11.65-.13-.82-.26-1.68-.41-2.6a9.67,9.67,0,0,1,.24-4.28,8.73,8.73,0,0,1,.65-1.68,9.65,9.65,0,0,1,11.3-5C211.59,142.72,213.56,153,212.67,159.4Z"
          style="fill: #ffc3bd"
        />
        <path
          d="M195.55,146.22a29.88,29.88,0,0,1,2.45,5,6.62,6.62,0,0,0-1.82.71c-.52.33,0,1.89.27,2.47a28.31,28.31,0,0,0,1.69,3.4,12.69,12.69,0,0,1-2.89.84,5.18,5.18,0,0,0-1.3-2,2.66,2.66,0,0,0-2.87-.85c-.18-.89-5.41-4.91-1.05-7.6a9.26,9.26,0,0,1,2.09-1.25l1-.37A7.3,7.3,0,0,1,195.55,146.22Z"
          style="fill: #263238"
        />
        <path
          d="M191.32,154c2.23-.51,4.3,1.94,5,3.8.82,2.06-.16,4.17-2.46,3.76a6.6,6.6,0,0,1-4.24-2.8A3,3,0,0,1,191.32,154Z"
          style="fill: #ffc3bd"
        />
        <path
          d="M204.7,151c.37,0,.72.39.78,1s-.15,1.06-.57,1.1-.76-.39-.82-1S204.33,151.08,204.7,151Z"
          style="fill: #263238"
        />
        <path
          d="M211.11,150.58c.37,0,.72.39.78,1s-.19,1.06-.57,1.1-.72-.39-.78-.95S210.74,150.63,211.11,150.58Z"
          style="fill: #263238"
        />
        <path
          d="M209.49,157.71a3.27,3.27,0,0,0,2.57-1.09,20.06,20.06,0,0,1-3.65-4.21Z"
          style="fill: #f98c8c"
        />
        <path
          d="M203.17,150.06a2.69,2.69,0,0,1,2-1.35h.11a.34.34,0,0,0,.28-.39.35.35,0,0,0-.39-.28,3.35,3.35,0,0,0-2.58,1.65.35.35,0,0,0,.11.46.34.34,0,0,0,.17,0A.42.42,0,0,0,203.17,150.06Z"
          style="fill: #263238"
        />
        <path
          d="M212.38,149.48h0a.34.34,0,0,0,.06-.47,3.34,3.34,0,0,0-2.76-1.32h0a.32.32,0,0,0-.29.36v0a.33.33,0,0,0,.36.28,2.65,2.65,0,0,1,2.17,1.07.33.33,0,0,0,.29.13A.48.48,0,0,0,212.38,149.48Z"
          style="fill: #263238"
        />
        <path
          d="M201.22,214.16c-1.25-4.4-2.69-8.84-5.3-12.59-.94-1.36-2-2.61-3-4a55,55,0,0,1-3.69-5.54c1.1-1,2.55-2,3.73-2.92l-4.46-3.69a46.44,46.44,0,0,0,1.53-13,2.3,2.3,0,0,1,1.69-.4,34.64,34.64,0,0,1,4.59,10.79A179.09,179.09,0,0,1,201.22,214.16Z"
          style="fill: #263238"
        />
        <path
          d="M206.13,218.61l0,0c-.07-.09-.13-.2-.2-.31-2.84-4.42-15.76-24.6-16.66-26.28,1.11-1,2.56-2,3.74-2.92l-4.46-3.69a46.81,46.81,0,0,0,1.53-13,2.29,2.29,0,0,1,1.68-.4,35,35,0,0,1,3.34,6.64,33.56,33.56,0,0,1,1.25,4.15c2.53,9.94,5.17,26.14,9.62,35.37Z"
          style="fill: #fff; opacity: 0.2"
        />
        <path
          d="M212.57,216.29c-.07-4.58,0-9.24,1.43-13.59.51-1.57,1.19-3.08,1.72-4.65.68-2,2.75-4.48,3.17-6.58-1.34-.61-3-1.23-4.42-1.72l3.21-4.82c-2.3-3.73-5.81-6.77-7-11a2.31,2.31,0,0,0-1.73.1c-.71,2.35.41,9.19.47,10.63C209.92,195.29,210.23,205.92,212.57,216.29Z"
          style="fill: #263238"
        />
        <path
          d="M218.89,191.47c-2.49,6.82-7.68,21-12.76,27.14l0,0c-.16.19-.31.37-.47.54l.27-.85a1,1,0,0,1,0-.14c4.63-15,4-23.15,3.51-33.54-.06-1.44-1.18-8.47-.47-10.81a2.26,2.26,0,0,1,1.73-.1c1.17,4.22,4.68,7.44,7,11.17-1.06,1.61-2.13,3.21-3.2,4.82C215.85,190.24,217.55,190.86,218.89,191.47Z"
          style="fill: #fff; opacity: 0.2"
        />
        <path
          d="M173.46,186.72s3.07,6.9,10.48,17.09c7.53,10.35,11.76,10.35,11.76,10.35l5.52,1.66s-1.42-26.1-17.46-30C179,184.63,172.44,186.75,173.46,186.72Z"
          style="opacity: 0.30000000000000004"
        />
        <polygon
          points="236.2 203.71 227.95 204.09 227.95 208.63 236.2 207.47 236.2 203.71"
          style="fill: #ffc3bd"
        />
        <path
          d="M233.83,206.7c-.17,4,4.71,4.91,4.71,4.91l2.54,2.08a2.24,2.24,0,0,0,2.92,0l2.1-1.79.06-.05a2.24,2.24,0,0,0,.14-3.16l-1.71-1.89A7.22,7.22,0,0,0,242.1,205l-5.9-1.29Z"
          style="fill: #ffc3bd"
        />
        <path
          d="M174.27,181.22c-1.7,3.32-.94,7.16,1.1,10.28,4.64,7.06,19.94,16.44,23,18.51,6.92,4.72,35-.78,35-.78l.41-6.93-30-1.5s-10.07-16.77-17-21.89C183.29,176.35,176.65,176.57,174.27,181.22Z"
          style="fill: #263238"
        />
      </g>
      <path
        d="M196.48,139.73a3.3,3.3,0,0,0-2.59.83,10.47,10.47,0,0,0-3.92,6.08,3,3,0,0,0,0,1.51,1.29,1.29,0,0,0,1.05,1,6,6,0,0,1,2.55-1.58,34.46,34.46,0,0,1,6-1.39,75.86,75.86,0,0,0,10.93-2.5c.71-.21,1.52-.53,1.71-1.24s-1.34-1.87-1.09-2.1c.39-.38.93-.72.93-1.26a1.3,1.3,0,0,0-.17-.61,2.89,2.89,0,0,0-1.58-1.55,1.45,1.45,0,0,0-1.83.87,17.27,17.27,0,0,0-.44-1.92,1.36,1.36,0,0,0-.78-.6,3.17,3.17,0,0,0-2.91.62,9.43,9.43,0,0,0-2,2.37C201.3,139.78,198.3,140.15,196.48,139.73Z"
        style="fill: #263238"
      />
      <path
        d="M205.82,158.8h0a.16.16,0,0,1,0-.23.15.15,0,0,1,.21,0,4.22,4.22,0,0,0,3.6,1.28.17.17,0,0,1,.18.15.16.16,0,0,1-.14.17A4.48,4.48,0,0,1,205.82,158.8Z"
        style="fill: #263238"
      />
      <path
        d="M191.71,172.34l2.54-.8a24.65,24.65,0,0,0,9.4,6.43,9.56,9.56,0,0,0-3.72,4.14C191.43,178.75,191.71,172.34,191.71,172.34Z"
        style="fill: #fff"
      />
      <path
        d="M208.64,172.78l-2.31-.61s-1.18,4.81-3.61,5.38c4.11,1.83,4.7,3.93,4.7,3.93C213.54,179.83,208.64,172.78,208.64,172.78Z"
        style="fill: #fff"
      />
      <path
        d="M203.84,220.9a.93.93,0,1,1-.88-1A.94.94,0,0,1,203.84,220.9Z"
        style="fill: #fff; opacity: 0.30000000000000004"
      />
    </g>
  </svg>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("theme", ["theme"]),
    svgColor() {
      return this.theme.companyTheme.primaryColor || "#8800FF";
    },
  },
};
</script>

<style scoped lang="scss"></style>
