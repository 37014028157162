import { SYSTEM_STATUS_OBJECT } from "@/utils/constants";

export const mutations = {
  READ_ALL_LEADS(state, payload) {
    state.allLeads = payload.leads.map((lead) => {
      const leadSystems = lead.systems ? lead.systems : [],
        greaterSystemStatusIndex = leadSystems.reduce(
          (currentSystem, nextSystem) => {
            const nextSystemIndex = Object.keys(SYSTEM_STATUS_OBJECT).indexOf(
              nextSystem.status
            );
            if (nextSystemIndex > currentSystem)
              currentSystem = nextSystemIndex;
            return currentSystem;
          },
          -1
        ),
        greaterSystemStatus =
          greaterSystemStatusIndex >= 0
            ? Object.keys(SYSTEM_STATUS_OBJECT)[greaterSystemStatusIndex]
            : null;
      return {
        ...lead,
        translatedGreaterSystemStatus: greaterSystemStatus
          ? SYSTEM_STATUS_OBJECT[greaterSystemStatus]
          : null,
      };
    });
    state.leadsTableInfo.pageCount = Math.ceil(
      payload.amount / state.leadsTableInfo.itemPerPage
    );
  },

  UPDATE_LEADS_TABLE_INFO_PAGE(state, payload) {
    state.leadsTableInfo.page = payload;
  },

  UPDATE_LEADS_TABLE_INFO_ITEM_PER_PAGE(state, payload) {
    state.leadsTableInfo.page = 1;
    state.leadsTableInfo.itemPerPage = payload;
  },

  READ_LEAD(state, payload) {
    state.lead = payload;
  },

  UPDATE_LEAD(state, payload) {
    if (payload.cameFrom === "leadsTable")
      state.allLeads.forEach((lead) => {
        if (lead.id === payload.lead.id) Object.assign(lead, payload.lead);
      });
    else {
      state.lead.address = payload.lead.address;
      state.lead.cellphone = payload.lead.cellphone;
      state.lead.email = payload.lead.email;
      state.lead.name = payload.lead.name;
      state.lead.phone = payload.lead.phone;
      state.lead.surname = payload.lead.surname;
      state.lead.cpf = payload.lead.cpf;
      state.lead.cnpj = payload.lead.cnpj;
      state.lead.responsible = payload.lead.responsible;
      state.lead.customFields = payload.lead.customFields;
      state.lead.origin = payload.lead.origin;
      state.lead.otherOrigin = payload.lead.otherOrigin || "";
      if (payload.activities) state.lead.activities = payload.activities;
    }
  },

  DELETE_LEAD(state, payload) {
    state.allLeads = state.allLeads.filter((lead) => lead.id != payload);
  },

  ADD_OBSERVATION(state, payload) {
    state.lead.observations = payload.observations;
    if (payload.activities) state.lead.activities = payload.activities;
  },

  DELETE_OBSERVATION(state, payload) {
    state.lead.observations = state.lead.observations.filter(
      (observation) => observation._id !== payload.deletedObservationId
    );
    if (payload.activities) state.lead.activities = payload.activities;
  },

  CREATE_TASK(state, payload) {
    state.lead.tasks.unshift(payload.task[0]);
    if (payload.activities) state.lead.activities = payload.activities;
  },

  UPDATE_TASK(state, payload) {
    state.lead.tasks.forEach((task) => {
      if (task.id === payload.task.id) Object.assign(task, payload.task);
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  UPDATE_TASK_FINISHED(state, payload) {
    state.lead.tasks.forEach((task) => {
      if (task.id === payload.task.id) task.finished = payload.task.finished;
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  DELETE_TASK(state, payload) {
    state.lead.tasks = state.lead.tasks.filter(
      (task) => task.id !== payload.taskId
    );
    if (payload.activities) state.lead.activities = payload.activities;
  },

  CREATE_SYSTEM(state, payload) {
    state.lead.systems.unshift(payload.system);
    state.lead.systems.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    );
    if (payload.activities) state.lead.activities = payload.activities;
  },

  UPDATE_SYSTEM(state, payload) {
    state.lead.systems.forEach((system) => {
      if (system.id === payload.system.id)
        Object.assign(system, payload.system);
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  UPDATE_SYSTEM_STATUS(state, payload) {
    state.lead.systems = state.lead.systems.map((system) => {
      if (system.id === payload.system.id)
        system.status = payload.system.status;
      return system;
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  UPDATE_SYSTEM_COMMENTS(state, payload) {
    state.lead.systems.forEach((system) => {
      if (system.id === payload.systemId)
        system.commentsByStatus = payload.commentsByStatus;
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  DELETE_SYSTEM_COMMENT(state, payload) {
    state.lead.systems.forEach((system) => {
      if (system.id === payload.systemId)
        system.commentsByStatus = system.commentsByStatus.filter(
          (comment) => comment._id !== payload.commentId
        );
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  DELETE_SYSTEM(state, payload) {
    state.lead.systems = state.lead.systems.filter(
      (system) => system.id !== payload.deletedSystemId
    );
    if (payload.activities) state.lead.activities = payload.activities;
  },

  DELETE_SIZING(state, payload) {
    state.lead.systems.forEach((system) => {
      if (system.id === payload.systemId)
        system.sizings = system.sizings.filter(
          (sizing) => sizing.id !== payload.sizingId
        );
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },
  DELETE_PROPOSAL(state, payload) {
    state.lead.systems.forEach((system) => {
      if (system.id === payload.systemId) {
        system.proposals = system.proposals.filter(
          (proposal) => proposal.id !== payload.proposalId
        );
      }
    });
    if (payload.activities) state.lead.activities = payload.activities;
  },

  CREATE_LEAD_TOKEN(state, payload) {
    Object.assign(state.lead, payload);
  },

  UPDATE_SIZING_PROPOSAL(state, payload) {
    Object.assign(state.sizing.proposal, payload);
  },
};
