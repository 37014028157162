import { Staff } from "@/request";

const state = {
  items: [],
  total: 0,
  last_modified: null,
  status: {
    wait: "Aguardando",
    enable: "Ativo",
    disable: "Recusado",
    inactive: "Inativo",
  },
};

const getters = {
  items: (state) => state.items,
  total: (state) => state.total,
  status: (state) => state.status,
};

const mutations = {
  READ_INTEGRATORS(state, payload) {
    if (payload.items?.length) {
      payload.items.forEach((integrator) => {
        if (integrator?.mainContact?.cellphone) {
          integrator.mainContact.cellphone = integrator.mainContact.cellphone
            .replace(/\D/g, "")
            .replace(
              /(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
              (fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
                if (country)
                  return `${country} (${
                    ddd || dddWithZero
                  }) ${prefixTel}-${suffixTel}`;
                if (ddd || dddWithZero)
                  return `(${ddd || dddWithZero}) ${prefixTel}-${suffixTel}`;
                if (prefixTel && suffixTel) return `${prefixTel}-${suffixTel}`;
                return integrator.mainContact.cellphone;
              }
            );
        }
      });
    }
    state.items = payload.items;
    state.total = payload.total;
    state.last_modified = new Date();
  },

  CHANGE_INTEGRATOR_STATUS(state, payload) {
    const { companiesId, status } = payload;

    for (const item of state.items.filter((integrator) =>
      companiesId.includes(integrator.id)
    )) {
      item.accountStatus = status;
      item.accountStatusTranslated = state.status[status];
    }

    state.last_modified = new Date();
  },
};

const actions = {
  async fetch({ commit }, filter = {}) {
    const ret = await Staff.readIntegrator(filter);

    if (ret.error) throw ret.error;

    commit("READ_INTEGRATORS", ret.data);

    return ret.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
