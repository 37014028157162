import { Storage } from "@/localStorage";
import { ADVERTISING_POPUP } from "@/localStorage/storageKeys";
import { User } from "@/request";
import router from "@/router";
import store from "@/store";

export async function readPopUps() {
  const ret = await User.readAdvertisingPopUp();
  if (!ret.error && ret.data)
    store.commit("advertisingPopUps/SERVER_POPUPS", ret.data);
}

export async function getAdvertisingPopUpToDisplay(newRoute) {
  if (!store.getters["advertisingPopUps/serverPopUps"].length)
    await readPopUps();
  let selectedPopUpId = null,
    user = store.getters["userData/getUserData"],
    routeName = newRoute ?? router.history.current.name,
    serverPopUps = store.getters["advertisingPopUps/serverPopUps"],
    currentRoutePopUps = serverPopUps.filter((item) =>
      item.whereToDisplay.includes(routeName)
    ),
    clientControllerPopUps = await setClientControllerPopUps(
      currentRoutePopUps,
      user.id,
      routeName
    );

  clientControllerPopUps[user.id][routeName] =
    removeObsoleteClientControllerPopUps(
      clientControllerPopUps[user.id][routeName],
      currentRoutePopUps
    );
  clientControllerPopUps[user.id][routeName] =
    addNewPopUpsFromServerToClientController(
      clientControllerPopUps[user.id][routeName],
      currentRoutePopUps
    );
  clientControllerPopUps[user.id][routeName] =
    updateClientControllerPopUpsWeight(
      clientControllerPopUps[user.id][routeName],
      currentRoutePopUps
    );
  selectedPopUpId = selectPopUpToDisplay(
    clientControllerPopUps[user.id][routeName]
  );
  if (selectedPopUpId)
    clientControllerPopUps[user.id][routeName] =
      updateDisplayedPopUpClientController(
        selectedPopUpId,
        clientControllerPopUps[user.id][routeName],
        currentRoutePopUps
      );
  await Storage.setItem(ADVERTISING_POPUP, clientControllerPopUps);
  return currentRoutePopUps.find((item) => item.id === selectedPopUpId);
}

async function setClientControllerPopUps(
  currentRoutePopUps,
  userId,
  routeName
) {
  let result = await Storage.getItem(ADVERTISING_POPUP);
  if (!result) result = {};
  if (!result[userId]) result[userId] = {};
  if (!result[userId][routeName]) result[userId][routeName] = [];
  if (!result[userId][routeName].length)
    result[userId][routeName] = currentRoutePopUps.map((item, index) => {
      return { popUpId: item.id, weight: index, displayedTimes: 0 };
    });
  return result;
}

function removeObsoleteClientControllerPopUps(
  currentRouteClientControllerPopUps,
  currentRoutePopUps
) {
  let result = currentRouteClientControllerPopUps,
    obsoletePopUps = [];
  for (const clientPopUp of result) {
    const serverPopUp = currentRoutePopUps.find(
      (item) => item.id === clientPopUp.popUpId
    );
    if (!serverPopUp) obsoletePopUps.push(clientPopUp.popUpId);
  }
  obsoletePopUps.forEach((obsoletePopUpId) => {
    result = result.filter((item) => item.popUpId !== obsoletePopUpId);
  });
  return result;
}

function addNewPopUpsFromServerToClientController(
  currentRouteClientControllerPopUps,
  currentRoutePopUps
) {
  let result = currentRouteClientControllerPopUps;
  for (const serverPopUp of currentRoutePopUps) {
    const clientPopUp = result.find((item) => item.popUpId === serverPopUp.id);
    if (!clientPopUp) {
      result.push({
        popUpId: serverPopUp.id,
        displayedTimes: 0,
        weight: 0,
      });
    }
  }
  return result;
}

function updateClientControllerPopUpsWeight(
  currentRouteClientControllerPopUps,
  currentRoutePopUps
) {
  let result = currentRouteClientControllerPopUps;
  for (const clientPopUp of result) {
    const serverPopUp = currentRoutePopUps.find(
      (item) => item.id === clientPopUp.popUpId
    );
    clientPopUp.weight = getPopUpWeight(
      serverPopUp.displayFrequency,
      clientPopUp.displayedTimes
    );
  }
  return result.sort((a, b) => (a.weight > b.weight ? -1 : 1));
}

function selectPopUpToDisplay(currentRouteClientControllerPopUps) {
  let selectedValidPopUpId = null;

  if (currentRouteClientControllerPopUps.length) {
    const validPopUps = selectValidPopUps(currentRouteClientControllerPopUps);
    if (validPopUps.length) {
      const selectedValidPopUpIndex = getValidPopUpIndexToDisplay(
        validPopUps.length - 1
      );
      selectedValidPopUpId = validPopUps[selectedValidPopUpIndex];
    }
  }
  return selectedValidPopUpId;
}

function updateDisplayedPopUpClientController(
  popUpId,
  clientControllerPopUps,
  currentRoutePopUps
) {
  let result = clientControllerPopUps;
  for (const popUp of result) {
    if (popUp.popUpId === popUpId) {
      popUp.displayedTimes++;
      const popUpDisplayFrequency = currentRoutePopUps.find(
        (item) => item.id === popUpId
      ).displayFrequency;
      popUp.weight = getPopUpWeight(
        popUpDisplayFrequency,
        popUp.displayedTimes
      );
      break;
    }
  }
  return result;
}

function getPopUpWeight(displayFrequency, displayedTimes) {
  return (displayedTimes * 100) / displayFrequency;
}

function selectValidPopUps(clientControllerPopUps) {
  const result = [];
  for (const clientPopUp of clientControllerPopUps)
    if (clientPopUp.weight < 100) result.push(clientPopUp.popUpId);
  return result;
}

function getValidPopUpIndexToDisplay(maxIndex) {
  return Math.floor(Math.random() * maxIndex);
}

export const WHERE_TO_DISPLAY_POP_UP_KEYS_VALUES = {
  DashboardIntegrator: "Visão Geral",
  Budgets: "Orçamentos",
  Requests: "Pedidos",
  SizingComponent: "Engenheiro Virtual",
  KitsIntegrator: "Kits Fechados",
  MountKit: "Kits Personalizados",
  Separate: "Produtos Avulsos",
  KitsExpress: "Kits Express",
};

export const whereToDisplayPopUpOptions = () => {
  return Object.keys(WHERE_TO_DISPLAY_POP_UP_KEYS_VALUES).map((key) => ({
    value: key,
    label: WHERE_TO_DISPLAY_POP_UP_KEYS_VALUES[key],
  }));
};
