import { Integrator } from "@/request";

const state = {
  //Permissions
  permissions: {
    authSellerComplementaryValues: false,
    authSellerProfitMagin: false,
    authSellerDiscount: false,
    authSellerMaxDiscount: 0,
    authSellerViewRequestsBudgetsPrices: true,
  },
  //ComplementaryValues
  complementaryValues: [],
  //DefaultValues
  profitMarginTable: [],
  valueByWpTable: [],
};

const mutations = {
  READ_PROPOSAL_SETTINGS(state, payload) {
    if (payload !== undefined) {
      state.permissions = payload.permissions;
      state.complementaryValues = payload.complementaryValues ?? [];
      state.profitMarginTable = payload.profitMarginTable ?? [];
      state.valueByWpTable = payload.valueByWpTable ?? [];
    }
  },

  UPDATE_PROPOSAL_SETTINGS_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },

  CREATE_UPDATE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_TABLE(state, payload) {
    state.complementaryValues = payload;
  },

  CREATE_UPDATE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_BAND(state, payload) {
    state.complementaryValues = state.complementaryValues.map((table) => {
      if (table._id === payload[0]._id) Object.assign(table, payload[0]);
      return table;
    });
  },

  CREATE_UPDATE_PROPOSAL_SETTINGS_DEFAULT_VALUES_BAND(state, payload) {
    state[payload.tableKind] = payload.tableItems;
  },

  DELETE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_TABLE(state, payload) {
    state.complementaryValues = state.complementaryValues.filter(
      (table) => table._id !== payload
    );
  },

  DELETE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_BAND(state, payload) {
    state.complementaryValues = state.complementaryValues.map((table) => {
      if (table._id === payload.tableId)
        table.bands = table.bands.filter((band) => band._id !== payload.bandId);
      return table;
    });
  },

  DELETE_PROPOSAL_SETTINGS_DEFAULT_VALUES_BAND(state, payload) {
    state[payload.tableKind] = state[payload.tableKind].filter(
      (band) => band._id !== payload.bandId
    );
  },
};

const actions = {
  async readProposalSettingsPermissionsAndDefaultValues(context) {
    const ret = await Integrator.readProposalSettings(
      "permissionsAndDefaultValues"
    );
    if (!ret.error && ret.data)
      context.commit("READ_PROPOSAL_SETTINGS", ret.data[0]);
    return ret;
  },

  async updateProposalSettingsPermissions(context, payload) {
    const ret = await Integrator.updateProposalSettingsPermissions(payload);
    if (!ret.error && ret.data)
      context.commit("UPDATE_PROPOSAL_SETTINGS_PERMISSIONS", payload);
    return ret;
  },

  async createUpdateComplementaryValuesTable(context, payload) {
    const ret = await Integrator.createUpdateComplementaryValuesTable(payload);
    if (!ret.error && ret.data)
      context.commit(
        "CREATE_UPDATE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_TABLE",
        ret.data
      );
    return ret;
  },

  async createUpdateComplementaryValuesBand(context, payload) {
    const ret = await Integrator.createUpdateComplementaryValuesBand(payload);
    if (!ret.error && ret.data)
      context.commit(
        "CREATE_UPDATE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_BAND",
        ret.data
      );
    return ret;
  },

  async createUpdateDefaultValuesBand(context, payload) {
    const ret = await Integrator.createUpdateDefaultValuesBand(payload);
    if (!ret.error && ret.data)
      context.commit("CREATE_UPDATE_PROPOSAL_SETTINGS_DEFAULT_VALUES_BAND", {
        tableKind: payload.tableKind,
        tableItems: ret.data,
      });
    return ret;
  },

  async deleteComplementaryValuesTable(context, payload) {
    const ret = await Integrator.deleteComplementaryValuesTable(payload);
    if (!ret.error && ret.data)
      context.commit(
        "DELETE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_TABLE",
        payload
      );
    return ret;
  },

  async deleteComplementaryValuesBand(context, payload) {
    const ret = await Integrator.deleteComplementaryValuesBand(
      payload.tableId,
      payload.bandId
    );
    if (!ret.error && ret.data)
      context.commit(
        "DELETE_PROPOSAL_SETTINGS_COMPLEMENTARY_VALUES_BAND",
        payload
      );
    return ret;
  },

  async deleteDefaultValuesBand(context, payload) {
    const ret = await Integrator.deleteDefaultValuesBand(
      payload.tableKind,
      payload.bandId
    );
    if (!ret.error && ret.data)
      context.commit("DELETE_PROPOSAL_SETTINGS_DEFAULT_VALUES_BAND", payload);
    return ret;
  },
};

const getters = {
  getPermissions: (state) => state.permissions,

  getComplementaryValues: (state) => state.complementaryValues,

  getProfitMarginTable: (state) => state.profitMarginTable,

  getValueByWpTable: (state) => state.valueByWpTable,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
