import Vue from "vue";
import router from "./../router";
import * as Sentry from "@sentry/vue";
import { getRuntimeEnv } from "@/utils/utils";
import { BrowserTracing } from "@sentry/tracing";

export const CreateTransaction = (
  operation,
  options = {
    name: null,
    description: null,
    data: {},
  }
) => {
  const transaction = Sentry.startTransaction({
    op: operation,
    name: options?.name,
    description: options?.description,
  });

  if (options?.data) transaction.data = options.data;

  Sentry.withScope((scope) => {
    if (typeof options?.setScope === "function") {
      options.setScope(scope);
    }

    scope.setSpan(transaction);
  });

  return {
    exception: (e) => Sentry.captureException(e),
    save: () => transaction.finish(),
  };
};

if (process.env.NODE_ENV === "production") {
  if (!getRuntimeEnv("VUE_APP_environment"))
    throw new Error("Varivavel VUE_APP_enviroment não configurada");
  if (!getRuntimeEnv("VUE_APP_sentry_DSN"))
    throw new Error("Varivavel VUE_APP_sentry_DSN não configurada");

  Sentry.init({
    Vue,
    dsn: getRuntimeEnv("VUE_APP_sentry_DSN"),
    environment: getRuntimeEnv("VUE_APP_environment"),
    tracesSampleRate: getRuntimeEnv("VUA_APP_sentry_traces_sample_rate") || 1.0,
    release: document?.documentElement?.dataset?.buildTimestampUtc?.toString(),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    ignoreErrors: ["NavigationDuplicated", /Avoided redundant navigation/],
  });
}
