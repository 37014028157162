const state = {
  loading: false,
};

const mutations = {
  UPDATE_LOADING(state, payload) {
    state.loading = payload;
  },
};

export default {
  state,
  mutations,
};
