import { io, Socket as SocketIO } from "socket.io-client";
import store from "@/store";
import { baseURL, getBaseHeader, updateExpiredToken } from "@/request/http";

export const SOCKET_STATUS = {
  DISCONNECTED: "DISCONNECTED",
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
};

class Socket {
  /**
   * @type {null|Socket}
   */
  static instance = null;

  static status = SOCKET_STATUS.DISCONNECTED;

  static subscriptions = [];

  /**
   * @type {SocketIO}
   */
  io = null;

  async getAuth() {
    const baseHeader = await getBaseHeader();
    return {
      authorization: baseHeader.authorization,
    };
  }

  /**
   * @returns {SocketIO}
   */
  async connect() {
    if (this.io) return this.io;

    const baseHeader = await getBaseHeader();
    if (!baseHeader?.authorization) throw "invalidToken";
    const user = store.getters["userData/getUserData"];

    Socket.status = SOCKET_STATUS.PENDING;

    this.io = io(`${baseURL}/${user?.distribuitor?.id}`, {
      auth: async (cb) => cb(await this.getAuth()),
      transports: ["websocket"],
      query: {
        buildTimestampUtc:
          document?.documentElement?.dataset?.buildTimestampUtc?.toString(),
      },
    });

    this.io.on("connect_error", async (error) => {
      if (error?.message === "not authorized") {
        await updateExpiredToken();
      } else if (!this.io?.active) {
        this.io?.disconnect();
        setTimeout(() => this.io?.connect(), 5000);
      }
    });

    this.io.on("newVersion", async (timestamp) => {
      store.dispatch("userData/newBuildDetected", new Date(timestamp));
    });

    this.io.on("connect", async () => {
      Socket.status = SOCKET_STATUS.CONNECTED;
      for (const subscription of Socket.subscriptions
        .map((s) => s.subscribeArray)
        .flat()) {
        subscription(this.io);
      }
    });

    this.io.on("disconnect", async (reason) => {
      console.warn("disconnected: ", reason);
      Socket.status = SOCKET_STATUS.DISCONNECTED;
    });

    return this.io;
  }

  disconnect() {
    this.io.disconnect();
    this.io = null;
    Socket.subscriptions = [];
  }

  /**
   * @param {Array<(io?:SocketIO) => void>} subscribeArray
   * @param {Array<(io?:SocketIO) => void>} unsubscribeArray
   * @returns
   */
  subscribe(subscribeArray = [], unsubscribeArray = []) {
    const subscription = { subscribeArray, unsubscribeArray };
    Socket.subscriptions.push(subscription);
    for (const subscription of subscribeArray) {
      subscription(this.io);
    }
    return () => this.unsubscribe(subscription);
  }

  unsubscribe(subscription) {
    Socket.subscriptions.slice(Socket.subscriptions.indexOf(subscription), 1);
    for (const unsubscription of subscription.unsubscribeArray) {
      unsubscription(this.io);
    }
  }

  /**
   * @param {*} timeout
   * @returns {Promise<SocketIO|null>}
   */
  async waitConnection(timeout = 10000) {
    return new Promise((resolve, reject) => {
      if (Socket.status === SOCKET_STATUS.CONNECTED) resolve(this.io);
      setTimeout(() => {
        reject("pending");
      }, 50);
    }).catch(() => {
      if (timeout > 0) {
        return this.waitConnection(timeout - 50);
      } else {
        return Promise.resolve(null);
      }
    });
  }

  /**
   *
   * @param {string} event   *
   * @param {unknown} data
   * @param options
   * @returns {unknown}
   */

  async promisefy(event, data, options = {}) {
    const { timeout = 10000, retry = 2 } = options;
    return new Promise(async (resolve, reject) => {
      let timer;

      if (!this.io?.active) {
        await this.waitConnection();
      }

      function responseHandler(message) {
        // resolve promise with the value we got
        resolve(message);
        clearTimeout(timer);
      }

      this.io?.emit(event, data, (ret) => responseHandler(ret));
      this.io?.once(event, responseHandler);

      // set timeout so if a response is not received within a
      // reasonable amount of time, the promise will reject
      timer = setTimeout(async () => {
        this.io?.removeListener(event, responseHandler);
        reject(new Error(`Timeout on ${event}`));
      }, timeout);
    }).catch((err) => {
      if (retry > 0) {
        return this.promisefy(event, data, { ...options, retry: retry - 1 });
      } else {
        if (!this.io?.active) {
          return null;
        } else {
          throw err;
        }
      }
    });
  }

  /**
   * @returns {Socket}
   */
  static getInstance() {
    if (!Socket.instance) {
      Socket.instance = new Socket();
    }

    return Socket.instance;
  }
}

export default Socket.getInstance();
