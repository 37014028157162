import Icons from "@/constants/Icons";

export default [
  {
    path: "/",
    name: "Login",
    meta: {
      title: "Login",
      permissions: ["open"],
    },
    component: () => import("@/views/login/PageLogin.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "Cadastro",
      permissions: ["open"],
    },
    component: () => import("@/views/register/PageRegister.vue"),
  },
  {
    path: "/api",
    name: "OpenAPI",
    meta: {
      icon: Icons.KEY,
      title: "API",
      actions: ["openAPI"],
      permissions: ["open"],
    },
    component: () => import("@/views/settings/api/Api.vue"),
  },
  {
    path: "/termos-de-uso",
    name: "TermsAndConditionsIndex",
    meta: {
      title: "Termos de Uso",
      permissions: ["open"],
    },
    component: () => import("@/views/open/PageTermsAndConditions.vue"),
  },
];
