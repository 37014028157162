<template>
  <PiedDialog v-model="show" clean-head width="685px">
    <template #body>
      <v-window v-model="currentStep" :reverse="!needImprovement">
        <v-window-item class="nps-dialog-research__container">
          <div class="nps-dialog-research__title">
            Em uma escala de 0 a 10, o quanto você recomendaria a plataforma
            Pied da Pieta.tech para um amigo ou colega?
          </div>
          <div>
            <div class="d-flex flex-row justify-space-between">
              <div class="nps-dialog-research__subtitle">IMPROVÁVEL</div>
              <div class="nps-dialog-research__subtitle text-right">
                MUITO PROVÁVEL
              </div>
            </div>
            <div
              class="nps-dialog-research__vote-container"
              :data-has-selection="hasVote"
            >
              <div
                v-for="index in 11"
                :data-selected="form.scoreValue === index - 1"
                :style="{ 'background-color': getColor(index - 1) }"
                @click="onVoteClick(index - 1)"
              >
                {{ index - 1 }}
              </div>
            </div>
          </div>
          <div>
            <div class="nps-dialog-research__subtitle">
              Em poucas palavras, descreva o que motivou a sua nota.
              <span>(Opcional)</span>
            </div>
            <v-textarea
              v-model="form.feedback"
              placeholder="Escreva aqui sua resposta..."
              hide-details
              outlined
            />
          </div>
        </v-window-item>

        <v-window-item class="nps-dialog-research__container">
          <div>
            <div class="nps-dialog-research__subtitle">
              Há algo específico que poderemos fazer para melhorar sua
              experiência na plataforma? <span>(Opcional)</span>
            </div>

            <v-textarea v-model="form.commentary" hide-details outlined />
          </div>
        </v-window-item>
        <v-window-item
          class="d-flex flex-column align-center"
          style="max-width: 400px; margin: auto"
        >
          <div style="height: 270px; width: 300px">
            <NpsStepThanks />
          </div>
          <div class="nps-dialog-research__vote-h1">
            Obrigado por contribuir!
          </div>
          <div class="nps-dialog-research__caption">
            O seu feedback é muito importante para melhorarmos a plataforma
            ainda mais.
          </div>

          <v-btn
            class="mt-5"
            width="150px"
            height="40px"
            color="button"
            outlined
            @click="show = false"
          >
            Fechar
          </v-btn>
        </v-window-item>
      </v-window>
    </template>

    <template v-if="currentStep <= 1" #actions>
      <v-btn
        color="button"
        class="white--text"
        width="150px"
        height="40px"
        depressed
        :disabled="!hasVote"
        @click="onNext"
      >
        Próximo
      </v-btn>
    </template>
  </PiedDialog>
</template>

<script>
import PiedDialog from "@/components/global/PiedDialog.vue";
import { User } from "@/request";
import NpsStepThanks from "@/components/plataform/nps/step/NpsStepThanks.vue";
import socket from "@/plugins/socket";

export default {
  components: { NpsStepThanks, PiedDialog },
  data() {
    return {
      nps: null,
      show: false,
      answered: false,
      currentStep: 0,
      form: {
        scoreValue: -1,
        commentary: "",
        feedback: "",
      },
    };
  },
  computed: {
    hasVote() {
      return this.form.scoreValue !== -1;
    },
    needImprovement() {
      return this.form.scoreValue <= 6;
    },
  },
  watch: {
    show(val) {
      if (val) return this.currentStep;
      if (this.answered) return;

      User.npsPostpone(this.nps.id);
    },
  },
  mounted() {
    socket.subscribe([
      (io) =>
        io?.on("nps", async (nps) => {
          this.show = true;
          this.nps = nps;
          this.currentStep = 0;
          this.answered = false;
          this.form = {
            scoreValue: -1,
            commentary: "",
            feedback: "",
          };
        }),
    ]);
  },
  methods: {
    getColor(index) {
      switch (index) {
        case 0:
          return "#B72224";
        case 1:
          return "#D52029";
        case 2:
          return "#E95223";
        case 3:
          return "#EA6F22";
        case 4:
          return "#F6A726";
        case 5:
          return "#FDC729";
        case 6:
          return "#EBDB0A";
        case 7:
          return "#E5E044";
        case 8:
          return "#C2D234";
        case 9:
          return "#AEC93C";
        case 10:
          return "#66B44E";
      }
    },
    onVoteClick(index) {
      this.form.scoreValue = index;
    },
    onNext() {
      if (this.needImprovement) {
        this.currentStep++;
      } else {
        this.currentStep = 2;
      }

      if (this.currentStep === 2) {
        this.onSave();
      }
    },
    onSave() {
      this.answered = true;
      const key = this.needImprovement
        ? "negativeFeedback"
        : "positiveFeedback";

      User.npsAnswer(this.nps.id, {
        scoreValue: this.form.scoreValue,
        commentary: this.form.commentary,
        [key]: this.form.feedback,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.nps-dialog-research__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #0b0b0b;
}

.nps-dialog-research__vote-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  &[data-has-selection="true"] {
    div {
      opacity: 45%;
    }
  }

  div {
    height: 45px;
    width: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;

    &[data-selected] {
      cursor: auto;
      opacity: 100%;
    }
  }
}

.nps-dialog-research__subtitle {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 10px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
}

.nps-dialog-research__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 29px;

  div {
    width: 100%;
  }
}

.nps-dialog-research__vote-h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  color: black;
}

.nps-dialog-research__caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: black;
}
</style>
