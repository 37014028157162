<template>
  <PiedDialog
    v-model="show"
    title-font-size="18px"
    :title="title"
    :title-color="titleColor"
    :width="hasAction ? '450px' : '500px'"
    @close="onClose"
  >
    <template #body>
      <div>
        <v-icon size="50" :style="{ color: iconColor }">
          {{ icon }}
        </v-icon>
      </div>

      <div :class="messageClass" v-html="message" />

      <div v-if="hasAction" class="notify-dialog__actions">
        <v-btn class="mr-2" outlined @click="onClose">
          {{ cancelLabel }}
        </v-btn>
        <v-btn
          class="white--text"
          depressed
          :style="btnConfirmStyle"
          @click="onAccept"
        >
          {{ confirmLabel }}
        </v-btn>
      </div>
    </template>
  </PiedDialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PiedDialog from "@/components/global/PiedDialog.vue";

export default {
  name: "NotifyDialog",
  components: { PiedDialog },
  computed: {
    ...mapState("NotifyDialog", {
      value: "value",
      type: "type",
      title: "title",
      message: "message",
      textAlign: "textAlign",
      confirmLabel: "confirmLabel",
      cancelLabel: "cancelLabel",
      onConfirm: "onConfirm",
      onCancel: "onCancel",
    }),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.closeNotifyDialog();
      },
    },
    titleColor() {
      return this.type === "success" ? "#198F3A" : "#424259";
    },
    messageClass() {
      return {
        "notify-dialog__message": true,
        [`text-${this.textAlign}`]: true,
      };
    },
    icon() {
      switch (this.type) {
        case "success":
          return this.$icons.CHECK2;
        case "warning":
          return this.$icons.ALERT3;
        case "error":
          return this.$icons.CLOSE3;
        default:
          return this.$icons.HELP2;
      }
    },
    iconColor() {
      switch (this.type) {
        case "success":
          return "#198F3A";
        case "warning":
          return "#E0B400";
        case "error":
          return "#D80027CC";
        default:
          return "primary";
      }
    },
    hasAction() {
      return typeof this.onConfirm === "function";
    },
    btnConfirmStyle() {
      return this.type === "error"
        ? { "background-color": "#D80027CC", "font-weight": 600 }
        : { "background-color": "var(--v-button-base)" };
    },
  },
  methods: {
    ...mapActions("NotifyDialog", ["closeNotifyDialog"]),
    async onClose() {
      if (typeof this.onCancel === "function") {
        await this.onCancel();
      }

      await this.closeNotifyDialog();
    },
    onAccept() {
      this.onConfirm();
      this.closeNotifyDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-dialog__actions {
  display: flex;
  gap: 6px;

  button {
    flex: 1;
  }

  ::v-deep .v-btn__content {
    font-weight: inherit;
  }
}

.notify-dialog__message {
  font-weight: 400;
  font-size: 14px;
  color: #2f353999;
  margin: 16px 0;
}
</style>
