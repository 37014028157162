const state = {
  serverPopUps: [],
  displayingPopUp: false,
};

const mutations = {
  SERVER_POPUPS(state, payload) {
    Object.assign(state.serverPopUps, payload);
  },

  UPDATE_DISPLAYING_POPUP(state) {
    state.displayingPopUp = !state.displayingPopUp;
  },
};

const getters = {
  serverPopUps: (state) => state.serverPopUps,
  displayingPopUp: (state) => state.displayingPopUp,
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
};
