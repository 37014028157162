import ProductClassFilterModule from "@/components/plataform/products/class/filter/ProductClassFilterModule";
import ProductClassFilterInverter from "@/components/plataform/products/class/filter/ProductClassFilterInverter";
import ProductClassFilterKit from "@/components/plataform/products/class/filter/ProductClassFilterKit";
import ProductClassFilterStringBox from "@/components/plataform/products/class/filter/ProductClassFilterStringBox";
import ProductClassFilterComplementaryEquipment from "@/components/plataform/products/class/filter/ProductClassFilterComplementaryEquipment";

const newFilter = {
  module: () => new ProductClassFilterModule(),
  inverter: () => new ProductClassFilterInverter(),
  kit: () => new ProductClassFilterKit(),
  stringBox: () => new ProductClassFilterStringBox(),
  complementaryEquipment: () => new ProductClassFilterComplementaryEquipment(),
};

export const state = {
  module: newFilter["module"](),
  inverter: newFilter["inverter"](),
  kit: newFilter["kit"](),
  stringBox: newFilter["stringBox"](),
  complementaryEquipment: newFilter["complementaryEquipment"](),
};

const getters = {
  productFilter: (state) => (type) => state[type],
};

const mutations = {
  SAVE_FILTER_PRODUCT(state, { type, filter }) {
    if (state[type]) {
      state[type].saveFilter(filter);
    }
  },

  PAGINATE_FILTER_PRODUCT(state, { type, pagination }) {
    if (state[type]) {
      state[type].setPagination(pagination);
    }
  },

  CLEAR_FILTER_PRODUCT(state, type) {
    if (state[type]) {
      state[type].resetFilter();
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
