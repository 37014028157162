export const EnumPaymentStatus = {
  notRequested: {
    integrator: "Gerando Dados de Pagamento",
    distributor: "Aguardando Envio",
    color: "#717171",
    backgroundColor: "#71717126",
  },
  requested: {
    integrator: "Aguardando Pagamento",
    distributor: "Pagamento Enviado",
    color: "#E3C83A",
    backgroundColor: "#E3C83A26",
  },
  received: {
    integrator: "Pagamento Concluído",
    distributor: "Pagamento Recebido",
    color: "#198F3A",
    backgroundColor: "#DDEEE2",
  },
  cancelled: {
    integrator: "Pagamento Cancelado",
    distributor: "Pagamento Cancelado",
    color: "#E70C0CB2",
    backgroundColor: "#A8080826",
  },
};

export const PAYMENT_METHODS = [
  { text: "Boleto", value: "boleto", img: "boleto.svg" },
  { text: "Pix", value: "pix", img: "pix.svg" },
  { text: "Cartão de Crédito", value: "credito", img: "card.svg" },
  { text: "Cartão de Débito", value: "debito", img: "card.svg" },
  {
    text: "Direto com a Financeira",
    value: "diretoFinanceira",
    img: "bank.svg",
  },
  { text: "Outras Opções", value: "other", img: "others.svg" },
];

export const ListEnumPaymentStatus = Object.keys(EnumPaymentStatus).map((k) => {
  return { type: k, ...EnumPaymentStatus[k] };
});

const PaymentMethodTypeEnum = {
  BOLETO: "boleto",
  PIX: "pix",
  DEBITO: "debito",
  CREDITO: "credito",
  DIRETO_FINANCEIRA: "diretoFinanceira",
  OTHER: "other",
};

const PaymentShowConditionsRuleEnum = {
  YES_SHOW_FINAL_VALUE_UPDATED: "YesShowFinalValueUpdated",
  YES_SHOW_ONLY_VALUE_UPDATED: "YesShowOnlyValueUpdated",
  NOT_SHOW_VALUES: "NotShowValues",
};

const PaymentShowConditionsRuleEnumMapper = {
  [PaymentShowConditionsRuleEnum.YES_SHOW_FINAL_VALUE_UPDATED]:
    "Sim, exibir o valor final do pedido alterado",
  [PaymentShowConditionsRuleEnum.YES_SHOW_ONLY_VALUE_UPDATED]:
    "Sim, exibir apenas o valor de alteração",
  [PaymentShowConditionsRuleEnum.NOT_SHOW_VALUES]:
    "Não exibir o valor de alteração durante o fluxo de compra",
};

const PaymentConditionTypeEnum = {
  INCREASE: "increase",
  DECREASE: "decrease",
  NOT_UPDATE: "notUpdate",
};

const PaymentConditionTypeEnumMapper = {
  [PaymentConditionTypeEnum.INCREASE]: "Sim, acréscimo no valor",
  [PaymentConditionTypeEnum.DECREASE]: "Sim, desconto no valor",
  [PaymentConditionTypeEnum.NOT_UPDATE]: "Não atualizar",
};

const PaymentConditionModifierTypeEnum = {
  FIXED: "fixed",
  PERCENTAGE: "percentage",
};

const PaymentConditionModifierTypeEnumMapper = {
  [PaymentConditionModifierTypeEnum.PERCENTAGE]: "Percentual",
  [PaymentConditionModifierTypeEnum.FIXED]: "Valor específico",
};

const PaymentMethodTypeEnumMapper = {
  [PaymentMethodTypeEnum.BOLETO]: "Boleto",
  [PaymentMethodTypeEnum.PIX]: "Pix",
  [PaymentMethodTypeEnum.DEBITO]: "Débito",
  [PaymentMethodTypeEnum.CREDITO]: "Crédito",
  [PaymentMethodTypeEnum.DIRETO_FINANCEIRA]: "Direto Financeira",
  [PaymentMethodTypeEnum.OTHER]: "Outro",
};

export {
  PaymentMethodTypeEnum,
  PaymentShowConditionsRuleEnum,
  PaymentShowConditionsRuleEnumMapper,
  PaymentConditionTypeEnum,
  PaymentConditionTypeEnumMapper,
  PaymentMethodTypeEnumMapper,
  PaymentConditionModifierTypeEnum,
  PaymentConditionModifierTypeEnumMapper,
};
