import BaseFilter from "./BaseFilter";
export default class ProductClassFilterModule extends BaseFilter {
  constructor() {
    super({
      minPowerWP: null,
      maxPowerWP: null,
      isToSellOptions: [],
      manufacturer: [],
      minPrice: null,
      maxPrice: null,
      productCode: null,
      name: null,
      typeOfCell: null,
      model: null,
    });
  }
}
