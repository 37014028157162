import { Integrator } from "@/request";

const state = {
  tasksTableInfo: {
    page: 1,
    itemPerPage: 50,
    pageCount: 0,
  },

  tasks: [],
  leads: [],
};

const mutations = {
  READ_ALL_TASKS(state, payload) {
    state.tasks = payload.tasks;
    state.tasksTableInfo.pageCount = Math.ceil(
      payload.amount / state.tasksTableInfo.itemPerPage
    );
  },

  CREATE_TASK(state, payload) {
    state.tasks.unshift(payload.task[0]);
    state.tasks.sort((a, b) =>
      a.start > b.start ? -1 : a.end > b.end ? -1 : 1
    );
  },

  UPDATE_TASK(state, payload) {
    state.tasks.forEach((task) => {
      if (task.id === payload.task.id) Object.assign(task, payload.task);
    });
  },

  UPDATE_TASK_FINISHED(state, payload) {
    state.tasks.forEach((task) => {
      if (task.id === payload.task.id) task.finished = payload.task.finished;
    });
  },

  UPDATE_TASKS_TABLE_INFO_PAGE(state, payload) {
    state.tasksTableInfo.page = payload;
  },

  UPDATE_TASKS_TABLE_INFO_ITEM_PER_PAGE(state, payload) {
    state.tasksTableInfo.page = 1;
    state.tasksTableInfo.itemPerPage = payload;
  },

  DELETE_TASK(state, payload) {
    state.tasks = state.tasks.filter((task) => task.id !== payload.taskId);
  },

  READ_ALL_LEADS(state, payload) {
    state.leads = payload;
    state.leads.unshift({
      completeName: "Sem Cliente",
      id: undefined,
    });
  },
};

const actions = {
  async readTasksFromTasks(context, payload) {
    try {
      payload.page = context.state.tasksTableInfo.page;
      payload.limit = context.state.tasksTableInfo.itemPerPage;
      payload.amount = true;
      const ret = await Integrator.readTasks(payload);
      if (!ret.error && ret.data) context.commit("READ_ALL_TASKS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async createTaskFromTasks(context, payload) {
    try {
      const ret = await Integrator.createTask(payload);
      if (!ret.error && ret.data) context.commit("CREATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFromTasks(context, payload) {
    try {
      const ret = await Integrator.updateTask(payload);
      if (!ret.error && ret.data) context.commit("UPDATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFinishedFromTasks(context, payload) {
    try {
      const ret = await Integrator.updateTaskFinished(
        payload.taskId,
        payload.finished,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_TASK_FINISHED", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteTaskFromTasks(context, payload) {
    try {
      const ret = await Integrator.deleteTask(payload.taskId, payload.leadId);
      if (!ret.error && ret.data) context.commit("DELETE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },
};

const getters = {
  getTasksTableInfo: (state) => state.tasksTableInfo,

  getTasksFromTasks: (state) => state.tasks,

  getTaskById: (state) => (taskId) =>
    state.tasks.find((item) => item.id === taskId),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
