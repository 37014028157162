export const ENUM_NOTIFY_DIALOG_TEXT_ALIGN = {
  CENTER: "center",
  RIGHT: "right",
  LEFT: "left",
};

const state = {
  value: false,
  title: "",
  type: "",
  textAlign: "",
  message: "",
  confirmLabel: "Confirmar",
  cancelLabel: "Cancelar",
  onConfirm: () => {},
  onCancel: () => {},
};

const mutations = {
  OPEN_ALERT_DIALOG(state, payload) {
    state.value = true;
    state.type = payload.type;
    state.textAlign = payload.textAlign || ENUM_NOTIFY_DIALOG_TEXT_ALIGN.CENTER;
    state.title = payload.title;
    state.message = payload.message;
    state.confirmLabel = payload.confirmLabel || state.confirmLabel;
    state.cancelLabel = payload.cancelLabel || state.cancelLabel;
    state.onConfirm = payload.onConfirm;
    state.onCancel = payload.onCancel;
  },
  CLOSE_ALERT_DIALOG(state) {
    state.value = false;
    state.type = "";
    state.title = "";
    state.textAlign = ENUM_NOTIFY_DIALOG_TEXT_ALIGN.CENTER;
    state.message = "";
    state.confirmLabel = "Confirmar";
    state.cancelLabel = "Cancelar";
    state.onConfirm = null;
    state.onCancel = null;
  },
};

const actions = {
  openNotifySuccess({ commit }, payload) {
    commit("OPEN_ALERT_DIALOG", { ...payload, type: "success" });
  },
  openNotifyError({ commit }, payload) {
    commit("OPEN_ALERT_DIALOG", { ...payload, type: "error" });
  },
  openNotifyWarning({ commit }, payload) {
    commit("OPEN_ALERT_DIALOG", { ...payload, type: "warning" });
  },
  closeNotifyDialog({ commit }) {
    commit("CLOSE_ALERT_DIALOG");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
