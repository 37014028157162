import BaseFilter from "./BaseFilter";

export default class ProductClassFilterComplementaryEquipment extends BaseFilter {
  constructor() {
    super({
      categories: [],
      category: [],
      isToSellOptions: [],
      manufacturer: [],
      minPrice: null,
      maxPrice: null,
    });
  }
}
