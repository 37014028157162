import { Integrator } from "@/request";

const state = {
  systems: [],
};

const mutations = {
  READ_ALL_SYSTEMS(state, payload) {
    state.systems = payload;
  },

  UPDATE_SYSTEM_STATUS(state, payload) {
    state.systems = state.systems.map((system) => {
      if (system.id === payload.system.id)
        system.status = payload.system.status;
      return system;
    });
  },
};

const actions = {
  async readSystemsFromBusiness(context, payload) {
    try {
      const ret = await Integrator.readSystems(payload);
      if (!ret.error && ret.data) context.commit("READ_ALL_SYSTEMS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateSystemStatusFromBusiness(context, payload) {
    try {
      const ret = await Integrator.updateSystemStatus(
        payload.systemId,
        payload.newStatus,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_SYSTEM_STATUS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateSystemStatusFromBusinessDragging(context, payload) {
    try {
      const leadId =
          context.state.systems.find((system) => system.id === payload.systemId)
            .lead.id || "",
        ret = await Integrator.updateSystemStatus(
          payload.systemId,
          payload.newStatus,
          leadId
        );
      if (!ret.error && ret.data)
        context.commit("UPDATE_SYSTEM_STATUS", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },
};

const getters = {
  getAllSystems: (state) => state.systems || [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
