const originalState = {
  breadcrumb: [],
};

const state = originalState;

const mutations = {
  UPDATE_BREADCRUMB(state, payload) {
    Object.assign(state, payload);
  },

  RESET_BREADCRUMB(state) {
    Object.assign(state, originalState);
  },
};

const getters = {
  breadcrumb: (state) => state?.breadcrumb,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
