import { User } from "@/request";
import { SEPARATED_SALES_SECTION_TYPES } from "@/utils/constants";
import store from "@/store";

const getFormattedDefaultSection = (section) => {
  let formattedSection = null;
  const sectionType = section.type;
  const baseData = {
    description: section.name,
    type: sectionType,
    isCustom: false,
  };
  const separateMessages = store.getters["storeFlow/separateMessages"];
  switch (sectionType) {
    case SEPARATED_SALES_SECTION_TYPES.MODULE:
      formattedSection = {
        ...baseData,
        observation: [separateMessages["modules"]],
      };
      break;
    case SEPARATED_SALES_SECTION_TYPES.INVERTER:
      formattedSection = {
        ...baseData,
        observation: [separateMessages["inverters"]],
      };
      break;
    case SEPARATED_SALES_SECTION_TYPES.STRING_BOX:
      formattedSection = {
        ...baseData,
        observation: [separateMessages["stringBoxes"]],
      };
      break;
    case SEPARATED_SALES_SECTION_TYPES.COMPLEMENTARY_EQUIPMENT:
      formattedSection = {
        ...baseData,
        observation: [
          separateMessages["stringBoxes"],
          separateMessages["others"],
        ],
        categories: getFormattedProductCategories() || [],
      };
      break;
    default:
      break;
  }
  return formattedSection;
};

const getFormattedCustomSection = (section) => {
  return {
    description: section.name,
    type: section.id,
    categories: section.categories,
    observation: [],
    isCustom: true,
  };
};

const getFormattedProductCategories = () => {
  return (store.getters["storeFlow/products/itemsComplementaryEquipment"] || [])
    ?.map((item) => ({
      name: item.product.category.name,
      id: item.product.category.id,
    }))
    ?.filter(
      (obj, index, self) => index == self.findIndex((v) => v.id == obj.id)
    );
};

const state = {
  filter: {
    type: "",
    category: "all",
  },
  sections: [],
};

const mutations = {
  ["SET_SECTIONS"](state, payload = []) {
    state.sections = payload;
  },
  ["SET_FILTER_TYPE"](state, payload) {
    state.filter.type = payload;
  },
  ["SET_FILTER_CATEGORY"](state, payload) {
    state.filter.category = payload;
  },
};

const actions = {
  async readSections({ commit }) {
    const ret = await User.readSeparatedSalesSections();
    if (ret.error) return;
    commit("SET_SECTIONS", ret.data);
  },
};

const getters = {
  getSections: (state) =>
    state.sections
      .map((section) => {
        if (section.isDefault) {
          return getFormattedDefaultSection(section);
        } else {
          return getFormattedCustomSection(section);
        }
      })
      ?.filter((h) => !!h?.type) || [],
  getFilterType: (state) => state.filter.type,
  getFilterCategory: (state) => state.filter.category,
  getFilterSectionCategories: (state, getters) =>
    getters.getSections?.find(
      (section) => section.type == getters.getFilterType
    )?.categories || [],
  getAvailableSections: (state, getters) => {
    return getters.getSections?.filter(
      (section) =>
        !section.isCustom ||
        getFormattedProductCategories()?.some((category) =>
          section?.categories?.some((sc) => sc.id == category.id)
        )
    );
  },
  getAvailableSectionCategories: (state, getters) => {
    const categories = getters.getFilterSectionCategories?.filter((category) =>
      getFormattedProductCategories()?.some(
        (productCategory) => productCategory.id == category.id
      )
    );
    if (categories.length) {
      return [{ name: "Todos", id: "all" }, ...categories] || [];
    }
    return [];
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
