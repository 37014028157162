import InputFacade from "vue-input-facade";
import Vue from "vue";

Vue.use(InputFacade, {
  name: "mask",
  tokens: {
    "#": { pattern: /\d/ },
    A: { pattern: /[a-z]/i },
    N: { pattern: /[0-9a-z]/i },
    X: { pattern: /./ },
    "\\": { escape: true },
  },
});
