import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { checkPermissions, getInitialPage } from "@/utils/utils";
import Open from "./Open";

import Equipments from "@/router/Equipments";
import Settings from "@/router/Settings";
import Menu from "@/router/Menu";
import Sales from "@/router/Sales";
import PiedAdmin from "@/router/PiedAdmin";
import Ecommerce from "@/router/Ecommerce";
import { verifyBuildTimestamp } from "@/request/http";
import CRM from "@/router/CRM";

Vue.use(VueRouter);

const routes = () => {
  return [
    ...Open,
    ...PiedAdmin,
    {
      path: "/interactiveProposal/:leadToken/:proposalId",
      name: "interactiveProposal",
      meta: {
        title: "Proposta Interativa",
        permissions: ["open"],
      },
      component: () => import("@/views/proposal/InteractiveProposal.vue"),
    },
    ...Menu,
    ...Sales,
    ...Equipments,
    ...Settings,
    ...Ecommerce,
    {
      path: "/info/:type",
      name: "UserInfo",
      meta: {
        title: "Meus Dados",
        local: ["userMenu"],
        permissions: ["admin", "staff", "integrator"],
      },
      component: () => import("@/views/users/UserDetail.vue"),
    },
    ...CRM,
  ];
};

const router = new VueRouter({
  routes: routes(),
});

const isBackOfficeUser = (user) => {
  return user?.distribuitor?.marketplace && user?.type === "admin";
};

const isRestrict = (restrictList = [], routeNames = []) => {
  return restrictList.some((routeName) => routeNames.includes(routeName));
};

router.onError((error) => {
  if (error?.message?.match(/chunk/g)) {
    window.location.reload();
  }
});

router.beforeEach(async (to, from, next) => {
  store.commit("breadcrumb/RESET_BREADCRUMB");
  const user = store.getters["userData/getUserData"];
  const theme = store.getters["theme/getTheme"] || {};

  if (store.getters["userData/newBuildDetected"]) {
    await verifyBuildTimestamp(store.getters["userData/newBuildDetected"]);
  }

  if (
    isBackOfficeUser(user) &&
    isRestrict(
      [
        "Equipments",
        "PrecificationSettings",
        "RecomendationsSettings",
        "DeliverySettings",
        "StructuresSettings",
      ],
      to.matched.map(({ name }) => name)
    )
  ) {
    return next(getInitialPage(user?.type));
  }

  let actionCheck = true;
  if (to.meta?.actions?.length) {
    actionCheck = to.meta.actions.reduce((acc, currentAction) => {
      let cur = user?.actions?.[currentAction];
      if (acc != null) return acc && cur;
      return cur;
    }, null);
  }

  let permissionCheck = true;
  let hasPermissionCheck =
    (user?.type === "integrator" && to.meta?.integratorPermissions) ||
    (user?.type === "staff" && to.meta?.staffPermissions) ||
    [];

  if (hasPermissionCheck.length) {
    permissionCheck = checkPermissions(
      to.meta?.staffPermissions,
      to.meta?.integratorPermissions,
      user,
      to.meta?.onePermissionNeeded
    );
  }

  const isOpenRoute = to.meta?.permissions?.indexOf("open") !== -1;

  if (!isOpenRoute && !user) {
    return next({ name: "Login" });
  } else if (
    to.name &&
    (isOpenRoute ||
      (to.meta?.permissions?.indexOf(user?.type) !== -1 && permissionCheck)) &&
    actionCheck
  ) {
    if (user?.type === "integrator")
      Vue.prototype.$bus.$emit(
        "advertising:open",
        to.name !== "DashboardIntegrator",
        to.name
      );

    document.title = to.meta?.title
      ? `${theme.title || ""} | ${to.meta.title}`
      : theme.title || "";

    if (to.name !== "Login") {
      const description = document.createElement("meta");
      description.setAttribute("name", "robots");
      description.setAttribute("content", "noindex,nofollow");
      document.head.append(description);
    }

    await store.dispatch("tracking/PAGE_CHANGE", { to, from });

    return next();
  } else {
    return next(getInitialPage(user?.type));
  }
});

export default router;
