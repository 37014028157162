import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import Crown from "@/assets/Crown.vue";
import IconKitsFechados from "@/assets/IconKitsFechados.vue";
import IconKitsPersonalizados from "@/assets/IconKitsPersonalizados.vue";
import IconProdutosAvulsos from "@/assets/IconProdutosAvulsos.vue";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    themes: {
      light: {
        accent: "#307fe2",
        anchor: "#06c",
        error: "#9d031e",
        info: "#579DFF",
        primary: "#ff2a54",
        secondary: "#ff7d2a",
        success: "#198F3A",
        warning: "#FFC107",
        // DEFAULT PIED
        background_: "#F8F9FA",
        button: "#ff2a54",
        colorOneLinearGradient: "#8800FF",
        colorTwoLinearGradient: "#BC70FF",
        complement: "#787877",
        clear: "#0857c3",
        contentColor: "#494949",
        darkness: "#01297f",
        extra: "#995f29",
        icon: "#845621",
        light: "#307fe2",
        list: "#fafafa",
        notify_: "#ff0000",
        selectedItemSideBar: "#fff",
        shadow: "#0032a0",
        tertiary: "#307fe2",
        text_: "#494949",
        text2_: "#9c9c9c",
        black: "#000",
        green_: "#63cb11",
        orange_: "#ff8a00",
        orange2_: "#ff7d2a",
        orange3_: "#ffd1b3",
        pink_: "#ff2a54",
        yellow_: "#e7ff1b",
        white: "#fff",
        lightgrey: "#e4e4e4",
      },
    },
    options: { customProperties: true },
  },
  icons: {
    values: {
      crown: { component: Crown },
      kitsFechados: { component: IconKitsFechados },
      kitsPersonalizados: { component: IconKitsPersonalizados },
      produtosAvulsos: { component: IconProdutosAvulsos },
    },
  },
});
