import BaseFilter from "./BaseFilter";

export default class ProductClassFilterKit extends BaseFilter {
  constructor() {
    super({
      minPower: null,
      maxPower: null,
      isToSell: [],
      minPrice: null,
      maxPrice: null,
      productCode: null,
      name: null,
      model: null,
    });
  }
}
