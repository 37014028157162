import BaseFilter from "@/components/plataform/products/class/filter/BaseFilter";
export const SAVE_FILTER = "SAVE_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";
export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const RESET_FILTER_COLUMN = "RESET_FILTER_COLUMN";

const state = {
  pageName: null,
  filter: {},
  original: {},
  changes: [],
  hasChanges: false,
  pagination: {
    page: 1,
    itemsPerPage: 50,
  },
};

const mutations = {
  [SET_PAGE_NAME](state, pageName) {
    state.pageName = pageName;
  },
  /**
   * @param state
   * @param {BaseFilter} entity
   */
  [SAVE_FILTER](state, entity) {
    const { changes, hasChanges } = entity.comparator;
    state.filter = entity.filter;
    state.original = entity.baseFilter;
    state.changes = changes;
    state.hasChanges = hasChanges;
    state.pagination = entity.pagination;
  },
  [UPDATE_PAGINATION](state, pagination) {
    state.pagination = {
      ...state.pagination,
      ...pagination,
    };
  },
  [RESET_FILTER](state, pageName = null) {
    state.pageName = pageName;
    state.filter = {};
    state.original = {};
    state.changes = [];
    state.hasChanges = false;
    state.pagination = {
      page: 1,
      itemsPerPage: 50,
    };
  },
};

const getters = {
  pageName: (state) => state.pageName,
  changes: (state) => state.changes,
  hasChanges: (state) => state.hasChanges,
  filter: (state) => state.filter,
  original: (state) => state.original,
  pagination: (state) => state.pagination,
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
