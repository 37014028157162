import { FreightCalculation } from "./FreightCalculation";
import { PrecificationCalculation } from "./PrecificationCalculation";
import { RecommendationCalculation } from "./RecommendationCalculation";

export class DistributorFormulas {
  _recommendationCalculation = RecommendationCalculation;
  _precificationCalculation;

  constructor(data) {
    this._precificationCalculation = new PrecificationCalculation(
      data.precification
    );
  }

  calculateExtraItemsPrices() {
    return this._precificationCalculation.calculateExtraItemsPrices();
  }

  calculateMultipleProductsPrices() {
    return this._precificationCalculation.calculateMultipleProductsPrices();
  }

  calculateCompanyServicePrice(payload) {
    return this._precificationCalculation.calculateCompanyServicePrice(payload);
  }

  async calculateRecommendations() {
    return this._recommendationCalculation.calculateRecommendations();
  }

  calculateFreight(data) {
    return FreightCalculation.calculateFreight(data);
  }

  async calculateMultipleKitPrices() {
    return this._precificationCalculation.calculateMultipleKitPrices();
  }
}
