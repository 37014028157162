import BaseFilter from "./BaseFilter";
export default class ProductClassFilterStringBox extends BaseFilter {
  constructor() {
    super({
      isToSellOptions: [],
      manufacturer: [],
      minPrice: null,
      maxPrice: null,
      productCode: null,
      inputs: null,
      outputs: null,
      name: null,
      model: null,
    });
  }
}
