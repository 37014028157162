<template>
  <div class="nav-bar-header-logo" :style="localStyle">
    <img
      :src="logoSrc"
      alt="logo"
      style="cursor: pointer; align-self: center"
      width="100%"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { baseURL } from "@/request/http";

export default {
  props: {
    customLogo: {
      type: String,
      default: undefined,
    },
    gradientColor1: {
      type: String,
      default: "var(--v-colorOneLinearGradient-base)",
    },
    gradientColor2: {
      type: String,
      default: "var(--v-colorTwoLinearGradient-base)",
    },
  },
  data() {
    return {
      createDate: new Date().getTime(),
    };
  },
  computed: {
    ...mapState("theme", ["mini"]),
    ...mapGetters("theme", {
      theme: "getTheme",
    }),
    companyLogo() {
      return this.mini
        ? this.theme.companyTheme.smallLogo
        : this.theme.companyTheme.logo;
    },
    logoSrc() {
      return this.customLogo
        ? this.customLogo
        : `${baseURL}${this.companyLogo}?w=sm&q=${this.createDate}`;
    },
    localStyle() {
      return {
        background: `linear-gradient(0.25turn, ${this.gradientColor1}, ${this.gradientColor2})`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar-header-logo {
  content: "";
  display: flex;
  justify-content: center;
  height: 166px;
  max-width: 210px;
  margin: 0 20px 32px 20px;
  padding: 16px;
  border-radius: 0 0 10px 10px;
}
</style>
