export const state = {
  allLeads: [],
  leadsTableInfo: {
    page: 1,
    itemPerPage: 50,
    pageCount: 0,
  },

  lead: {
    _id: "",
    token: "",
    status: "",
    name: "",
    surname: "",
    email: "",
    note: "",
    cpf: "",
    cnpj: "",
    address: {
      CEP: "",
      patio: "",
      city: "",
      neighborhood: "",
      state: "",
      number: "",
      complement: "",
    },
    responsible: {
      id: "",
      name: "",
      surname: "",
      email: "",
      responsible: "",
      integratorRole: "",
    },

    systems: [],
    proposals: [],
    requests: [],
    tasks: [],
    observations: [],
    activities: [],
    customFields: [],
    origin: "",
    otherOrigin: "",
  },

  sizing: {
    proposal: {
      group: null,
      subGroup: null,
      tariffType: null,
    },
  },
};
