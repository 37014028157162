<template>
  <div>
    <v-overlay :value="loading" color="white" opacity="0.8" z-index="1000">
      <transition appear name="fade">
        <LoadingStyle />
      </transition>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingStyle from "./LoadingStyle.vue";
export default {
  components: { LoadingStyle },
  name: "LoadingPage",
  computed: {
    ...mapState({
      loading: (state) => state.loading.loading,
    }),
  },
};
</script>

<style></style>
