<template>
  <PiedDialog v-model="show" width="495px" hide-header @close="close">
    <template #body>
      <v-img
        v-if="imageOnePath"
        :src="imageOnePath"
        max-height="360px"
        max-width="480px"
        contain
        position="center"
      />
      <h3 class="mt-3">{{ title }}</h3>
      <pre style="overflow: auto">{{ description }}</pre>
    </template>
    <template #actions>
      <v-btn color="button" outlined @click="close"> Fechar </v-btn>
    </template>
  </PiedDialog>
</template>

<script>
import { baseURL } from "@/request/http";
import PiedDialog from "@/components/global/PiedDialog.vue";
import DialogMixin from "@/components/mixins/DialogMixin";

export default {
  components: { PiedDialog },
  mixins: [DialogMixin],
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  computed: {
    title() {
      return this.item?.title;
    },
    description() {
      return this.item?.description;
    },
    imageOnePath() {
      return `${baseURL}${this.item?.imageOnePath}`;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
