import { FormulaHelper } from "./FormulaHelper";
import { FormulaSupport } from "./FormulaSupport";
import store from "@/store";
import { User } from "@/request";
import {
  PRECIFICATION_CHARGE_TYPE,
  PURCHASE_TYPES,
  PURCHASE_TYPE_SEPARATED_SALE,
} from "@/utils/constants";

export class PrecificationCalculation {
  conditions = {
    module: [],
    inverter: [],
    stringBox: [],
    complementaryEquipment: [],
    extraItem: [],
    companyService: [],
    closedKits: [],
  };

  constructor(precification) {
    const conditions = (precification?.conditions || []).map((item) => {
      try {
        return {
          condition: FormulaSupport.getFunctionFromString(item.condition).bind(
            PrecificationCalculation.formulaFunctions
          ),
          trueSentence: FormulaSupport.getFunctionFromString(
            item.trueSentence
          ).bind(PrecificationCalculation.formulaFunctions),
          productType: item.productType,
        };
      } catch (e) {
        User.errorReport(
          "Erro ao transformar formulas",
          "precification",
          {},
          e
        );
      }
    });
    conditions.forEach((cond) => {
      if (this.conditions[cond.productType])
        this.conditions[cond.productType].push(cond);
    });
  }

  calculateCompanyServicePrice(payload) {
    const variables = {
      estadoFaturamento: FormulaHelper.parseStrVar(
        payload.additionalInfo.sameAddress
          ? payload.additionalInfo?.shippingAddress?.state
          : payload.additionalInfo?.invoiceAddress?.state
      ),
      cidadeFaturamento: FormulaHelper.parseStrVar(
        payload.additionalInfo.sameAddress
          ? payload.additionalInfo?.shippingAddress?.city
          : payload.additionalInfo?.invoiceAddress?.city
      ),
      cidadeEntrega: FormulaHelper.parseStrVar(
        payload.additionalInfo?.shippingAddress?.city
      ),
      estadoEmpresa: FormulaHelper.parseStrVar(
        payload.integratorAddress?.state
      ),
      precoServico: FormulaHelper.parseNumVar(
        payload.additionalInfo.companyServiceValue
      ),
    };

    return PrecificationCalculation.calculateSinglePrice(
      variables,
      this.conditions["companyService"],
      variables.precoServico
    );

    // TODO: reenable this part to enable calculation in back-end
    // const res = await User.calculateCompanyServicePrice(variables);
    // if (res.error) return  service.precoServico
    // return res.data
  }

  calculateExtraItemsPrices() {
    const additionalInfo = store.getters["storeFlow/checkout/additionalInfo"];

    const baseVariables = {
      estadoFrete: FormulaHelper.parseStrVar(
        store.getters["storeFlow/checkout/stateForCalculation"]
      ),
      estadoEmpresa: FormulaHelper.parseStrVar(
        store.getters["storeFlow/checkout/integratorAddress"]?.state
      ),
      tipoVenda: FormulaHelper.parseStrVar(
        store.getters["storeFlow/purchaseType"] ===
          PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE]
          ? "avulso"
          : "kit"
      ),
      QtdInversores: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalInvertersAmount"]
      ),
      QtdModulos: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalModulesAmount"]
      ),
      QtdStringbox: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalStringBoxAmount"]
      ),
      QtdOutros: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalComplementaryEquipmentAmount"]
      ),
      potenciaKit: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalModulesPower"]
      ),
      valorEquipamentos: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalProductsPrice"]
      ),
      valorFrete: FormulaHelper.parseNumVar(
        store.getters["storeFlow/checkout/freightPrice"]
      ),
      cidadeFaturamento: FormulaHelper.parseStrVar(
        additionalInfo.sameAddress
          ? additionalInfo?.shippingAddress?.city
          : additionalInfo?.invoiceAddress?.city
      ),
      cidadeEntrega: FormulaHelper.parseStrVar(
        additionalInfo?.shippingAddress?.city
      ),
      temInscricaoEstadual: additionalInfo?.temInscricaoEstadual || "nao",
      perfilIntegrador: FormulaHelper.parseStrVar(
        store.getters["storeFlow/checkout/integratorInfo"].role
      ),
    };

    const extraItems = store.getters["storeFlow/checkout/extraItems"].map(
      (item) => ({
        productCode: item.productCode || item.name,
        price: FormulaHelper.parseNumVar(
          isNaN(item.basePrice) ? item.price : item.basePrice
        ),
      })
    );

    for (const extraItem of extraItems) {
      const variables = {
        ...baseVariables,
        codigoProduto: FormulaHelper.parseStrVar(extraItem.productCode),
        precoBase: extraItem.price,
      };

      const newPrice = PrecificationCalculation.calculateSinglePrice(
        variables,
        this.conditions["extraItem"],
        variables.precoBase
      );

      if (typeof newPrice === "number" && newPrice !== extraItem.price) {
        extraItem.price = newPrice;
      }
    }

    return extraItems;

    // TODO: reenable this part to enable calculation in back-end
    // const res = await User.calculateExtraItemPrices({...baseVariables, extraItems})
    // if (res.error) return  []
    // return res.data
  }

  async calculateMultipleKitPrices() {
    const additionalInfo = store.getters["storeFlow/checkout/additionalInfo"];

    let priceChange = false;
    const allKits = store.getters["storeFlow/products/kits"];
    const productIds = allKits
      .map((item) => item.id)
      .filter((v, i, a) => v && a.indexOf(v) === i);

    let state = store.getters["storeFlow/checkout/stateForCalculation"];
    const baseVariables = {
      estado: FormulaHelper.parseStrVar(state),
      temInscricaoEstadual: additionalInfo.temInscricaoEstadual || "nao",
      tipoFaturamento: additionalInfo.chargeType,
      perfilIntegrador: FormulaHelper.parseStrVar(
        store.getters["storeFlow/checkout/integratorInfo"].role
      ),
      kits: productIds.map((id) => {
        const kit = allKits.find((k) => k.id == id);
        return {
          id: kit.id,
          price: kit.basePrice,
          amount: kit.amount,
          centerName: kit.centerName,
        };
      }),
      cidadeFaturamento: FormulaHelper.parseStrVar(
        additionalInfo.sameAddress
          ? additionalInfo?.shippingAddress?.city
          : additionalInfo?.invoiceAddress?.city
      ),
      cidadeEntrega: FormulaHelper.parseStrVar(
        additionalInfo?.shippingAddress?.city
      ),
    };

    const pastVariables =
      store.getters["storeFlow/products/pastKitPriceVariables"];

    const pastKits = pastVariables?.kits || [];
    const noNewKitsOrCenter = baseVariables.kits.every((kit) => {
      return !!pastKits.find(
        (pKit) => pKit.id === kit.id && pKit.centerName === kit.centerNme
      );
    });

    if (
      [
        baseVariables.kits.length > 0,
        // Check if Variables changed
        [
          pastVariables?.estado !== baseVariables.estado,
          pastVariables?.temInscricaoEstadual !==
            baseVariables.temInscricaoEstadual,
          !noNewKitsOrCenter,
        ].some((item) => item === true),
      ].every((item) => item === true)
    ) {
      const ret = await User.calculateMultipleKitPrices(baseVariables);
      if (!ret.error && ret.data?.length)
        for (const kit of allKits) {
          const newPrice = ret.data.find(
            (item) => item.id === kit.id
          )?.singlePrice;
          if (typeof newPrice === "number" && newPrice !== kit.price) {
            store.commit("storeFlow/products/setProductPrice", {
              item: kit,
              price: newPrice,
            });
            priceChange = true;
          }
        }
    }

    store.commit("storeFlow/products/setPastKitPriceVariables", {
      pastKitPriceVariables: baseVariables,
    });

    return priceChange;
  }

  calculateMultipleProductsPrices() {
    const allProducts = store.getters["storeFlow/products/allProducts"].map(
      (product) => ({
        amount: product.amount,
        productCode: product.productCode,
        price: product.price,
        precoBase: product.basePrice,
        type: product.type,
        centroDistribuicao: FormulaHelper.parseStrVar(product.centerName),
        fasesInversor: FormulaHelper.parseStrVar(product.tipo_ligacao),
        categoria: FormulaHelper.parseStrVar(product.category?.name),
        codigoProduto: FormulaHelper.parseStrVar(product.productCode),
        codigoNCM: FormulaHelper.parseStrVar(product.ncm),
        origemEquipamento: FormulaHelper.parseStrVar(product.origin),
      })
    );

    const additionalInfo = store.getters["storeFlow/checkout/additionalInfo"];
    const state = store.getters["storeFlow/checkout/stateForCalculation"];

    const baseVariables = {
      estado: FormulaHelper.parseStrVar(state),
      potenciaKit: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalModulesPower"]
      ),
      QtdInversores: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalInvertersAmount"]
      ),
      QtdModulos: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalModulesAmount"]
      ),
      QtdOutros: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalComplementaryEquipmentAmount"]
      ),
      QtdStringbox: FormulaHelper.parseNumVar(
        store.getters["storeFlow/products/totalStringBoxAmount"]
      ),
      contemCodigo: allProducts
        .filter((item) => item.amount > 0)
        .map((item) => FormulaHelper.parseStrVar(item.productCode)),
      cidadeFaturamento: FormulaHelper.parseStrVar(
        additionalInfo.sameAddress
          ? additionalInfo?.shippingAddress?.city
          : additionalInfo?.invoiceAddress?.city
      ),
      cidadeEntrega: FormulaHelper.parseStrVar(
        additionalInfo?.shippingAddress?.city
      ),
      tipoFaturamento:
        PRECIFICATION_CHARGE_TYPE?.[additionalInfo.chargeType] || "",
      tipoVenda:
        store.getters["storeFlow/purchaseType"] ===
        PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE]
          ? "avulso"
          : "kit",
      temInscricaoEstadual: additionalInfo?.temInscricaoEstadual || "nao",
      perfilIntegrador: FormulaHelper.parseStrVar(
        store.getters["storeFlow/checkout/integratorInfo"].role
      ),
      selectedObjects: allProducts
        .filter((item) => item.amount > 0)
        .map(({ amount, productCode }) => ({
          amount,
          productCode,
        })),
    };

    for (const product of allProducts) {
      const {
        amount: _amount,
        productCode: _productCode,
        price: _price,
        ...productVariables
      } = product;

      const variables = {
        ...baseVariables,
        ...productVariables,
      };

      const newPrice = PrecificationCalculation.calculateSinglePrice(
        variables,
        this.conditions[product.type],
        product.precoBase
      );

      if (typeof newPrice === "number" && newPrice !== product.price) {
        product.price = newPrice;
      }
    }
    return allProducts;
  }

  static calculateSinglePrice(variables, conditions = [], defaultReturn = 0) {
    for (const itemCondition of conditions) {
      try {
        const condition = itemCondition.condition;
        if (condition && condition(variables)) {
          const price = itemCondition.trueSentence(variables);
          return Math.round(price * 100) / 100;
        }
      } catch (e) {
        User.errorReport(
          "formulas.precifications",
          "calculateSinglePrice",
          {},
          e
        );
        return defaultReturn;
      }
    }
    return defaultReturn;
  }
  static formulaFunctions = {
    // Needs to mirror itself from backend
    decimalVerificationFunction: (a, b, c, d) => {
      const remainder = (a % b) / b;
      switch (c) {
        case ">":
          return remainder > d ? 1 : 0;
        case ">=":
          return remainder >= d ? 1 : 0;
        case "<":
          return remainder < d ? 1 : 0;
        case "<=":
          return remainder <= d ? 1 : 0;
        case "==":
          return remainder == d ? 1 : 0;
        case "!=":
          return remainder != d ? 1 : 0;
        default:
          throw new Error(
            `decimalVerificationFunction possui um comparador desconhecido: ${c}`
          );
      }
    },
    mod: (a, b) => {
      return a % b;
    },
    quociente: (a, b) => {
      return Math.floor(a / b);
    },
    qtdprodutos: (list, ...vars) => {
      return vars
        .map((item) => item.replace(/\"|'/g, ""))
        .reduce((acc, cur) => {
          return (
            (list.filter(
              (item) =>
                item.productCode.replace(/\"|'/g, "").toLowerCase() == cur
            )?.[0]?.amount || 0) + acc
          );
        }, 0);
    },
    switch: (comparator, ...vars) => {
      if (!vars?.length) return 0;
      if (vars.length) {
        let length = vars.length;
        let check = false;
        return vars.reduce((acc, cur, idx, src) => {
          if (acc !== 0 || check) return acc;
          else if (!(idx % 2) && comparator === cur && length >= idx) {
            check = true;
            return src[idx + 1];
          } else if (idx === length - 1 && !(idx % 2) && acc === 0) return cur;
          return 0;
        }, 0);
      }
    },
  };
}
