import { Integrator } from "@/request";

const state = {
  tasks: [],

  leads: [],
};

const mutations = {
  READ_ALL_TASKS(state, payload) {
    state.tasks = payload;
  },

  CREATE_TASK(state, payload) {
    state.tasks.unshift(payload.task[0]);
  },

  UPDATE_TASK(state, payload) {
    state.tasks.forEach((task) => {
      if (task.id === payload.task.id) Object.assign(task, payload.task);
    });
  },

  UPDATE_TASK_FINISHED(state, payload) {
    state.tasks.forEach((task) => {
      if (task.id === payload.task.id) task.finished = payload.task.finished;
    });
  },

  DELETE_TASK(state, payload) {
    state.tasks = state.tasks.filter((task) => task.id !== payload.taskId);
  },
};

const actions = {
  async readTasksFromSchedule(context, payload) {
    try {
      const ret = await Integrator.readTasks(payload);
      if (!ret.error && ret.data)
        context.commit("READ_ALL_TASKS", ret.data.tasks);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async createTaskFromSchedule(context, payload) {
    try {
      const ret = await Integrator.createTask(payload);
      if (!ret.error && ret.data) context.commit("CREATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFromSchedule(context, payload) {
    try {
      const ret = await Integrator.updateTask(payload);
      if (!ret.error && ret.data) context.commit("UPDATE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async updateTaskFinishedFromSchedule(context, payload) {
    try {
      const ret = await Integrator.updateTaskFinished(
        payload.taskId,
        payload.finished,
        payload.leadId
      );
      if (!ret.error && ret.data)
        context.commit("UPDATE_TASK_FINISHED", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },

  async deleteTaskFromSchedule(context, payload) {
    try {
      const ret = await Integrator.deleteTask(payload.taskId, payload.leadId);
      if (!ret.error && ret.data) context.commit("DELETE_TASK", ret.data);
      return ret;
    } catch (e) {
      throw e.response || e;
    }
  },
};

const getters = {
  getAllTasks: (state) =>
    state.tasks.map((item) => {
      return {
        ...item,
        start: new Date(item.start).getTime(),
        end: new Date(item.end).getTime(),
      };
    }),

  getTaskById: (state) => (taskId) =>
    state.tasks.find((item) => item.id === taskId),

  getDayTasks: (state) => (day) => {
    let arrayDay = day.split("-"),
      dayStart = new Date(
        arrayDay[0],
        Number(arrayDay[1]) - 1,
        arrayDay[2],
        0,
        0,
        0
      ),
      dayEnd = new Date(
        arrayDay[0],
        Number(arrayDay[1]) - 1,
        arrayDay[2],
        23,
        59,
        59
      );
    return state.tasks
      .filter(
        (item) =>
          new Date(item.start) <= dayEnd && new Date(item.end) >= dayStart
      )
      .sort((a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
