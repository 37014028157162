import { User } from "@/request";
import {
  MARKETPLACE_CATEGORIES,
  STATUS_COLOR_FINISHED,
  STATUS_COLOR_NEW,
  STATUS_COLOR_REFUSED,
} from "./constants";

const STATUS_TO_BLOCK_EDITION = {
  DEFAULT: {
    value: "defaultNotRestrictRequest",
    name: "Padrão (Sem Bloqueio)",
  },
};

const getMarketplaceStatus = (request) => {
  let marketplaceStatus = null;

  if (request.requestStatus) {
    return request.requestStatus.category;
  }

  switch (request.mainStatus) {
    case "refused":
    case "lost":
      marketplaceStatus = MARKETPLACE_CATEGORIES.find(
        (category) => category.value === "refused"
      )?.value;
      break;
    default:
      marketplaceStatus = request.mainStatus;
      break;
  }
  return marketplaceStatus;
};

const getDistributorStatus = (request) => {
  let status = request.mainStatus ?? request.status;

  if (request.requestStatus) {
    status = request.requestStatus.id;
  }

  if (status === "new" && request.marketplace) {
    status = "inAnalysis";
  }

  return status;
};

const getBudgetStatus = (customStatus = []) => {
  const budgetDefaultStart = [
    { name: "Novo", id: "new", color: STATUS_COLOR_NEW },
  ];

  const budgetDefaultEnd = [
    { name: "Finalizado", id: "finish", color: STATUS_COLOR_FINISHED },
    { name: "Recusado", id: "refused", color: STATUS_COLOR_REFUSED },
  ];

  let budgetStatus = [...budgetDefaultStart, ...budgetDefaultEnd];

  if (customStatus.length) {
    budgetStatus = [
      ...budgetDefaultStart,
      ...customStatus,
      ...budgetDefaultEnd,
    ];
  }

  return budgetStatus.map((item) => {
    return {
      name: item.name,
      value: item.id ? item.id : item.value,
      category: item.category || null,
      color: item.color,
    };
  });
};

const getMarketplaceStatusForBackOffice = (customStatus = []) => {
  const categories = [
    ...new Set(
      customStatus.map((status) => {
        if (status.category) return status.category;
      })
    ),
  ];

  const defaulStatusStart = [
    { name: "Análise Pieta", id: "new", color: STATUS_COLOR_NEW },
  ];
  const defaulStatusEnd = [
    { name: "Finalizado", id: "finish", color: STATUS_COLOR_FINISHED },
    { name: "Recusado", id: "refused", color: STATUS_COLOR_REFUSED },
  ];

  const requestStatus = MARKETPLACE_CATEGORIES.filter(
    (category) =>
      categories.includes(category.value) && category.value !== "inAnalysis"
  );

  defaulStatusStart.forEach((item) => {
    if (!categories.includes(item.id)) {
      requestStatus.unshift(item);
    }
  });

  defaulStatusEnd.forEach((item) => {
    if (!categories.includes(item.id)) {
      requestStatus.push(item);
    }
  });

  return requestStatus.map((item) => {
    return {
      name: item.text ? item.text : item.name,
      value: item.id ? item.id : item.value,
      category: item.category ? item.category : item.value,
      color: item.color,
    };
  });
};

const getMarketplaceStatusForDistributor = (customStatus = []) => {
  const categories = [
    ...new Set(
      customStatus.map((status) => {
        if (status.category) return status.category;
      })
    ),
  ];

  const defaulStatusStart = [
    { name: "Novo", id: "new", color: STATUS_COLOR_NEW },
  ];
  const defaulStatusEnd = [
    { name: "Finalizado", id: "finish", color: STATUS_COLOR_FINISHED },
    { name: "Recusado", id: "refused", color: STATUS_COLOR_REFUSED },
  ];

  defaulStatusStart.forEach((item) => {
    if (!categories.includes(item.id)) {
      customStatus.unshift(item);
    }
  });

  defaulStatusEnd.forEach((item) => {
    if (!categories.includes(item.id)) {
      customStatus.push(item);
    }
  });

  return customStatus.map((item) => {
    return {
      name: item.name,
      value: item.id ? item.id : item.value,
      category: item.category ? item.category : item.value,
      color: item.color,
    };
  });
};

const changeNewStatusToInAnalysis = (customStatus = []) => {
  return customStatus.map((item) => {
    if (item.value === "new") {
      item.value = "inAnalysis";
      item.name = "Análise Pieta";
    }
    return item;
  });
};

const getRequestStatus = (
  customStatus = [],
  { isMarketplace, isBackOfficeUser }
) => {
  if (isMarketplace && isBackOfficeUser) {
    customStatus = getMarketplaceStatusForBackOffice(customStatus);
  } else if (isMarketplace && !isBackOfficeUser) {
    customStatus = changeNewStatusToInAnalysis(
      getMarketplaceStatusForDistributor(customStatus)
    );
  } else {
    customStatus = getMarketplaceStatusForDistributor(customStatus);
  }

  customStatus = customStatus.filter((obj, index) => {
    return index === customStatus.findIndex((o) => obj.value === o.value);
  });

  return customStatus.map((item, index) => {
    return {
      ...item,
      sequence: index + 1,
    };
  });
};

const disableStatusOptions = (customStatus = [], request, isBackOfficeUser) => {
  const getStatusLabel = (request) => {
    if (request.requestStatus) {
      return request.requestStatus.id;
    }
    return request.mainStatus;
  };

  let requestStatus = customStatus.map((status) => {
    const category = status.category || status.value;
    const disabled =
      request.moves?.disallowedTo.includes(status.value) ||
      request.moves?.disallowedTo.includes(category) ||
      request.moves?.disallowedFrom.includes(request.mainStatus) ||
      status.value === getStatusLabel(request);

    return { ...status, category, disabled };
  });

  if (isBackOfficeUser) {
    requestStatus.forEach((status) => {
      status.value = status.category;
      status.disabled = true;
    });

    if (request.mainStatus === "inAnalysis" || request.mainStatus === "new") {
      const firstStatus = requestStatus.find(
        (status) =>
          status.category !== "new" && status.category !== "inAnalysis"
      );

      const refusedStatus = requestStatus.find(
        (status) => status.category === "refused" || status.value === "refused"
      );

      if (firstStatus) firstStatus.disabled = false;
      if (refusedStatus) refusedStatus.disabled = false;
    }
  }
  return [...new Set(requestStatus)];
};

const isBlockedToEdit = (customStatus = [], request, statusToBlockEdition) => {
  if (statusToBlockEdition === STATUS_TO_BLOCK_EDITION.DEFAULT.value) {
    return false;
  }

  const requestStatusNow = (request) => {
    if (request.mainStatus !== "requestStatus") {
      return request.mainStatus;
    }
    return request.requestStatus?.id || request.requestStatus;
  };

  const requestStatus = requestStatusNow(request);

  if (statusToBlockEdition) {
    const indexToBlock = customStatus.findIndex(
      (status) => status.value === statusToBlockEdition
    );
    const indexRequestStatus = customStatus.findIndex(
      (status) => status.value === requestStatus
    );
    if (indexRequestStatus >= indexToBlock) return true;
  }

  return false;
};

const getDistributorCustomStatus = async (
  type = "budget",
  distributorId = null
) => {
  try {
    const response = await User.readRequestStatus({
      type,
      distributorId,
    });
    if (!response.error) {
      return (response.data || [])
        .sort((a, b) => (a.sequence < b.sequence ? -1 : 1))
        .map((item) => {
          return {
            name: item.name,
            value: item.id,
            category: item.category || null,
            color: item.color,
          };
        });
    }
  } catch (e) {
    console.error("error", e.message);
    return [];
  }
};

export {
  getBudgetStatus,
  getRequestStatus,
  getDistributorCustomStatus,
  getDistributorStatus,
  getMarketplaceStatus,
  disableStatusOptions,
  isBlockedToEdit,
  STATUS_TO_BLOCK_EDITION,
};
