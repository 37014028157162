import { User } from "@/request";
import {
  getRequestFinalModifiedValue,
  calculateFinalRequestValue,
  applyPaymentCondition,
} from "@/utils/utils";
import {
  PURCHASE_TYPES,
  PURCHASE_TYPE_CLOSED_KIT,
  PURCHASE_TYPE_CUSTOM_KIT,
  PURCHASE_TYPE_KIT_EXPRESS,
  PURCHASE_TYPE_SEPARATED_SALE,
  STATES_UF,
} from "@/utils/constants";
import { generateUUID } from "@/utils/StringUtils";
import { InvoiceCalculation } from "@/domain/invoice/InvoiceCalculation";

const getDefaultBaseInfo = () => {
  return {
    eventType: "",
    activeForm: false,
    showFreight: false,
    requestName: "",
    checkoutFile: [],
    pastAnswersCustomForm: [],
    selectedOptionInvoiceValue: null,
    tipoPessoaFisicaOuJuridica: "jurídica",
    activeDialogRoutes: false,
    coupon: null,
    identification: {
      session: "",
      page_name: "",
      url: "",
    },
    freight: {
      state: "",
      price: 0,
      timeToDeliver: 30,
      integrationsData: {},
    },
    valueModifier: {
      increase: {
        amount: 0,
        type: "percentage",
      },
      decrease: {
        amount: 0,
        type: "percentage",
      },
    },
    integratorInfo: {
      companyName: "",
      email: "",
      cnpj: "",
      cpf: "",
      telephone: "",
      ie: "",
      defaultDiscount: 0,
      integratorAddress: null,
      role: "",
    },
    additionalInfo: {
      shippingDistributor: null,
      chargeType: "",
      shippingAddress: null,
      timeRestrictionBoolean: null,
      timeRestriction: "",
      ruralZone: "",
      truckPossible: null,
      baseInvoiceValue: "",
      changeInvoice: false,
      invoiceValue: "",
      companyServiceValue: "",
      userOrCompanyName: "",
      cpf: "",
      cnpj: "",
      rg: "",
      temInscricaoEstadual: "",
      ie: "",
      telephone: "",
      email: "",
      sameAddress: null,
      invoiceAddress: null,
      answersCustomForm: [],
    },
    payment: {
      type: "",
      condition: "",
    },
  };
};
const state = {
  extraItems: [],
  customForm: [],
  configCheckoutFile: [],
  deliveryBlacklist: [],
  deliveryConditions: [],
  deliveryVariables: [],
  isToGetDeliveryInfo: true,
  onlyGetCityAndState: false,
  invoiceRestriction: "sameState",
  unableDeliveryFob: false,
  uploadedFiles: [],
  checkoutPayment: false,
  paymentMethods: [],
  //...getDefaultAdditionalInfo(),
  ...getDefaultBaseInfo(),
};

const mutations = {
  ["setExtraItems"](state, payload) {
    state.extraItems = payload.extraItems;
  },
  ["setCheckoutPayment"](state, payload) {
    state.checkoutPayment = payload.checkoutPayment;
  },
  ["setPaymentMethods"](state, payload) {
    state.paymentMethods = payload.paymentMethods;
  },
  ["setCustomForm"](state, payload) {
    state.customForm = payload.customForm;
  },
  ["setCheckoutFile"](state, payload) {
    state.checkoutFile = payload;
  },
  SET_CONFIG_CHECKOUT_FILE(state, payload) {
    state.configCheckoutFile = payload;
  },
  ["setEventType"](state, payload) {
    state.eventType = payload.eventType;
  },
  ["setDeliveryInfo"](state, payload) {
    state.deliveryBlacklist = payload.deliveryBlacklist;
    state.isToGetDeliveryInfo = payload.isToGetDeliveryInfo;
    state.onlyGetCityAndState = payload.onlyGetCityAndState;
    state.unableDeliveryFob = payload.unableDeliveryFob;
    state.invoiceRestriction = payload.invoiceRestriction;
  },
  ["setDeliveryConditions"](state, payload) {
    state.deliveryConditions = payload.deliveryConditions;
    state.deliveryVariables = payload.deliveryVariables;
  },
  ["resetForm"](state) {
    Object.assign(state, getDefaultBaseInfo());
    state.extraItems.forEach((i) => (i.amount = 0));
  },
  ["setBaseInvoiceValue"](state, payload) {
    state.additionalInfo.baseInvoiceValue = payload.baseInvoiceValue;
  },
  ["setFreight"](state, payload) {
    state.showFreight = true;
    state.freight = payload.freight;
  },
  ["setSelectedOptionInvoiceValue"](state, payload) {
    state.selectedOptionInvoiceValue = payload.selectedOptionInvoiceValue;
    state.additionalInfo.companyServiceValue = "";
  },
  ["setActiveForm"](state, payload) {
    state.activeForm = payload.activeForm;
  },
  ["setExtraItemAmount"](state, { item, amount, basePrice }) {
    item.amount = amount;
    if (typeof basePrice == "number") item.basePrice = basePrice;
  },
  ["setAdditionalInfoData"](state, payload) {
    Object.assign(state.additionalInfo, payload);
  },
  ["setPaymentData"](state, payload) {
    Object.assign(state.payment, payload);
  },
  ["setExtraInfoPrice"](state, payload) {
    payload.extraItem.price = payload.price;
  },
  ["changeDataCustomForm"](state, payload) {
    const index = state.additionalInfo.answersCustomForm.findIndex(
      (p) => p.customForm === payload.customForm
    );
    if (index < 0) {
      state.additionalInfo.answersCustomForm.push(payload);
    } else {
      state.additionalInfo.answersCustomForm[index] = payload;
    }
  },
  ["setPastAnswersCustomForm"](state, payload) {
    state.pastAnswersCustomForm = payload.pastAnswersCustomForm;
  },
  ["setRequestName"](state, payload) {
    state.requestName = payload.requestName;
  },
  ["setTipoPessoaFisicaOuJuridica"](state, payload) {
    const resetDocumentValue = {
      física: () => Object.assign(state.additionalInfo, { cnpj: "" }),
      jurídica: () => Object.assign(state.additionalInfo, { cpf: "" }),
    };

    state.tipoPessoaFisicaOuJuridica = payload.tipoPessoaFisicaOuJuridica;
    if (
      payload.tipoPessoaFisicaOuJuridica &&
      resetDocumentValue[payload.tipoPessoaFisicaOuJuridica]
    ) {
      resetDocumentValue[payload.tipoPessoaFisicaOuJuridica]();
    }
  },
  ["setActiveDialogRoutes"](state, payload) {
    state.activeDialogRoutes = payload.activeDialogRoutes;
  },
  ["setValueModifier"](state, payload) {
    state.valueModifier.increase.amount = payload?.increase?.amount || 0;
    state.valueModifier.increase.type = payload?.increase?.type || "percentage";
    state.valueModifier.decrease.amount = payload?.decrease?.amount || 0;
    state.valueModifier.decrease.type = payload?.decrease?.type || "percentage";
  },
  ["pushExtraItem"](state, product) {
    state.extraItems.push(product);
  },
  ["setUploadedFiles"](state, payload) {
    state.uploadedFiles = [...payload.uploadedFiles.filter((file) => file)];
  },
  ["setCoupon"](state, payload) {
    state.coupon = payload;
  },
  SET_IDENTIFICATION(state, { pageName, url }) {
    state.identification.session = generateUUID();
    state.identification.page_name = pageName;
    state.identification.url = url;
  },
};

const actions = {
  async checkoutLoad({ dispatch, commit, rootGetters, getters }) {
    commit("resetForm");

    const getCheckouParams = async (retry = 0) => {
      const ret = await User.readCheckoutLoad(
        rootGetters["storeFlow/purchaseType"]
      );
      if (ret.error && retry < 3) {
        retry++;
        console.warn("Erro ao carregar checkout, tentando novamente", retry);
        return getCheckouParams(retry);
      }
      return ret;
    };

    const ret = await getCheckouParams();

    if (!rootGetters["storeFlow/isStaff"]) await dispatch("readIntegratorInfo");
    if (ret.error) return;

    commit("setExtraItems", {
      extraItems:
        ret.data?.extraItems?.map((item) => {
          return {
            ...item,
            basePrice: item.price,
            productCode: item.productCode || item.name,
            amount: 0,
          };
        }) || [],
    });
    commit("setCustomForm", { customForm: ret.data?.customForm || [] });
    dispatch("setConfigCheckoutFile", ret.data?.configCheckoutFile);
    commit("setCheckoutPayment", {
      checkoutPayment: ret.data?.paymentInfo?.checkoutPayment || false,
    });

    const paymentMethods = ret.data?.paymentInfo?.paymentMethods || [];
    paymentMethods.sort((a, b) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    });

    commit("setPaymentMethods", { paymentMethods });

    let deliveryInfo = {};
    if (ret.data?.deliveryInfo?.deliveryBlacklist)
      Object.assign(deliveryInfo, {
        deliveryBlacklist: ret.data?.deliveryInfo?.deliveryBlacklist.map(
          (item) => item.toLowerCase()
        ),
      });

    if (typeof ret.data?.deliveryInfo?.isToGetDeliveryInfo === "boolean")
      Object.assign(deliveryInfo, {
        isToGetDeliveryInfo: ret.data?.deliveryInfo?.isToGetDeliveryInfo,
      });

    if (typeof ret.data?.deliveryInfo?.onlyGetCityAndState === "boolean")
      Object.assign(deliveryInfo, {
        onlyGetCityAndState: ret.data?.deliveryInfo?.onlyGetCityAndState,
      });

    if (typeof ret.data?.deliveryInfo?.unableDeliveryFob === "boolean")
      Object.assign(deliveryInfo, {
        unableDeliveryFob: ret.data?.deliveryInfo?.unableDeliveryFob,
      });

    if (ret.data?.deliveryInfo?.invoiceRestriction)
      Object.assign(deliveryInfo, {
        invoiceRestriction: ret.data?.deliveryInfo?.invoiceRestriction,
      });

    commit("setDeliveryInfo", deliveryInfo);
  },

  async resetForm({ dispatch, commit, getters, rootGetters }) {
    if (!rootGetters["storeFlow/editId"]) {
      commit("resetForm");
    }
    if (!rootGetters["storeFlow/isStaff"]) await dispatch("readIntegratorInfo");
  },

  async readIntegratorInfo({ commit, rootGetters }) {
    if (!rootGetters["userData/logged"]) return;

    const ret = await User.readCompanyInfo(rootGetters["storeFlow/integrator"]);

    if (!ret.error && ret.data) {
      let address = {};
      if (ret.data.address?.CEP) {
        Object.assign(address, ret.data.address);
      } else {
        Object.assign(address, ret.data.mainContact?.address);
      }

      let integratorInfo = {
        companyName: ret.data.companyName || ret.data.name || "",
        email: ret.data.mainContact?.email || "",
        cnpj: ret.data.cnpj || "",
        cpf: ret.data.cpf || ret.data.mainContact?.cpf || "",
        telephone: ret.data.mainContact?.cellphone || "",
        ie: ret.data.stateInscription || "",
        defaultDiscount: ret.data.role?.defaultDiscount || 0,
        integratorAddress: {
          CEP: address.CEP || "",
          state: address.state || "",
          city: address.city || "",
          neighborhood: address.neighborhood || "",
          patio: address.patio || "",
          complement: address.complement || "",
          number: address.number || "",
        },
        role: ret.data?.role?.name,
      };

      commit("storeFlow/setIntegratorInfo", { integratorInfo }, { root: true });
    }
  },

  async setDiscount(
    { dispatch, commit, getters, rootGetters },
    { valueModifier, coupon }
  ) {
    commit("setValueModifier", valueModifier);
    commit("setCoupon", coupon);

    commit("setBaseInvoiceValue", {
      baseInvoiceValue: Number(
        getters.totalValueBeforeModifiers + getters.discount
      ),
    });
  },

  async calculateValueInvoice({ commit, getters, rootGetters, dispatch }) {
    const paymentMethod = getters.paymentMethods?.find(
      (method) => method.type === getters.payment.type
    );

    const condition = paymentMethod?.conditions?.find(
      (condition) => condition._id === getters.payment.condition
    );
    if (getters.eventType !== "budgetToOrder") {
      let price = getters.totalValueBeforeModifiers;

      if (price) {
        if (
          getters.valueModifier.increase?.amount === 0 &&
          getters.valueModifier.decrease?.amount === 0 &&
          getters.defaultDiscount > getters.discount &&
          (!rootGetters["storeFlow/pastInfo"] ||
            rootGetters["requestEdit/editRequest"].isCopy)
        ) {
          commit("setValueModifier", {
            decrease: { amount: getters.defaultDiscount },
          });
        }
        commit("setBaseInvoiceValue", {
          baseInvoiceValue: applyPaymentCondition(
            Number(price + getters.discount),
            condition
          ),
        });
      }
    } else {
      const baseInvoiceValue =
        getters.budgetToOrderTotalValueWithoutPaymentCondition;
      commit("setBaseInvoiceValue", {
        baseInvoiceValue: applyPaymentCondition(baseInvoiceValue, condition),
      });
    }
  },

  async recalculateExtraItemsPrices({
    dispatch,
    commit,
    getters,
    rootGetters,
  }) {
    if (!rootGetters["storeFlow/products/formulasEntity"]) return;
    try {
      let recalculateInvoice = false;
      const extraItems = await rootGetters[
        "storeFlow/products/formulasEntity"
      ].calculateExtraItemsPrices();

      if (extraItems?.length) {
        getters.extraItems.forEach((extraItem) => {
          const productCode = extraItem.productCode || extraItem.name;
          const resItem = extraItems.find(
            (item) => item.productCode == productCode
          );
          if (
            resItem &&
            typeof resItem.price === "number" &&
            resItem.price != extraItem.price
          ) {
            commit("setExtraInfoPrice", { extraItem, price: resItem.price });
            recalculateInvoice = true;
          }
        });
      }

      if (recalculateInvoice) await dispatch("calculateValueInvoice");
    } catch (e) {
      await User.errorReport(
        "Erro ao calcular preço",
        "price",
        {
          state: getters.stateForCalculation,
          products: getters.extraItemsSelected.map((item) => item.name),
        },
        e
      );
    }
  },

  async calculateFreight(
    { dispatch, commit, getters, rootGetters, state },
    options = { uf: null, ref: null, city: null }
  ) {
    const products = (rootGetters["storeFlow/products/itemsCheckout"] || [])
      .map((p) => ({
        id: p.id,
        singlePrice: p.price,
        freeAmount: p.freeAmount,
        quantity: p.amount,
      }))
      .filter((item) => item.quantity > 0);

    const kits = [];

    for (const kit of rootGetters["storeFlow/products/kitsCheckout"] || []) {
      const kitProducts = kit.products || [];
      kits.push({ id: kit.id, name: kit.name, power: kit.power });
      for (const product of kitProducts) {
        products.push({
          id: product.id,
          quantity: product.amount,
          singlePrice: product.price,
          freeAmount: 0,
          kitId: kit.id,
        });
      }
    }

    if (
      getters.eventType === "editFreight" &&
      rootGetters["storeFlow/purchaseType"] ===
        PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]
    ) {
      for (const product of rootGetters["storeFlow/pastInfo"]?.products || []) {
        if (
          [
            "module",
            "inverter",
            "stringBox",
            "complementaryEquipment",
          ].includes(product.type)
        )
          products.push({
            id: product.id,
            quantity: product.amount,
            singlePrice: product.price,
            freeAmount: 0,
          });
      }
    }

    if (
      !products.length ||
      ![
        "ruralZoneUpdate",
        "shippingAddressForm",
        "truckPossible",
        "valueModifier",
      ].includes(options.ref)
    ) {
      return;
    }

    commit("storeFlow/setLoading", true, { root: true });

    let total = rootGetters["storeFlow/products/totalProductsPrice"];
    if (getters.eventType === "budgetToOrder") {
      let editRequest = rootGetters["requestEdit/editRequest"];
      editRequest = editRequest.products.filter((p) => p.type !== "extraItem");

      total = editRequest.reduce((acc, cur) => acc + cur.totalPrice, 0);
    }

    const stateForCalculation = options?.uf || getters.stateForCalculation;
    const cityForCalculation = options?.city || getters.cityForCalculation;
    const data = {
      acessoCarreta: state.additionalInfo?.truckPossible ? "sim" : "nao",
      cidadeEntrega: options?.city
        ? options?.city
        : state.additionalInfo?.shippingAddress?.city,
      state: stateForCalculation.toLowerCase(),
      tipoZona: (state.additionalInfo.ruralZone || "urbana").toLowerCase(),
      payment_type: !state.additionalInfo?.shippingDistributor ? "FOB" : "CIF",
      destination_zip_code: state.additionalInfo?.shippingAddress?.CEP,
      identification: state.identification,
      valorTotal: total,
      products,
      kits,
      tipoCidade: STATES_UF.filter(
        (item) =>
          item.sigla === stateForCalculation.toUpperCase() &&
          item.capital === cityForCalculation
      ).length
        ? "capital"
        : "interior",
      valorTotalAtualizado: calculateFinalRequestValue(
        total,
        getters.valueModifier
      ),
    };
    const res = await rootGetters[
      "storeFlow/products/formulasEntity"
    ].calculateFreight(data);

    if (!res?.error && !res?.data?.error) {
      commit("setFreight", {
        freight: {
          state: stateForCalculation,
          price: res.data.price,
          timeToDeliver: res.data.timeToDeliver,
          integrationData: res.data.integrationData,
        },
      });
      await dispatch("calculateValueInvoice");
    } else {
      commit("storeFlow/setLoading", false, { root: true });
      return res.data?.error || res.error;
    }

    commit("storeFlow/setLoading", false, { root: true });
  },

  async recalculateCompanyServiceInvoice({ commit, getters, rootGetters }) {
    if (
      getters.additionalInfo.changeInvoice &&
      getters.selectedOptionInvoiceValue
    ) {
      const invoiceCalculation = new InvoiceCalculation();
      const paymentMethod = getters.paymentMethods?.find(
        (method) => method.type === getters.payment.type
      );
      const condition = paymentMethod?.conditions?.find(
        (condition) => condition._id === getters.payment.condition
      );

      const invoiceValue =
        await invoiceCalculation.calculateCompanyServiceInvoice({
          additionalInfo: getters.additionalInfo,
          condition,
          integratorAddress:
            rootGetters["storeFlow/checkout/integratorAddress"],
        });

      commit("setAdditionalInfoData", { invoiceValue });
    }
  },

  async setAdditionalInfoData(
    { dispatch, commit, getters, rootGetters },
    additionalInfo
  ) {
    commit("setAdditionalInfoData", additionalInfo);
    for (let key of Object.keys(additionalInfo)) {
      if (key === "deliveryState") {
        commit("setAdditionalInfoData", {
          deliveryState: additionalInfo[key],
        });
        commit("storeFlow/products/setProductsCenters", additionalInfo[key], {
          root: true,
        });
        await dispatch("storeFlow/products/recalculatePrices", null, {
          root: true,
        });
      }

      if (key === "shippingDistributor") {
        if (additionalInfo[key]) {
          // frete = CIF
          commit("setAdditionalInfoData", {
            timeRestrictionBoolean: null,
            timeRestriction: "",
            sameAddress: null,
            truckPossible: null,
            ruralZone: "",
          });
        } else {
          // frete = FOB
          commit("setAdditionalInfoData", {
            shippingAddress: null,
            sameAddress: false,
            timeRestrictionBoolean: false,
            timeRestriction: "",
            truckPossible: false,
            ruralZone: "",
          });
        }
      } else if (key === "chargeType") {
        if (additionalInfo[key] === "company") {
          if (getters.integratorAddress) {
            await dispatch("loadInvoiceAddress", getters.integratorAddress);
          }

          if (getters.integratorInfo.cnpj !== "") {
            commit("setTipoPessoaFisicaOuJuridica", {
              tipoPessoaFisicaOuJuridica: "jurídica",
            });
          } else if (getters.integratorInfo.cpf !== "") {
            commit("setTipoPessoaFisicaOuJuridica", {
              tipoPessoaFisicaOuJuridica: "física",
            });
          }

          commit("setAdditionalInfoData", {
            userOrCompanyName: getters.integratorInfo.companyName || "",
            email: getters.integratorInfo.email || "",
            cnpj: getters.integratorInfo.cnpj || "",
            cpf: getters.integratorInfo.cpf || "",
            telephone: getters.integratorInfo.telephone || "",
            ie: getters.integratorInfo.ie || "",
          });

          await dispatch("storeFlow/products/recalculatePrices", null, {
            root: true,
          });
        } else {
          await dispatch("loadInvoiceAddress", {
            CEP: "",
            state: "",
            city: "",
            neighborhood: "",
            patio: "",
            complement: "",
            number: "",
          });
          let update = {};
          if (
            getters.additionalInfo.userOrCompanyName ===
            getters.integratorInfo.companyName
          )
            update["userOrCompanyName"] = "";
          if (getters.additionalInfo.cnpj === getters.integratorInfo.cnpj)
            update["cnpj"] = "";
          if (getters.additionalInfo.cpf === getters.integratorInfo.cpf)
            update["cpf"] = "";
          if (
            getters.additionalInfo.telephone ===
            getters.integratorInfo.telephone
          )
            update["telephone"] = "";
          if (getters.additionalInfo.email === getters.integratorInfo.email)
            update["email"] = "";
          commit("setAdditionalInfoData", update);
        }
      } else if (key === "timeRestrictionBoolean") {
        commit("setAdditionalInfoData", { timeRestriction: "" });
      } else if (key === "sameAddress") {
        if (additionalInfo[key])
          commit("setAdditionalInfoData", { invoiceAddress: null });
        else {
          if (getters.additionalInfo.chargeType === "company") {
            await dispatch("loadInvoiceAddress", {
              CEP: getters.integratorAddress.CEP || "",
              state: getters.integratorAddress.state || "",
              city: getters.integratorAddress.city || "",
              neighborhood: getters.integratorAddress.neighborhood || "",
              patio: getters.integratorAddress.patio || "",
              complement: getters.integratorAddress.complement || "",
              number: getters.integratorAddress.number || "",
            });
          }
          if (getters.additionalInfo.chargeType === "lead") {
            await dispatch("loadInvoiceAddress", {
              CEP: "",
              state: "",
              city: "",
              neighborhood: "",
              patio: "",
              complement: "",
              number: "",
            });
          }
        }
        await dispatch("storeFlow/products/recalculatePrices", null, {
          root: true,
        });
      } else if (key === "companyServiceValue") {
        await dispatch("recalculateCompanyServiceInvoice");
      } else if (key === "changeInvoice") {
        if (
          !additionalInfo[key] &&
          getters.editableInvoceService &&
          getters.editableInvoceTotal
        ) {
          commit("setSelectedOptionInvoiceValue", {
            selectedOptionInvoiceValue: null,
          });
        }

        commit("setAdditionalInfoData", {
          invoiceValue: getters.additionalInfo.baseInvoiceValue,
          companyServiceValue: "",
        });
      } else if (key === "ie" || key == "temInscricaoEstadual") {
        if (getters.eventType !== "budgetToOrder") {
          await dispatch("storeFlow/products/recalculatePrices", null, {
            root: true,
          });
        }
      } else if (key == "ruralZone") {
        await dispatch("calculateFreight", { ref: "ruralZoneUpdate" });
      } else if (key === "truckPossible") {
        await dispatch("calculateFreight", { ref: "truckPossible" });
      }
    }
  },

  async setSelectedOptionInvoiceValue(
    { dispatch, commit, getters, rootGetters },
    payload
  ) {
    commit("setSelectedOptionInvoiceValue", payload);
    if (typeof payload.selectedOptionInvoiceValue === "boolean") {
      commit("setAdditionalInfoData", {
        invoiceValue: getters.additionalInfo.baseInvoiceValue,
        companyServiceValue: "",
      });
    }
  },

  async loadInvoiceAddress(
    { dispatch, commit, getters, rootGetters },
    address
  ) {
    if (!getters.additionalInfo.sameAddress) {
      commit("setAdditionalInfoData", { invoiceAddress: address });
    }
  },

  async newAddress(
    { dispatch, commit, getters, rootGetters },
    { address, type }
  ) {
    let newAddress = !!Object.values(address).reduce((a, b) => a + b)
      ? { ...address }
      : null;

    if (type === "shipping") {
      commit("setAdditionalInfoData", { shippingAddress: newAddress });

      if (
        address &&
        address.state &&
        address.state !== getters["additionalInfo"]?.deliveryState
      ) {
        commit("setAdditionalInfoData", { deliveryState: newAddress?.state });
      }
    }

    if (type === "invoice") {
      commit("setAdditionalInfoData", { invoiceAddress: newAddress });
      await dispatch("recalculateCompanyServiceInvoice");
    }

    await dispatch("storeFlow/products/recalculatePrices", null, {
      root: true,
    });
  },

  // On buttons
  async openDialogSaveName(
    { dispatch, commit, getters, rootGetters },
    eventType
  ) {
    commit("setEventType", { eventType });

    if (eventType === "budgetToOrder" && rootGetters["requestEdit/ecommerce"]) {
      return dispatch("storeFlow/saveRequests", null, { root: true });
    }
    await dispatch("calculateValueInvoice");

    commit("setActiveForm", { activeForm: true });
    await dispatch("recalculateExtraItemsPrices");
  },

  async setExtraItemAmount(
    { dispatch, commit, getters, rootGetters },
    { item, amount }
  ) {
    commit("setExtraItemAmount", { item, amount });
    dispatch("recalculateExtraItemsPrices");
    dispatch("calculateValueInvoice");
  },

  initializeIdentification({ commit }, { pageName, url }) {
    commit("SET_IDENTIFICATION", { pageName, url });
  },

  setConfigCheckoutFile({ commit }, payload) {
    commit("SET_CONFIG_CHECKOUT_FILE", payload || []);
  },
};

const getters = {
  extraItems: (state) => state.extraItems,
  eventType: (state) => state.eventType,
  requestName: (state) => state.requestName,
  checkoutFile: (state) => state.checkoutFile,
  activeDialogRoutes: (state) => state.activeDialogRoutes,
  tipoPessoaFisicaOuJuridica: (state) => state.tipoPessoaFisicaOuJuridica,
  activeForm: (state) => state.activeForm,
  showFreight: (state, getters, rootState, rootGetters) =>
    state.showFreight &&
    (["admin"].indexOf(rootGetters["storeFlow/type"]) !== -1 ||
      rootState.integratorPermissions?.howToDisplayFreightValue ===
        "onSelection"),
  totalValueBeforeModifiers: (state, getters, rootState, rootGetters) => {
    let price = rootGetters["storeFlow/products/totalProductsPrice"];
    return price + getters.freightPrice + getters.totalExtraItemsPrice;
  },
  budgetToOrderTotalValueWithoutPaymentCondition: (
    _state,
    getters,
    _rootState,
    rootGetters
  ) => {
    const pastBaseInvoiceValue =
      rootGetters["requestEdit/editRequest"].totalValue.convertToNumber();
    const pastFreight =
      rootGetters["requestEdit/editRequest"]?.freight?.price || 0;
    const newFreight = getters.freightPrice || 0;
    const baseInvoiceValue = calculateFinalRequestValue(
      pastBaseInvoiceValue - pastFreight + newFreight,
      getters.valueModifier
    );
    return baseInvoiceValue;
  },

  discount: (state, getters) =>
    getRequestFinalModifiedValue(
      getters.totalValueBeforeModifiers,
      getters.valueModifier
    ),
  coupon: (state) => state.coupon,
  totalValue: (_state, getters) => {
    if (getters.eventType === "budgetToOrder") {
      return getters.budgetToOrderTotalValueWithoutPaymentCondition;
    }
    return getters.totalValueBeforeModifiers + getters.discount;
  },
  valueModifier: (state) => state.valueModifier,
  defaultDiscount: (state, getters) =>
    getters.hasDefaultDiscount &&
    (getters.integratorInfo?.defaultDiscount || 0),
  integratorInfo: (state, getters, rootState) =>
    rootState["storeFlow"].integratorInfo,
  freight: (state) => state.freight,
  configCheckoutFile: (state) =>
    state.configCheckoutFile.filter((item) => item.active),
  freightPrice: (state) => Number(state.freight?.price || 0),
  integratorAddress: (state, getters) =>
    getters.integratorInfo.integratorAddress,
  unableDeliveryFob: (state) => state.unableDeliveryFob,
  onlyGetCityAndState: (state, getters, rootState, rootGetters) =>
    state.onlyGetCityAndState &&
    (getters.eventType === "budget" ||
      (getters.eventType === "editFreight" &&
        rootGetters["storeFlow/pastInfo"]?.type == "budget")),
  checkoutPayment: (state) => state.checkoutPayment,
  paymentMethods: (state) => state.paymentMethods,
  deliveryBlacklist: (state) => state.deliveryBlacklist,
  deliveryConditions: (state) => state.deliveryConditions,
  deliveryVariables: (state) => state.deliveryVariables,
  isToGetDeliveryInfo: (state) => state.isToGetDeliveryInfo,
  invoiceRestriction: (state) => state.invoiceRestriction,

  pastAnswersCustomForm: (state) => state.pastAnswersCustomForm,

  customFormFirstStep: (state) =>
    state.customForm.filter((form) => form.screen == "firstStep"),
  customFormAfterShipping: (state) =>
    state.customForm.filter((form) => form.screen == "afterShipping"),
  customFormLastStep: (state) =>
    state.customForm.filter((form) => form.screen == "lastStep"),
  additionalInfo: (state) => state.additionalInfo,
  payment: (state) => state.payment,
  showRequestButton: (state, getters, rootState) =>
    rootState.integratorPermissions?.showRequestButton || false,
  showBudgetButton: (state, getters, rootState) =>
    rootState.integratorPermissions?.showBudgetButton || false,
  editableInvoceTotal: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableInvoceTotal || false,
  editableInvoceService: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableInvoceService || false,
  editableCompanyInvoceTotal: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableCompanyInvoceTotal || false,
  editableCompanyInvoceService: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableCompanyInvoceService || false,
  hasDefaultDiscount: (state, getters, rootState) =>
    rootState.integratorPermissions?.hasDefaultDiscount || false,

  extraItemAction: (state, getters, rootState) =>
    rootState.userData?.actions?.extraItem,
  customFormAction: (state, getters, rootState) =>
    rootState.userData?.actions?.customForm,
  extraItemsSelected: (state) => state.extraItems.filter((i) => i.amount > 0),
  totalExtraItemsPrice: (state, getters) =>
    getters.extraItemsSelected.reduce(
      (acc, cur) => acc + (Number(cur.amount) || 0) * (Number(cur.price) || 0),
      0
    ),
  editableInvoce: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableInvoce &&
    (getters.editableInvoceTotal || getters.editableInvoceService),
  editableCompanyInvoce: (state, getters, rootState) =>
    rootState.integratorPermissions?.editableCompanyInvoce &&
    (getters.editableCompanyInvoceTotal ||
      getters.editableCompanyInvoceService),
  extraItemsBeforeAdditionalData: (state, getters) =>
    state.extraItems.filter(
      (i) =>
        i.whereToDisplay === "beforeAdditionalData" &&
        (getters.eventType != "budgetToOrder" || i.amount > 0)
    ),
  extraItemsAfterShipping: (state, getters) =>
    state.extraItems.filter(
      (i) =>
        i.whereToDisplay === "afterShipping" &&
        (getters.eventType != "budgetToOrder" || i.amount > 0)
    ),

  blackListCheck: (state) =>
    !(
      state.additionalInfo.shippingDistributor &&
      state.additionalInfo.shippingAddress?.state &&
      (state.deliveryBlacklist || []).indexOf(
        String(state.additionalInfo?.shippingAddress?.state).toLowerCase()
      ) !== -1
    ),
  selectedOptionInvoiceValue: (state) => state.selectedOptionInvoiceValue,
  stateForCalculation: (state, getters, rootState, rootGetters) =>
    state.additionalInfo.shippingAddress?.state ||
    state.additionalInfo.deliveryState ||
    rootGetters["storeFlow/state"],
  cityForCalculation: (state) =>
    state.additionalInfo?.shippingAddress?.city || "",
  uploadedFiles: (state) => state.uploadedFiles,
  showPrice: (state, getters, rootState, rootGetter) => {
    const purchaseType = rootGetter["storeFlow/purchaseType"];
    const permission = rootState.integratorPermissions;

    if (purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]) {
      return ["onSelection", "onConfirmation"].includes(
        permission?.howToDisplayProductsPricesInMountKit
      );
    }

    if (purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_KIT_EXPRESS]) {
      return ["onSelection", "onConfirmation"].includes(
        permission?.howToDisplayProductsPricesInKitExpress
      );
    }

    if (purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE]) {
      return permission?.howToDisplayPricesInSeparateSale === "onSelection";
    }

    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
