<template>
  <div class="icon-style__sticky">
    <v-icon size="24px" :class="iconStyle" @click="onMenuBtnClick">
      {{ menuBarIcon }}
    </v-icon>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "ButtonOpenCloseNavBar",
  computed: {
    ...mapGetters("theme", ["isButtonOpenCloseNavBarHidden"]),
    ...mapState("theme", ["appBar", "mini", "isButtonOpenCloseNavBarHidden"]),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    menuBarIcon() {
      const valueToCheck = this.isMobile ? !this.appBar : this.mini;
      return valueToCheck ? this.$icons.CHEVRONRIGHT : this.$icons.CHEVRONLEFT;
    },
    iconStyle() {
      if (this.isMobile) {
        if (this.appBar)
          return "icon-style icon-style-mobile__position-expanded-appbar";
        return "icon-style";
      } else {
        if (this.isButtonOpenCloseNavBarHidden || !this.mini)
          return "icon-style icon-style__position-expanded-menu";
        return "icon-style icon-style__position-mini-menu";
      }
    },
  },

  created() {
    if (this.isMobile) {
      this.updateAppBar(false);
    }
  },

  methods: {
    ...mapActions("theme", ["updateAppBar", "updateMiniVariant"]),
    onMenuBtnClick() {
      if (this.isMobile) {
        this.updateAppBar(!this.appBar);
      } else {
        this.updateMiniVariant(!this.mini);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-style {
  border: solid var(--v-button-base) 1px;
  border-radius: 50%;
  color: var(--v-button-base);
  background-color: white;
  cursor: pointer;
  position: absolute;
}

.icon-style__position-expanded-menu {
  left: 237px;
}

.icon-style__position-mini-menu {
  left: 65px;
}

.icon-style-mobile__position-expanded-appbar {
  left: 237px;
}

.icon-style:hover {
  opacity: 0.75;
}

.icon-style__sticky {
  position: sticky;
  top: 30px;
  z-index: 7;
}
</style>
