import { Integrator } from "@/request";

const state = {
  sellerPermissions: {
    loaded: false,
    authSellerComplementaryValues: false,
    authSellerProfitMagin: false,
    authSellerDiscount: false,
    authSellerMaxDiscount: 0,
    authSellerViewRequestsBudgetsPrices: true,
  },
};

const mutations = {
  SET_COMPANY_PERMISSIONS(state, payload) {
    Object.assign(state.sellerPermissions, { ...payload, loaded: true });
  },
};

const actions = {
  async readPermissions(context) {
    const user = context.rootGetters["userData/getUserData"];

    if (context.state.sellerPermissions.loaded) return;
    if (!user) return;
    if (user.type === "integrator" && user.integratorRole === "seller") {
      const ret = await Integrator.readProposalSettings("permissions");
      if (!ret.error && ret.data)
        context.commit("SET_COMPANY_PERMISSIONS", ret.data[0].permissions);
    }
  },
  async getAuthSellerIntegratorViewRequestsBudgetsPrices({
    state,
    dispatch,
    rootGetters,
  }) {
    await dispatch["readPermissions"];

    const user = rootGetters["userData/getUserData"];
    if (!user) return false;
    if (user.type !== "integrator" || user.integratorRole !== "seller") {
      return true;
    }
    return state.sellerPermissions.authSellerViewRequestsBudgetsPrices ?? true;
  },
};

const getters = {
  getAuthSellerComplementaryValues: (state) =>
    state.sellerPermissions.authSellerComplementaryValues,
  getAuthSellerProfitMargin: (state) =>
    state.sellerPermissions.authSellerProfitMagin,
  getAuthSellerDiscount: (state) => state.sellerPermissions.authSellerDiscount,
  getAuthSellerMaxDiscount: (state) =>
    state.sellerPermissions.authSellerMaxDiscount,
  getAuthSellerViewRequestsBudgetsPrices: (state) =>
    state.sellerPermissions.authSellerViewRequestsBudgetsPrices,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
