import { baseHTTP, baseDownloadHTTP } from "../http";

export default {
  async downloadTerms() {
    return baseDownloadHTTP(
      "get",
      "Termos e Condições.pdf",
      true,
      `/app/integrator/download/terms`,
      null
    );
  },

  async readIntegratorUsers(payload) {
    let filteredPayload = payload
      ? Object.fromEntries(
          Object.entries(payload).filter(
            ([_, v]) =>
              (Array.isArray(v) && v.length > 0) ||
              (v !== "" && !Array.isArray(v))
          )
        )
      : {};
    let params = payload ? new URLSearchParams(filteredPayload).toString() : "";
    return baseHTTP("get", `/app/integrator/users/${params}`);
  },

  async checkCompanyUsersLimit() {
    return baseHTTP("get", "/app/integrator/users/check/limit");
  },

  async updateCompanyInfo(data) {
    return baseHTTP("put", `/app/integrator/company/info`, data);
  },

  async readIntegratorUserResponsabilities(id, dbModel) {
    return baseHTTP(
      "get",
      `/app/integrator/user/responsabilities/${id}/${dbModel}`
    );
  },

  async createIntegratorUser(data) {
    return baseHTTP("post", `/app/integrator/user`, data);
  },

  async updateIntegratorUser(data) {
    return baseHTTP("put", `/app/integrator/user`, data);
  },

  async deleteIntegratorUser(id) {
    return baseHTTP("delete", `/app/integrator/user/${id}`);
  },

  async exportNpsMetrics(id) {
    return baseDownloadHTTP(
      "get",
      `MetricasNPS-${id}.xlsx`,
      true,
      `/app/integrator/exportNpsMetrics/${id}`,
      null
    );
  },

  /******** INÍCIO *********
   ******* MÓDULO CRM *******
   **************************/

  async importLeads(file) {
    const formData = new FormData();
    formData.append("document", file);
    return baseHTTP("post", `/app/integrator/leads/import`, formData);
  },

  async exportLeads(fileName) {
    return baseDownloadHTTP(
      "get",
      fileName,
      true,
      `/app/integrator/leads/export`,
      null
    );
  },

  async createLead(data) {
    return baseHTTP("post", `/app/integrator/lead`, data);
  },

  async readLeads(store, page, limit, readAmount) {
    return baseHTTP(
      "get",
      `/app/integrator/leads/${store}/${page}/${limit}/${readAmount}`
    );
  },

  async readLead(leadId) {
    return baseHTTP("get", `/app/integrator/lead/${leadId}`);
  },

  async updateLead(data) {
    return baseHTTP("put", `/app/integrator/lead`, data);
  },

  async deleteLead(leadId) {
    return baseHTTP("delete", `/app/integrator/lead/${leadId}`);
  },

  async addLeadObservation(leadId, observation) {
    return baseHTTP("put", `/app/integrator/lead/observations/add`, {
      leadId,
      observation,
    });
  },

  async deleteLeadObservation(observationId, leadId) {
    return baseHTTP(
      "delete",
      `/app/integrator/lead/observations/delete/${observationId}/${leadId}`
    );
  },

  async readTasks(payload) {
    let filteredPayload = payload
      ? Object.fromEntries(
          Object.entries(payload).filter(
            ([_, v]) =>
              (Array.isArray(v) && v.length > 0) ||
              (v !== "" && !Array.isArray(v))
          )
        )
      : {};
    let params = payload ? new URLSearchParams(filteredPayload).toString() : "";
    return baseHTTP("get", `/app/integrator/tasks/${params}`);
  },

  async createTask(data) {
    return baseHTTP("post", "/app/integrator/task", data);
  },

  async updateTask(data) {
    return baseHTTP("put", `/app/integrator/task`, data);
  },

  async updateTaskFinished(taskId, finished, leadId) {
    return baseHTTP("put", "/app/integrator/task/finished", {
      taskId,
      finished,
      leadId,
    });
  },

  async deleteTask(taskId, leadId) {
    return baseHTTP("delete", `/app/integrator/task/${taskId}/${leadId || ""}`);
  },

  async createSystem(data) {
    return baseHTTP("post", "/app/integrator/system", data);
  },

  async readLeadSystem(leadId) {
    return baseHTTP("get", `/app/integrator/lead/systems/${leadId}`);
  },

  async readSystems(filterStatus) {
    return baseHTTP("get", `/app/integrator/systems/${filterStatus}`);
  },

  async updateSystem(data) {
    return baseHTTP("put", `/app/integrator/system`, data);
  },

  async updateSystemStatus(systemId, newStatus, leadId) {
    return baseHTTP("put", "/app/integrator/system/status", {
      systemId,
      newStatus,
      leadId,
    });
  },

  async updateLeadRequest(data) {
    return baseHTTP("put", `/app/integrator/request/lead`, data);
  },

  async deleteSystem(systemId, leadId) {
    return baseHTTP("delete", `/app/integrator/system/${systemId}/${leadId}`);
  },

  async createSystemCommentByStatus(systemId, status, comment, leadId) {
    return baseHTTP("put", "/app/integrator/system/addComment", {
      systemId,
      status,
      comment,
      leadId,
    });
  },

  async deleteSystemCommentByStatus(
    systemId,
    commentId,
    commentStatus,
    leadId
  ) {
    return baseHTTP(
      "delete",
      `/app/integrator/system/deleteComment/${systemId}/${commentId}/${commentStatus}/${leadId}`
    );
  },

  async createSizing(data) {
    return baseHTTP("post", "/app/integrator/sizing", data);
  },

  async readSizing(sizingId) {
    return baseHTTP("get", `/app/integrator/sizing/${sizingId}`);
  },

  async deleteSizing(leadId, sizingId, systemId) {
    return baseHTTP(
      "delete",
      `/app/integrator/sizing/${leadId}/${sizingId}/${systemId}`
    );
  },

  async readProposalSettings(cameFrom) {
    return baseHTTP("get", `/app/integrator/proposalSettings/${cameFrom}`);
  },

  async updateProposalSettingsCompanyInfo(proposalSettingsCompanyInfo) {
    return baseHTTP(
      "put",
      "/app/integrator/proposalSettings/companyInfo",
      proposalSettingsCompanyInfo
    );
  },

  async updateProposalSettingsPermissions(data) {
    return baseHTTP(
      "put",
      "/app/integrator/proposalSettings/permissions",
      data
    );
  },

  async createUpdateComplementaryValuesTable(data) {
    return baseHTTP(
      "put",
      "/app/integrator/proposalSettings/complementaryValuesTable",
      data
    );
  },

  async createUpdateComplementaryValuesBand(data) {
    return baseHTTP(
      "put",
      "/app/integrator/proposalSettings/complementaryValuesBand",
      data
    );
  },

  async createUpdateDefaultValuesBand(data) {
    return baseHTTP(
      "put",
      "/app/integrator/proposalSettings/defaultValuesBand",
      data
    );
  },

  async deleteComplementaryValuesTable(tableId) {
    return baseHTTP(
      "delete",
      `/app/integrator/proposalSettings/complementaryValuesTable/${tableId}`
    );
  },

  async deleteComplementaryValuesBand(tableId, bandId) {
    return baseHTTP(
      "delete",
      `/app/integrator/proposalSettings/complementaryValuesBand/${tableId}/${bandId}`
    );
  },

  async deleteDefaultValuesBand(tableKind, bandId) {
    return baseHTTP(
      "delete",
      `/app/integrator/proposalSettings/defaultValuesBand/${tableKind}/${bandId}`
    );
  },

  async calculateMonthlyGeneration(data) {
    return baseHTTP("post", "/app/integrator/monthlyGeneration", data);
  },

  async simulateFinancing(data) {
    return baseHTTP("put", "/app/integrator/financing/simulate", data);
  },

  async calculatePayback(data) {
    return baseHTTP("post", "/app/integrator/payback", data);
  },

  async updateInteractiveProposalHeader(image) {
    return baseHTTP(
      "put",
      `app/integrator/crmModule/interactiveProposalHeader`,
      image
    );
  },

  async readInteractiveProposalHeader() {
    return baseHTTP("get", `/app/integrator/interactiveProposalHeader`);
  },

  async createProposal(data) {
    return baseHTTP("post", "/app/integrator/proposal", data);
  },

  async readProposal(proposalId) {
    return baseHTTP("get", `/app/integrator/proposal/${proposalId}`);
  },

  async downloadProposalPdfOrDoc(proposalId, systemName, extension) {
    return baseDownloadHTTP(
      "get",
      `Proposta ${systemName}.${extension == "doc" ? "docx" : "pdf"}`,
      true,
      `/app/integrator/proposal/download/${proposalId}/${extension}`,
      null
    );
  },

  async deleteProposal(proposalId, leadId, systemId) {
    return baseHTTP(
      "delete",
      `/app/integrator/proposal/${proposalId}/${leadId}/${systemId}`
    );
  },

  async createLeadToken(data) {
    return baseHTTP("put", "/app/integrator/lead/token", data);
  },

  async readFrontendURL() {
    return baseHTTP("get", `/app/integrator/frontendUrl`);
  },

  async updateProposalLeadVisibility(data) {
    return baseHTTP("put", "/app/integrator/proposal/leadVisibility", data);
  },

  readActiveTerm() {
    return baseHTTP("get", "/app/integrator/terms-of-use");
  },

  acceptTerms(id) {
    return baseHTTP("post", `/app/integrator/terms/accept/${id}`);
  },

  async readTariffComponents(data) {
    return baseHTTP("post", `/app/integrator/tariff/complements`, data);
  },

  async exportTariffs(group) {
    return baseDownloadHTTP(
      "get",
      `Tariff${group}.xlsx`,
      true,
      `/app/integrator/tariff/export/${group}`,
      null
    );
  },

  async fetchPaymentMethods(query) {
    return baseHTTP("get", `/app/integrator/paymentMethods`, query);
  },

  async readProposalSettingsTemplates() {
    return baseHTTP("get", "/app/integrator/proposalSettingsTemplates");
  },

  async readDistributorEnabledIntegratorProposalTemplates() {
    return baseHTTP(
      "get",
      "/app/integrator/distributorEnabledIntegratorProposalTemplates"
    );
  },

  async importProposalSettingsTemplate(formData) {
    return baseHTTP(
      "post",
      `/app/integrator/import/proposalSettingsTemplate`,
      formData
    );
  },

  async testProposalSettingsTemplate(templateId, name) {
    return baseDownloadHTTP(
      "get",
      `Proposta ${name}.pdf`,
      true,
      `/app/integrator/testProposalSettingsTemplate/${templateId}`,
      null
    );
  },

  async updateDefaultProposalSettingsTemplate(data) {
    return baseHTTP(
      "put",
      `/app/integrator/defaultProposalSettingsTemplate`,
      data
    );
  },

  async updateProposalSettingsTemplateName(data) {
    return baseHTTP(
      "put",
      `/app/integrator/proposalSettingsTemplateName`,
      data
    );
  },

  async deleteProposalSettingsTemplate(templateId) {
    return baseHTTP(
      "delete",
      `/app/integrator/proposalSettingsTemplate/${templateId}`
    );
  },

  async downloadTemplate(templateId, name) {
    return baseDownloadHTTP(
      "get",
      `${name}.docx`,
      true,
      `/app/integrator/downloadTemplate/${templateId}`,
      null
    );
  },

  /********** FIM **********
   ******* MÓDULO CRM *******
   **************************/
};
