export class RegionsEntity {
  regions = [];

  centers = [];

  constructor(regions = []) {
    this.regions = regions;
    this.centers = regions?.[0]?.centerPriority || [];
  }

  get availableStates() {
    return this.regions.map((item) => item.states).flat();
  }

  getCenter(id) {
    return this.centers.find((center) => center.id === id);
  }

  getCenterOrderByState(state = "") {
    const region = this.regions.find(
      (region) => !state || region.states?.includes(state.toUpperCase())
    );
    return this.sortCenterAndReturnId(region);
  }

  sortCenterAndReturnId(region = {}) {
    const { centerPriority = [] } = region;

    return centerPriority
      .sort((a, b) => a.order - b.order)
      .map((item) => item.id);
  }
}
