import "moment/locale/pt-br";
import moment from "moment";

export function toDate(value) {
  const date =
    typeof value === "string" ? value.toString() : value?.toISOString();
  const [year, month, day] = date.match(/\d{2,4}/g);
  return `${day}/${month}/${year}`;
}

export function formatDate(date, format = "DD/MM/YYYY") {
  return moment(date).format(format);
}

export function dateToString(
  date,
  withoutTimeZone = false,
  location = "pt-br"
) {
  if (!date) return "";

  return stringToDate(date, withoutTimeZone).toLocaleString(location, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function timeToString(date, withoutTimeZone = false) {
  if (!date) return "";

  return stringToDate(date, withoutTimeZone)
    .toLocaleString("pt-br", {
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/(\d+)\/(\d+)/, "$1:$2");
}

export function stringToDate(date, withoutTimeZone = false) {
  if (!date) return "";

  let dateAux = new Date(date);

  if (isNaN(dateAux)) {
    const [day, month, year] = date.split("/");
    dateAux = new Date(`${year}-${month - 1}/${day}`);
  }

  if (!withoutTimeZone) return dateAux;

  return new Date(dateAux.toISOString().slice(0, -1));
}

export function stringToTime(time, withoutTimeZone = false) {
  if (!time) return "";

  const dateAux = dateToString(new Date(), false, "en-us");
  let timeAux = new Date(`${dateAux} ${time}`);

  if (!withoutTimeZone) return timeAux;

  return new Date(timeAux.toISOString().slice(0, -1));
}
