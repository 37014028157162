import { SYSTEM_STATUS_OBJECT } from "@/utils/constants";
import { formatWhatsAppContact, getResultSizingData } from "@/utils/utils";

export const getters = {
  getLeadId: (state) => state?.lead?._id,

  getLeadsTableInfo: (state) => state.leadsTableInfo,

  getAllLeads: (state) => {
    return state.allLeads.map((lead) => {
      return {
        nextTask: getNextTask(lead.tasks),
        formatedWhatsAppNumber: formatWhatsAppContact(lead.cellphone),
        formatedEmail: `mailto:${lead.email || ""}`,
        ...lead,
      };
    });
  },

  getLead: (state) => state.lead,

  getLeadActivities: (state) => {
    return state.lead.activities.map((item) => {
      return {
        ...item,
        formatedDateAndHour: `Às ${new Date(item.createdAt)
          .toBRTime()
          .slice(0, 5)} em ${new Date(item.createdAt).toBRDate()}`,
      };
    });
  },

  getLeadTasks: (state) => {
    return state.lead.tasks
      .map((item) => {
        return {
          ...item,
          formatedStartDate: new Date(item.start).toBRDate(),
          formatedEndDate: new Date(item.end).toBRDate(),
          formatedStartHour: new Date(item.start).toBRTime().slice(0, 5),
          formatedEndHour: new Date(item.end).toBRTime().slice(0, 5),
        };
      })
      .sort((a, b) => (a.start > b.start ? -1 : 1));
  },

  getLeadNextTask: (state) => getNextTask(state.lead.tasks) || {},

  getTaskById: (state) => (taskId) =>
    state.lead.tasks.find((item) => item.id === taskId),

  getLeadObservations: (state) => state.lead.observations,

  getLeadSystems: (state) => {
    return state.lead.systems.map((system) => {
      return {
        ...system,
        state: system.address.state,
        city: system.address.city,
        formatedStatus: SYSTEM_STATUS_OBJECT[system.status],
      };
    });
  },

  getCurrentSystem: (state) => (systemId) =>
    state.lead.systems.find((system) => system.id === systemId),

  getCurrentSystemCommentsByStatus: (state) => (systemId, selectedStatus) =>
    state.lead.systems
      .find((system) => system.id === systemId)
      .commentsByStatus.filter((comment) => comment.status === selectedStatus),

  getCurrentSystemSizings: (state) => (systemId) => {
    return (
      state.lead.systems
        .find((system) => system.id === systemId)
        .sizings.map((item) => {
          return {
            ...item,
            ...getResultSizingData(item),
          };
        })
        .sort((a, b) =>
          new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1
        ) || []
    );
  },

  getCurrentSystemProposals: (state) => (systemId) => {
    return (
      state.lead.systems.find((system) => system.id === systemId).proposals ||
      []
    );
  },

  getLeadProposals: (state) => {
    const proposals = [];
    for (let system of state.lead.systems) {
      const formatedProposals = system.proposals.map((item) => {
        return {
          ...item,
          systemName: system.name,
        };
      });
      proposals.push(...formatedProposals);
    }
    return proposals;
  },

  getCurrentSystemRequests: (state) => (systemId) => {
    return state.lead.requests.filter((request) => request.system === systemId);
  },

  getLeadBudgets: (state) =>
    state.lead.requests.filter((item) => item.type === "budget"),

  getLeadOrders: (state) =>
    state.lead.requests.filter((item) => item.type === "order"),

  getSizingProposal: (state) => state.sizing.proposal,
};

function getNextTask(tasksArray = []) {
  let nextTask = null,
    now = new Date(),
    futureTasks = [];

  if (tasksArray.length) {
    futureTasks = tasksArray.filter((task) => new Date(task.start) >= now);

    if (futureTasks.length) {
      nextTask = futureTasks.reduce((a, b) => (a.start <= b.start ? a : b));
    }
  }
  return nextTask;
}
