import { PiedAdmin } from "@/request";

const baseState = {
  items: [],
  total: {
    activeUsers: 0,
    maxUsers: 0,
    totalSize: 0,
  },
  last_modified: null,
};

const getters = {
  items: (state) => state.items,
  total: (state) => state.total,
  domains: (state) =>
    state.items
      .map((item) => [
        ...(item.piedFrontendSettings?.allowedDomains || []),
        ...(item.ecomFrontendSettings?.allowedDomains || []),
      ])
      .flat(),
};

const mutations = {
  set(state, payload) {
    state.items = payload.distribuitors;
    state.total = payload.meta;
    state.last_modified = new Date();
  },

  reset(state) {
    state = baseState;
  },
};

const actions = {
  async fetch({ commit }, filter = {}) {
    const { status = "accepted" } = filter;
    const ret = await PiedAdmin.readDistributors(status);
    if (ret.error) throw ret.error;
    commit("set", ret.data);
    return ret.data;
  },
};

export default {
  namespaced: true,
  state: baseState,
  getters,
  mutations,
  actions,
};
