import Icons from "@/constants/Icons";

export default [
  {
    path: "/equipments",
    name: "Equipments",
    redirect: "/equipments/modules",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.VIEWMODULE,
      title: "Equipamentos",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["equipment"],
      isGroup: true,
    },
    children: [
      {
        path: "modules",
        name: "Modules",
        meta: {
          type: "module",
          icon: Icons.VIEWMODULE,
          title: "Módulos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProducts.vue"),
      },
      {
        path: "inverters",
        name: "Inverters",
        meta: {
          type: "inverter",
          icon: Icons.INVERTER,
          title: "Inversores",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProducts.vue"),
      },
      {
        path: "stringbox",
        name: "StringBox",
        meta: {
          type: "stringBox",
          icon: Icons.ALPHA1,
          title: "String Box",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProducts.vue"),
      },
      {
        path: "products",
        name: "Products",
        meta: {
          type: "complementaryEquipment",
          icon: Icons.OTHERPRODUCT,
          title: "Outros Produtos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProducts.vue"),
      },
      {
        path: "kits",
        name: "Kits",
        meta: {
          type: "kit",
          icon: Icons.DOLLY,
          title: "Kits Fechados",
          local: ["navBar"],
          actions: ["enableClosedKits"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProducts.vue"),
      },
      {
        path: "historical/:type/:id",
        name: "historical",
        meta: {
          icon: Icons.HISTORIC,
          hiddenMenu: true,
          title: "Histórico",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/ProductsHistorical.vue"),
      },
      {
        path: "visualize/:type/:id?",
        name: "EquipmentsAddEdit",
        meta: {
          icon: Icons.VIEWMODULE,
          hiddenMenu: true,
          title: "Equipamentos",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageProductsAddEdit.vue"),
      },
      {
        path: "kits/automatic",
        name: "PageKitAutomatic",
        meta: {
          icon: Icons.DOLLY,
          hiddenMenu: true,
          title: "Kits Fechados",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["equipment"],
        },
        component: () => import("@/views/products/PageKitAutomatic.vue"),
      },
    ],
  },
];
