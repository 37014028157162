import { User } from "@/request";
import { DistributorFormulas } from "@/domain/formulas/Formulas";
import { PaymentConditionTypeEnum } from "@/constants/Payment";
import { applyPaymentCondition } from "@/utils/utils";
export class InvoiceCalculation {
  _formulas = null;

  async readFormulas() {
    const ret = await User.readDistributorProcessedFormulas();
    if (ret.error || !ret.data) return;
    this._formulas = new DistributorFormulas(ret.data);
  }

  async calculateCompanyServiceInvoice(payload) {
    if (payload.additionalInfo.changeInvoice) {
      if (!this._formulas) await this.readFormulas();
      let serviceValue = this._formulas.calculateCompanyServicePrice(payload);

      if (
        payload.condition?.conditionType === PaymentConditionTypeEnum.INCREASE
      ) {
        serviceValue = applyPaymentCondition(serviceValue, payload.condition);
      }

      const invoiceValue =
        serviceValue + payload.additionalInfo.baseInvoiceValue;

      return invoiceValue;
    }
  }
}
