import axios, { AxiosInstance } from "axios";
import { STATES_UF } from "@/utils/constants";

class BaseFindCep {
  /**
   * @type {AxiosInstance}
   */
  http = null;

  /**
   * @type {number}
   */
  attemps = 0;

  constructor(baseURL, headers = {}) {
    this.http = axios.create({
      baseURL,
      timeout: 5000,
    });

    Object.assign(this.http.defaults.headers, headers);
  }
}

class ViaCep extends BaseFindCep {
  constructor() {
    super("https://viacep.com.br/ws");
  }

  async execute(cep) {
    const response = await this.http.get(`${cep}/json`);

    if (response.status >= 400) {
      if (cep.slice(-3) !== "000") {
        return await this.execute(cep.substring(0, 5) + "000");
      }

      throw "invalid_cep";
    }

    const { uf, ibge, logradouro, bairro, erro } = response.data;
    if (erro) {
      return {
        erro,
        data: {},
      };
    }

    return {
      erro: false,
      data: { uf, ibge, logradouro, bairro },
    };
  }
}

class BrasilApi extends BaseFindCep {
  constructor() {
    super("https://brasilapi.com.br/api", {});
  }

  async execute(cep) {
    try {
      const response = await this.http.get(`cep/v1/${cep}`);

      this.attemps = 0;

      const {
        state: uf,
        city: ibge,
        neighborhood: bairro,
        street,
        errors,
      } = response.data;
      if (errors?.length) {
        throw errors[0];
      }
      const [logradouro] = street.split("-").map((h) => h.trim());

      return {
        erro: false,
        data: {
          uf,
          ibge,
          bairro,
          logradouro,
        },
      };
    } catch (e) {
      if (this.attemps === 0 && cep.slice(-3) !== "000") {
        this.attemps++;
        return await this.execute(cep.substring(0, 5) + "000");
      }

      this.attemps++;

      return {
        erro: true,
        data: e,
      };
    }
  }
}

class FindCepInStaticList {
  data = null;

  erro = "addressNotFound";

  execute(cep) {
    const cepRangeInput = Number(cep.slice(0, 5));
    const ordered = STATES_UF.sort(
      (a, b) => a.cepRanges[0][0] - b.cepRanges[0][0]
    );
    for (let i = 0; i < ordered.length; i++) {
      const { sigla, cepRanges = [] } = ordered[i];
      for (let j = 0; j < cepRanges.length; j++) {
        const [start = 0, end = 0] = cepRanges[j];

        const startNumber = Number(start);
        const endNumber = Number(end);

        if (cepRangeInput >= startNumber && cepRangeInput <= endNumber) {
          this.data = { uf: sigla };
          this.erro = false;
          return {
            data: this.data,
            erro: this.erro,
          };
        }
      }
    }

    return {
      data: this.data,
      erro: this.erro,
    };
  }
}

class FindAddressService {
  /**
   * @type {null|FindAddressService}
   */
  static instance = null;

  /**
   * @type {boolean|{data:{uf:string,ibge:string;logradouro:string;bairro:string;},erro:boolean}}
   */
  result = { erro: true, data: {} };

  error = null;

  /**
   * @type {string|null}
   */
  cep = null;

  static getInstance() {
    if (!FindAddressService.instance) {
      FindAddressService.instance = new FindAddressService();
    }

    return FindAddressService.instance;
  }

  reset() {
    this.cep = null;
    this.result = { erro: true, data: {} };
  }

  /**
   * @param {string} cep
   * @param {boolean} force
   */
  async search(cep, force = false) {
    const clients = [new BrasilApi(), new ViaCep()];
    if (this.result && this.cep === cep && !force) return this.result;
    this.cep = String(cep);

    for (const client of clients) {
      try {
        const data = await client.execute(cep);
        if (data.erro) {
          this.error = "addressNotFound";
          continue;
        }
        this.error = null;
        this.result = data;
        break;
      } catch (e) {
        this.error = "addressNotFound";
      }
    }

    if (this.error) {
      const findCepInStaticList = new FindCepInStaticList();
      this.result = findCepInStaticList.execute(cep);
    }

    return this.result;
  }
}

export default FindAddressService.getInstance();
