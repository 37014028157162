const state = {
  howToDisplayKitPriceInMountKit: "",
  howToDisplayKitPriceInKitExpress: "",
  howToDisplayPricesInClosedKits: "",
  howToDisplayPricesInSeparateSale: "",
  howToDisplayProductsPricesInMountKit: "",
  howToDisplayProductsPricesInKitExpress: "",
  howToDisplayFreightValue: "",
  howToDisplayPricesExtraItems: "",
  editableInvoce: false,
  showBudgetButton: true,
  showRequestButton: true,
  showSellerToIntegrator: false,
};

const mutations = {
  INTEGRATOR_PERMISSIONS(state, payload) {
    Object.assign(state, payload);
  },
};

export default {
  state,
  mutations,
};
