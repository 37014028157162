<template>
  <v-main app class="resize" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <router-view :class="routerViewClass" :key="$route.fullPath" />
  </v-main>
</template>

<script>
export default {
  computed: {
    routerViewClass() {
      const isRequestDetails = ["RequestsDetails", "BudgetsDetails"].includes(
        this.$route.name
      );
      const isOpenRoute = this.$route.meta?.permissions?.indexOf("open") > -1;

      return {
        "py-0": true,
        "px-0": isOpenRoute,
        "px-5":
          (isRequestDetails && this.$vuetify.breakpoint.smAndUp) ||
          (!isRequestDetails && !isOpenRoute),
      };
    },
  },
};
</script>
