import User from "@/request/routes/User";
import moment from "moment";

export const PaymentStatusEnum = {
  PENDING: "pending",
  FAILED: "failed",
  VOIDED: "voided",
  CANCELED: "canceled",
  PRE_AUTHORIZED: "pre_authorized",
  AUTHORIZED: "authorized",
  APPROVED: "approved",
};

export const PaymentMethodEnum = {
  CREDIT: "credit",
  PIX: "pix",
  BOLETO: "boleto",
};

export class PaymentDetail {
  id = "";
  orderId = "";
  capture = false;
  currency = "BRL";
  amount = 0;
  status = PaymentStatusEnum.PENDING;
  paymentMethod = {
    paymentType: "",
  };
  paymentSource = {
    sourceType: "",
  };
  createdAt = "";
  updatedAt = "";
  lastUpdatedAt = null;
  error = {
    declinedCode: "",
    type: "",
  };

  statusTranslated() {
    return {
      [this.status]: this.status,
      [PaymentStatusEnum.PENDING]: "Aguardando Pagamento",
      [PaymentStatusEnum.FAILED]: "Falhou",
      [PaymentStatusEnum.CANCELED]: "Cancelado",
      [PaymentStatusEnum.VOIDED]: "Estornado",
      [PaymentStatusEnum.PRE_AUTHORIZED]: "Pré aprovado",
      [PaymentStatusEnum.AUTHORIZED]: "Aprovado",
      [PaymentStatusEnum.APPROVED]: "Aprovado",
    }[this.status];
  }

  isCompleted() {
    return (
      this.status === PaymentStatusEnum.AUTHORIZED ||
      this.status === PaymentStatusEnum.PRE_AUTHORIZED
    );
  }

  isPending() {
    if (this.isCreditCard())
      return [
        PaymentStatusEnum.PRE_AUTHORIZED,
        PaymentStatusEnum.PENDING,
      ].includes(this.status);
    return this.status === PaymentStatusEnum.PENDING;
  }

  isCapturePending() {
    return (
      this.isCreditCard() && this.status === PaymentStatusEnum.PRE_AUTHORIZED
    );
  }

  isCreditCard() {
    return this.paymentMethod.paymentType === PaymentMethodEnum.CREDIT;
  }

  expireIn() {
    const createdAt = moment(this.createdAt);
    const expireIn = createdAt
      .clone()
      .add(this.paymentMethod.expiresIn, "seconds");
    return expireIn.diff(moment(), "milliseconds", false);
  }

  async capturePayment() {
    try {
      const result = await User.approvePayment(this.orderId, {
        amount: this.amount,
      });
      if (!result.error && !result.data.error && result.data.paymentMethod) {
        await this.reload(result.data);
      }
    } catch (e) {
      console.warn("falhou ao capturar pagamento", e);
    }
  }

  async cancelPayment() {
    try {
      const result = await User.cancelPayment(this.orderId, {
        amount: this.amount,
      });
      if (!result.error && !result.data.error && result.data.paymentMethod) {
        await this.reload(result.data);
      }
    } catch (e) {
      console.warn("falhou ao capturar pagamento", e);
    }
  }

  /**
   *
   * @param data {null|PaymentDetail}
   * @returns {Promise<void>}
   */
  async reload(data = null) {
    try {
      if (data) {
        Object.assign(this, PaymentDetailDirector.build(data));
        return;
      }
      const result = await User.readPaymentDetail(this.orderId);
      if (result.data) {
        Object.assign(this, PaymentDetailDirector.build(result.data));
      }
    } catch (e) {
      console.warn("PaymentDetail.reload.error", e);
    }
  }
}

export class PaymentCreditCardDetail extends PaymentDetail {
  paymentMethod = {
    paymentType: PaymentMethodEnum.CREDIT,
    installments: 0,
    recurrence: "",
  };

  paymentSource = {
    sourceType: "customer",
    customerId: "",
  };
}

export class PaymentPixDetail extends PaymentDetail {
  paymentMethod = {
    paymentType: PaymentMethodEnum.PIX,
    qrCodeData: "",
    qrCodeImageUrl: "",
    expiresIn: 0,
  };

  paymentSource = {
    sourceType: "customer",
    customerId: "",
  };
}

export class PaymentBoletoDetail extends PaymentDetail {
  paymentMethod = {
    paymentType: PaymentMethodEnum.BOLETO,
    barcodeData: "",
    barcodeImageUrl: "",
    expiresDate: "",
  };

  paymentSource = {
    sourceType: "customer",
    customerId: "",
  };
}

export class PaymentDetailBuilder {
  payment = new PaymentDetail();

  setBuilder(builder) {
    this.payment = builder;
    this.payment.lastUpdatedAt = new Date();
    return this;
  }

  setId(value) {
    this.payment.id = value;
    return this;
  }

  setOrderId(value) {
    this.payment.orderId = value;
    return this;
  }

  setCapture(value) {
    this.payment.capture = value;
    return this;
  }

  setAmount(value) {
    this.payment.amount = value;
    return this;
  }

  setStatus(value = PaymentStatusEnum.PENDING) {
    this.payment.status = value;
    return this;
  }

  setPaymentMethod(value) {
    this.payment.paymentMethod = value;
    return this;
  }

  setPaymentSource(value) {
    this.payment.paymentSource = value;
    return this;
  }

  setError(value) {
    this.payment.error = value || {
      declinedCode: "bad_request",
      type: "unknown",
    };
    return this;
  }

  setCreatedAt(value) {
    this.payment.createdAt = value;
    return this;
  }

  setUpdatedAt(value) {
    this.payment.updatedAt = value;
    return this;
  }

  /**
   * @returns {PaymentDetail|PaymentPixDetail}
   */
  toDTO() {
    return this.payment;
  }
}

export class PaymentDetailDirector {
  /**
   * @param payload {Record<string, any>}
   * @returns {PaymentPixDetail|PaymentBoletoDetail|PaymentCreditCardDetail}
   */
  static build(payload) {
    const concrete = {
      [PaymentMethodEnum.PIX]: new PaymentPixDetail(),
      [PaymentMethodEnum.CREDIT]: new PaymentCreditCardDetail(),
      [PaymentMethodEnum.BOLETO]: new PaymentBoletoDetail(),
    }[payload?.paymentMethod?.paymentType];

    return new PaymentDetailBuilder()
      .setBuilder(concrete)
      .setId(payload?.id)
      .setOrderId(payload?.orderId)
      .setAmount(payload?.amount)
      .setCapture(payload?.capture || false)
      .setStatus(payload?.status || PaymentStatusEnum.PENDING)
      .setCreatedAt(payload?.createdAt)
      .setUpdatedAt(payload?.updatedAt)
      .setPaymentMethod(payload?.paymentMethod || {})
      .setPaymentSource(payload?.paymentSource || {})
      .setError(payload?.error || {})
      .toDTO();
  }
}
