export const state = Object.freeze({
  requestId: [],
  name: null,
  code: null,
  status: [],
  company: [],
  seller: [],
  state: [],
  minPower: null,
  maxPower: null,
  minPrice: null,
  maxPrice: null,
  date: [],
  payment: [],
  equipament: "",
  discountRequisition: "",
});

const getters = {
  requestFilter: (state) => state,
  filterApplied: (state) =>
    Object.entries(state).filter(([k, v]) =>
      v instanceof Array ? v.length : !!v
    ),
  canFilterApplied: (state, getter) => getter.filterApplied.length > 0,
  requestFilterOriginal: () => ({ ...state }),
};

const mutations = {
  SAVE_FILTER_REQUEST(state, payload) {
    if (payload) {
      state.requestId = payload.requestId;
      state.name = payload.name;
      state.code = payload.code;
      state.status = payload.status;
      state.company = payload.company;
      state.seller = payload.seller;
      state.state = payload.state;
      state.minPower = payload.minPower;
      state.maxPower = payload.maxPower;
      state.minPrice = payload.minPrice;
      state.maxPrice = payload.maxPrice;
      state.date = payload.date;
      state.payment = payload.payment;
      state.equipament = payload.equipament;
      state.discountRequisition = payload.discountRequisition;
    }
  },

  CLEAR_FILTER_REQUEST(state) {
    state.requestId = [];
    state.name = null;
    state.code = null;
    state.status = [];
    state.company = [];
    state.seller = [];
    state.state = [];
    state.minPower = null;
    state.maxPower = null;
    state.minPrice = null;
    state.maxPrice = null;
    state.date = [];
    state.payment = [];
    state.equipament = null;
    state.discountRequisition = null;
  },

  CLEAR_FILTER_COLUMN(state, column) {
    state[column] = [
      "status",
      "company",
      "seller",
      "state",
      "date",
      "payment",
    ].includes(column)
      ? []
      : null;
  },

  REMOVE_ITEM_OF_COLUMN(state, payload = {}) {
    const { column, fn } = payload;
    if (state[column] && state[column] instanceof Array) {
      const index = state[column].findIndex(fn);
      index > -1 && state[column].splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state: {
    ...state,
  },
  mutations,
  getters,
};
