import { PURCHASE_TYPES } from "@/utils/constants";

const state = {
  id: "",
  name: "",
  additionalInfo: null,
  purchaseType: "",
  company: "",
  discount: 0,
  kits: [],
  products: [],
  structure: null,
  type: "",
  integrator: "",
  freight: null,
  sizingId: "",
  leadId: "",
  systemId: "",
  valueModifier: {
    increase: {
      amount: 0,
      type: "percentage",
    },
    decrease: {
      amount: 0,
      type: "percentage",
    },
  },
  totalValue: 0,
  marketplace: false,
  ecommerce: false,
  files: [],
  payment: null,
  coupon: null,
  isCopy: false,
};

const getters = {
  editRequest: (state) => state,
  ecommerce: (state) => state.ecommerce,
};
const formatPayment = (payment) => {
  if (payment?.condition?._id) {
    payment.condition = payment.condition._id;
  }
  return payment;
};

const mutations = {
  SAVE_EDIT_REQUEST(state, payload) {
    if (!payload) return;
    state.integrator = payload.company.id;
    state.id = payload.id;
    state.additionalInfo = payload.additionalInfo;
    state.purchaseType = PURCHASE_TYPES[payload.kind];
    state.company = payload.company.id;
    state.valueModifier = payload.valueModifier;
    state.discount = payload.discount || 0;
    state.products =
      payload.products
        .filter((p) => p.item)
        .map((item) => {
          return {
            ...item.item,
            basePrice: item.basePrice || item.item.price,
            amount: item.amount,
            recAmount: item.recAmount,
            recAutomaticAmount: item.recAutomaticAmount,
            freeAmount: item.freeAmount,
            type: item.type,
            singlePrice: item.singlePrice,
            price: item.singlePrice,
            totalPrice: item.totalPrice,
            center: item.center,
          };
        }) || [];

    state.structure = payload.structure ? payload.structure : null;
    state.type = payload.type || "";
    state.name = payload.name || "";
    state.freight = payload.freight || null;
    state.sizingId = payload.sizing || "";
    state.leadId = payload.lead || "";
    state.systemId = payload.system || "";
    state.totalValue = payload.totalValue || 0;
    state.marketplace = payload.marketplace || false;
    state.ecommerce = payload.ecommerce || false;
    state.totalValue = payload.totalValue || 0;
    state.files = payload.files || [];
    state.payment = formatPayment(payload.payment) || null;
    state.coupon = payload.coupon || null;
    state.isCopy = payload.isCopy || false;
  },
  DELETE_EDIT_REQUEST(state) {
    Object.assign(state, {
      id: "",
      name: "",
      additionalInfo: null,
      purchaseType: "",
      company: "",
      discount: 0,
      kits: [],
      products: [],
      structure: null,
      type: "",
      integrator: "",
      sizingId: "",
      leadId: "",
      systemId: "",
      valueModifier: {
        increase: {
          amount: 0,
          type: "percentage",
        },
        decrease: {
          amount: 0,
          type: "percentage",
        },
      },
      marketplace: false,
      ecommerce: false,
      files: [],
      payment: null,
      coupon: null,
      isCopy: false,
    });
  },
  SET_INTEGRATOR(state, integrator) {
    state.integrator = integrator;
    state.company = integrator;
  },
  SET_ADDITIONAL_INFO(state, additionalInfo) {
    state.additionalInfo = additionalInfo;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
