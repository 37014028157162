import { Staff, User } from "@/request";
import { orderBy } from "lodash";
import {
  getDistributorCustomStatus,
  getBudgetStatus,
  getRequestStatus,
} from "@/utils/statusUtils";

export const SET_REQUESTS = "SET_REQUESTS";
export const SET_STATUS = "SET_STATUS";
export const SET_META_REQUEST_INFO = "SET_META_REQUEST_INFO";
export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const SET_TYPE = "SET_TYPE";
export const SET_FILTER = "SET_FILTER";
export const SET_PAGE = "SET_PAGE";
export const UPDATE_DEAL_STAGE = "UPDATE_DEAL_STAGE";
export const UPDATE_DEAL = "UPDATE_DEAL";
export const REMOVE_DEAL = "REMOVE_DEAL";
export const SET_VIEW_MODE_HISTORY = "SET_VIEW_MODE_HISTORY";

export const VIEW_MODE_LIST = "list";
export const VIEW_MODE_KANBAN = "kanban";

export const GROUP_TYPE_ORDER = "order";
export const GROUP_TYPE_BUDGET = "budget";

const state = {
  meta: {
    total: 0,
  },
  requests: [],
  status: [],
  viewMode: null,
  type: GROUP_TYPE_ORDER,
  filter: {},
  page: 1,
  viewModeHistory: {
    [GROUP_TYPE_BUDGET]: VIEW_MODE_LIST,
    [GROUP_TYPE_ORDER]: VIEW_MODE_KANBAN,
  },
};

const mutations = {
  [SET_REQUESTS](state, payload = []) {
    const [rows = [], reset = false] = payload;

    if (reset) {
      state.requests = rows;
      return;
    }

    if (state.viewMode === VIEW_MODE_KANBAN) {
      for (const row of rows) {
        const { status, requests = [] } = row;
        const deals = state.requests.find((r) => r.status === status);
        deals.requests = [...deals.requests, ...requests];
      }
    } else {
      state.requests = [...state.requests, ...rows];
    }
  },
  [SET_STATUS](state, status) {
    state.status = status;
  },
  [SET_META_REQUEST_INFO](state, meta) {
    state.meta = meta;
  },
  [SET_VIEW_MODE](state, viewMode) {
    state.viewMode = viewMode;
  },
  [SET_TYPE](state, type) {
    state.type = type;
  },
  [SET_FILTER](state, filter) {
    state.filter = filter;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
  [UPDATE_DEAL_STAGE](state, data) {
    if (state.viewMode !== VIEW_MODE_KANBAN) return;
    const fromStage = state.requests.find((r) => r.status === data.from);
    const toStage = state.requests.find((r) => r.status === data.to);
    const deal = fromStage?.requests?.find((r) => r.id === data.id);
    if (!deal) return;
    const index = fromStage?.requests?.findIndex((r) => r.id === data.id);
    deal.status = data.to;
    deal.updateAt = new Date().toISOString();
    if (index > -1) {
      fromStage.requests.splice(index, 1);

      if (!toStage) {
        state.requests.push({
          total: 0,
          status: data.to,
          requests: [deal],
        });
      } else {
        toStage.requests.unshift(deal);
      }

      const metaFrom = state.meta.group.find((g) => g.status === data.from);

      if (metaFrom) {
        Object.assign(metaFrom, {
          docs: metaFrom.docs - 1,
          totalValue: metaFrom.totalValue - deal.totalValueFinal,
        });
      } else {
        state.meta.group.push({
          status: data.from,
          docs: 0,
          totalValue: 0,
        });
      }

      const metaTo = state.meta.group.find((g) => g.status === data.to);

      if (metaTo) {
        Object.assign(metaTo, {
          docs: metaTo.docs + 1,
          totalValue: metaTo.totalValue + deal.totalValueFinal,
        });
      } else {
        state.meta.group.push({
          status: data.to,
          docs: 1,
          totalValue: deal.totalValueFinal,
        });
      }
    }
  },
  [REMOVE_DEAL](state, data) {
    if (state.viewMode === VIEW_MODE_KANBAN) {
      const fromStage = state.requests.find((r) => r.status === data.status);
      const index = fromStage?.requests?.findIndex((r) => r.id === data.id);
      const metaInfo = state.meta.group.find((m) => m.status === data.status);
      if (index > -1) {
        fromStage.requests.splice(index, 1);
        if (metaInfo) {
          metaInfo.docs -= 1;
          metaInfo.totalValue -= data.totalValueFinal;
        }
      }
      return;
    }
    const index = state.requests.findIndex((r) => r.id === data.id);
    if (index > -1) state.requests.splice(index, 1);
  },
  [UPDATE_DEAL](state, data) {
    if (state.viewMode === VIEW_MODE_KANBAN) {
      const stage = state.requests.find((r) => r.status === data.status);
      const deal = stage?.requests?.find((r) => r.id === data.id);
      Object.assign(deal, data);
      return;
    }
    const deal = state.requests.find((r) => r.id === data.id);
    Object.assign(deal, data);
  },
  [SET_VIEW_MODE_HISTORY](state, data) {
    const { type, viewMode } = data;
    state.viewModeHistory[type] = viewMode;
  },
};

const getters = {
  requests: (state) => state.requests,
  status: (state) => state.status,
  total: (state) => state.meta.total,
  viewMode: (state) => state.viewMode,
  isViewModeKanban: (state) => state.viewMode === VIEW_MODE_KANBAN,
  isViewModeList: (state) => state.viewMode === VIEW_MODE_LIST,
  meta: (state) => state.meta,
  mapRequestFromKanban: (state) => {
    const result = [];
    for (const status of state.status) {
      const stage =
        state.requests?.find((r) => r.status === status.value) || {};
      const { requests = [], total = 0 } = stage;
      result.push({
        requests: orderBy(requests, ["updatedAt"], ["desc"]),
        total,
        status,
      });
    }
    return result;
  },
  getType: (state) => state.type,
  isViewKanbanModeAllowed: (state, getters, rootState, rootGetters) => {
    const isIntegrator = rootGetters["userData/isIntegrator"];
    if (isIntegrator) return false;
    const requestType = getters.getType;
    const isProDistribuitor = rootGetters["userData/isProDistribuitor"];
    const isBasicDistributor = rootGetters["userData/isBasicDistributor"];
    switch (requestType) {
      case GROUP_TYPE_ORDER:
        return isProDistribuitor || isBasicDistributor;
      case GROUP_TYPE_BUDGET:
        return isProDistribuitor;
      default:
        return false;
    }
  },
};

const actions = {
  async fetch({ commit, state, dispatch }, options) {
    const { page, itemsPerPage, filter, type } = Object.assign(
      {
        page: 1,
        itemsPerPage: 10,
        filter: {},
        type: "order",
        order: [["createdAt", -1]],
      },
      options
    );

    commit(SET_TYPE, type);
    commit(SET_PAGE, page);
    commit(SET_FILTER, filter);

    const [result] = await Promise.all([
      User.readRequest(type, state.viewMode, page, itemsPerPage, filter),
      dispatch("fetchStatus"),
    ]);

    const { amount = 0, requests: rows = [], meta = [] } = result?.data || {};
    commit(SET_REQUESTS, [
      rows,
      state.type !== type || amount !== state.meta.total || page == 1,
    ]);
    commit(SET_META_REQUEST_INFO, {
      total: amount,
      group: meta,
    });
  },
  async updateDealStage({ commit }, payload) {
    const { id, from, to, type } = payload;
    const result = await Staff.changeRequestStatus({
      id,
      update: { status: to, type },
    });

    if (result.error || !result?.data) {
      throw new Error(result?.error || "falhou ao alterar negociação.");
    }

    commit(UPDATE_DEAL_STAGE, { id, from, to });
  },
  async removeDeal({ commit }, payload) {
    const { type, ...deal } = payload;
    const result = await Staff.removeRequest(payload.type, payload.id);
    if (result.error || !result?.data) {
      throw new Error(result?.error || "falhou ao remover negociação.");
    }
    commit(REMOVE_DEAL, deal);
  },
  readViewModePreference({ state, getters, commit }, type) {
    if (!state.viewModeHistory[type]) {
      commit(
        SET_VIEW_MODE,
        getters.isViewKanbanModeAllowed ? VIEW_MODE_KANBAN : VIEW_MODE_LIST
      );
      return;
    }
    const viewModeAvailable = !getters.isViewKanbanModeAllowed
      ? VIEW_MODE_LIST
      : state.viewModeHistory[type];
    commit(SET_VIEW_MODE, viewModeAvailable);
  },
  setViewModePreference({ commit, state, getters }, viewMode) {
    switch (viewMode) {
      case VIEW_MODE_LIST:
      case VIEW_MODE_KANBAN:
        const viewModeAvailable = !getters.isViewKanbanModeAllowed
          ? VIEW_MODE_LIST
          : viewMode;
        commit(SET_VIEW_MODE, viewModeAvailable);
        commit(SET_VIEW_MODE_HISTORY, {
          type: state.type,
          viewMode: viewModeAvailable,
        });
        break;
      default:
        break;
    }
  },
  async fetchStatus({ commit, getters, rootGetters }) {
    const customStatus = await getDistributorCustomStatus(getters.getType);
    const status =
      getters.getType == "budget"
        ? getBudgetStatus(customStatus)
        : getRequestStatus(customStatus, {
            isMarketplace: false,
            isBackOfficeUser:
              rootGetters["userData/getUserData"].isBackOfficeUser,
          });
    commit(SET_STATUS, status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
