export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.$emit("before-show");
      },
    },
  },
};
