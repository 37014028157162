import Vue from "vue";
import moment from "moment";

import { toCurrency, toKWP } from "@/utils/CurrencyUtils";

Vue.filter("toCurrency", toCurrency);
Vue.filter("toKWP", toKWP);

Vue.filter("toNumber", function (value, maximumFractionDigits = 3) {
  let numberVal = value;
  if (typeof value === "string") numberVal = value.convertToNumber();
  else if (typeof numberVal !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits,
  });
  return formatter.format(numberVal);
});

Vue.filter("formatDate", (value, format = "DD/MM/YYYY") => {
  if (!value) return "N/A";
  return moment(value).format(format);
});
