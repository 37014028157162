import Icons from "@/constants/Icons";

export default [
  {
    path: "/distributors",
    name: "Distributors",
    meta: {
      icon: Icons.DOMAIN,
      title: "Distribuidores",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    component: () => import("@/views/distributors/Distributors.vue"),
    children: [
      {
        path: "details/:distributorId",
        name: "DistributorDetails",
        meta: {
          title: "Detalhes do distribuidor",
          local: ["navBar"],
          permissions: ["piedAdmin"],
        },
        component: () => import("@/views/distributors/DistributorDetails.vue"),
      },
    ],
  },
  {
    path: "/featured",
    name: "Featured",
    meta: {
      icon: Icons.STAR,
      title: "Equipamentos em destaque",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    component: () => import("@/views/featured/Featured.vue"),
  },
  {
    path: "/fares",
    name: "Fares",
    meta: {
      icon: Icons.CASH,
      title: "Tarifas",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    component: () => import("@/views/fares/Fares.vue"),
  },
  {
    path: "/irradiation",
    name: "Irradiation",
    meta: {
      icon: Icons.IRRADIATION,
      title: "Irradiação",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    component: () => import("@/views/irradiation/Irradiation.vue"),
  },
  {
    path: "/advertisements",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.ADVERTISEMENTS,
      title: "Anúncios",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    children: [
      {
        path: "",
        name: "Anúncios",
        component: () => import("@/views/advertisement/Advertisements.vue"),
        meta: {
          title: "Anúncios",
          permissions: ["piedAdmin"],
        },
      },
      {
        path: "advertisement/:advertisementId?",
        name: "AdvertisementDetails",
        meta: {
          title: "Anúncio",
          permissions: ["piedAdmin"],
        },
        component: () =>
          import("@/views/advertisement/AdvertisementDetail.vue"),
      },
    ],
  },
  {
    path: "/researches",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.ADVERTISEMENTS,
      title: "Pesquisas",
      local: ["navBar"],
      permissions: ["piedAdmin"],
    },
    children: [
      {
        path: "",
        name: "NPS",
        component: () => import("@/views/researches/NPS.vue"),
        meta: {
          title: "NPS",
          permissions: ["piedAdmin"],
        },
      },
    ],
  },
];
